import { useState, useCallback, useContext, createContext, useRef } from "react";

const ModalStatusContext = createContext();

export const useModalStatusContext = () => {
    const context = useContext(ModalStatusContext);
    if(!context){
        throw new Error('useModalStatusContext must be used within a ModalStatusContext provider.');
    }
    return context;
};

export const ModalStatusContextProvider = ({children}) => {
    const [LoadingCount,setLoadingCount] =  useState(0);
    const [statusErrors,setStatusErrors] = useState([]);
    const loggedErrorRef = useRef(new Set());

    const handleError = (error)  => {
        if(error){
            const errorJson = JSON.stringify(error);
            if(!loggedErrorRef.current.has(errorJson)){
                loggedErrorRef.current.add(errorJson);
                setStatusErrors((prev) => [...prev,error]);
            }
        }
    };

    // const handleError = (error) => {
    //     console.log(loggedErrorRef.current.has(error));
    //     console.log(error);
    //     if(loggedErrorRef.current.has(error)){
    //         return;
    //     }

    //     if(error){
    //         loggedErrorRef.current.add(error);
    //         setStatusErrors((prev) => [...prev,error]);
    //     }
    // }

    const setLoading = useCallback((isLoading) => {
        //Add one to loadCount if loading otherwise subtract 1
        setLoadingCount((prevCount) => prevCount + (isLoading ? 1 : -1));
    },[]);

    const isLoading = LoadingCount > 0;
    const HasError = statusErrors.length > 0;

    const resetContext = () => {
        setLoadingCount(0);
        setStatusErrors([]);
        loggedErrorRef.current.clear();
    }

    return(
        <ModalStatusContext.Provider
            value={{HasError,handleError,setLoading,isLoading,statusErrors,resetContext}}
        >
            {children}
        </ModalStatusContext.Provider>
    )
}