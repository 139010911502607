// import React, { useEffect, useState } from 'react';

import { useState, useEffect,useRef } from "react";
import styles from '../../styles/selectcomponent.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHelicopter, faXmark} from "@fortawesome/free-solid-svg-icons";
import SearchBox from "../searchbox";
import useApi from "../../hooks/use-api";
import { Button } from "react-bootstrap";
import InsetAlert from "../insetalert";
import ButtonOverride from "../buttonoverride";
import { useModalStatusContext } from "../../contexts/modalstatuscontext";
import SelectSkeleton from "../selectskeleton";
import {toTitleCase} from '../../utilities/texttransform';


const SelectionComponentTest = ({data,objectName,searchboxid,disabled,ignoreOutsideClickDataAttribute,sortFunction = null,field,fieldSetter,renderSelectionFunction = null,dataExtractFunction = null,retrieveDataEndpoint = null}) => {
    const [selected, setSelected] = useState({
      items: new Set(), // Now storing composite keys like `sectionID:itemID`
      actions: new Map(), // Key: composite key `sectionID:itemID`, Value: Set of action IDs
    });

    const _changeSelected = (selections) => {
      fieldSetter(selections);
      setSelected(selections);
    }

    const [dataExtracted,setDataExtracted] = useState([]);
    const {processing, error, getRequest} = useApi();
    // const {setLoading,handleError,statusErrors} = useModalStatusContext();

    useEffect(() => {
      console.log(field);
      setSelected(field);
    },[field]);

    useEffect(() => {
      if(retrieveDataEndpoint && data){
        throw new Error("Cannot supply both an AJAX endpoint and static data for selectcomponent.");
      }

      if(retrieveDataEndpoint){
        const get = async() => {
          // setLoading(true);
          const [data, status] = await getRequest(retrieveDataEndpoint, {timestamp: Date.now()}); 

          if(status === 200){
            let normalizedData;
            if(dataExtractFunction){
              normalizedData = dataExtractFunction(data);
            }
            else{
              normalizedData = data;
            }

            setDataExtracted(normalizedData);
            // setLoading(false);
          }
          else{
            setDataExtracted([]);
            // setLoading(false);
          }
        }

        get();
      }
      else{
        setDataExtracted(data);
      }
    },[data,retrieveDataEndpoint])

    const [sortedData,setSortedData] = useState([]);
    const [searchTerm,setSearchTerm] = useState('');
    const [IsExpanded,setIsExpanded] = useState(false);

    const containerRef = useRef(null);
    const searchAreaRef = useRef(null);
    const clearHandler = useRef(null);
  
    const handleItemClick = (sectionId, item) => {
      const compositeKey = `${sectionId}:${item.id}`;
      const newSelected = { ...selected };
      if (newSelected.items.has(compositeKey)) {
        newSelected.items.delete(compositeKey);
      } else {
        newSelected.items.add(compositeKey);
      }
      // setSelected(newSelected);
      _changeSelected(newSelected);
    };

    const _deselectAll = () => {
      // setSelected({
      //   items: new Set(), 
      //   actions: new Map()
      // });
      _changeSelected({items: new Set(),actions: new Map()})
    }
  
    const handleActionClick = (event, sectionId, item, action) => {

    event.stopPropagation(); // Prevent item selection
    const compositeKey = `${sectionId}:${item.id}`;
    const actionsForItem = selected.actions.get(compositeKey) || new Set();
  
    if (actionsForItem.has(action.id)) {
      actionsForItem.delete(action.id);
      // Check if the actions set is now empty, and remove the map entry if so
      if (actionsForItem.size === 0) {
        selected.actions.delete(compositeKey);
      } else {
        selected.actions.set(compositeKey, actionsForItem);
      }
    } else {
      if (!item.allowMultipleActionSelections) {
        actionsForItem.clear(); // Only one action can be selected at a time
      }
      actionsForItem.add(action.id);
      selected.actions.set(compositeKey, actionsForItem);
    }
  
    // Ensure state is updated with the potentially modified map
    // setSelected(prev => ({
    //   ...prev,
    //   actions: new Map(selected.actions)
    // }));
    _changeSelected({...selected,actions: new Map(selected.actions)})
  
    };
    useEffect(() => {
      const handleOutsideClick = (event) => {
          let _targetEvent = event.target;
          if(!_targetEvent.getAttribute(`${ignoreOutsideClickDataAttribute}`) && !_targetEvent.closest(`[${ignoreOutsideClickDataAttribute}]`)){
              if(containerRef.current && !containerRef.current.contains(_targetEvent)){
                  setIsExpanded(false);
                  if(clearHandler.current){
                      clearHandler.current();
                  }
              }
          }
      }

      document.addEventListener('mousedown',handleOutsideClick);

      return () => {
          document.removeEventListener('mousedown',handleOutsideClick);
      };
  },[containerRef]);

  useEffect(() => {
      if(IsExpanded && searchAreaRef.current){
          searchAreaRef.current.scrollTop = 0;
      }

  },[searchAreaRef,IsExpanded]);
  
    const renderActions = (sectionId, item) => {
      return item.actions.map((action) => (
          <ButtonOverride 
          key={action.id} 
          onClick={(e) => handleActionClick(e,sectionId,item,action)} 
          className={`${styles.actionBtn} ${styles.hasAction} ${selected.actions.get(`${sectionId}:${item.id}`)?.has(action.id) ? styles.active : styles.notactive} `}
          aria-label={`Select User : ${action.title}`}
          // aria-pressed={selectedItems.includes(item)}
          type="button"
          >
            {action.name}
          </ButtonOverride>
      ));
    };
  
    const renderItem = (sectionId, item) => {
      const compositeKey = `${sectionId}:${item.id}`;
      const isSelected = selected.items.has(compositeKey);
      const hasActions = item.actions && item.actions.length > 0;
      const isSelectedByProxy = hasActions ? selected.actions.has(compositeKey) : false;
  
      return (
        hasActions
        ?
          <div key={item.id} className={`${styles.resultItem} ${styles.nested} ${isSelectedByProxy ? styles.active : ''}`}>
            <span>{item.name}</span>
            <div className={styles.actionBtnGroup}>
              {renderActions(sectionId,item)}
            </div>
          </div>
        :
          <ButtonOverride 
            key={item.id} 
            onClick={() => handleItemClick(sectionId,item)} 
            className={`${styles.resultItem} ${isSelected ? styles.active : ''} `}
            aria-label={`Select User : ${item.title}`}
            type="button"
            >
                {item.name}
          </ButtonOverride>
      );
    };
  
    const renderSections = (data) => {
      return(
        <div className={styles.userSearchSelect}>
          <div className={`${styles.searchContainer} ${IsExpanded ? styles.expanded : ''}`}>
            <SearchBox autoComplete="off" placeholder={`Add ${objectName}'s...`} searchTerm={searchTerm} setSearchTerm={setSearchTerm} hasSearchableExpansion={IsExpanded} onFocus={() => setIsExpanded(true)} setClearHandler={handler => clearHandler.current = handler} id={searchboxid} disabled={disabled} />
            <div>
              <div className={`${styles.searchResults} ${IsExpanded ? styles.expanded : ''}`}>
                <div className={styles.autoOverflow}  ref={searchAreaRef}>
                  {
                    data.map((section) => (
                      <div key={section.id}>
                        {section.name && <h6 className={`text-center`}>{toTitleCase(section.name)}</h6>}
                        {section.items.map(item => renderItem(section.id,item))}
                      </div>
                    ))
                  }
                </div>
                {
                    !data.length
                    ?
                      searchTerm !== ''
                      ?
                        <span className={styles.noresults}>No Results Found</span>
                      :
                        <span className={styles.noresults}>No {objectName}'s Exist</span>
                    :
                      <span className={styles.paddingAdded}></span>
                  }
              </div>
            </div>
          </div>
        </div>
      )
    };

    useEffect(() => {
        const filterAndSortData = (data) => {
          return data
            .map(section => {
              // Determine if the section name matches the search term
              const sectionMatches = section.name ? section.name.toLowerCase().includes(searchTerm.toLowerCase()) : false;
              return {
                ...section,
                items: section.items
                  .filter(item => sectionMatches || item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort items alphabetically
                  .map(item => ({
                    ...item,
                    // actions: item.actions ? item.actions.sort((a, b) => a.name.localeCompare(b.name)) : []
                   // Sort actions alphabetically
                   actions: item.actions ? item.actions.sort((a,b) => sortFunction ? sortFunction(a,b) : a.name.localeCompare(b.name)) : []
                  }))
              };
            })
            .filter(section => section.items.length > 0 || section.name?.toLowerCase().includes(searchTerm.toLowerCase())); // Keep sections that match the search term or have matching items
        };
      
        setSortedData(filterAndSortData(dataExtracted));
      }, [dataExtracted, searchTerm]); // Depend on data and searchTerm
      
      const deselectItemOrAction = (sectionId, itemId, actionId = null) => {
        const compositeKey = `${sectionId}:${itemId}`;

        if (actionId !== null) {
          // Deselect action
          const actionsForItem = new Set(selected.actions.get(compositeKey) || []);
          actionsForItem.delete(actionId);
          if (actionsForItem.size > 0) {
            // setSelected(prev => ({
            //   ...prev,
            //   actions: new Map(prev.actions).set(compositeKey, actionsForItem),
            // }));
            _changeSelected({...selected,actions: new Map(selected.actions).set(compositeKey,actionsForItem)});
          } else {
            // Remove the item from actions if no actions are selected
            const newActions = new Map(selected.actions);
            newActions.delete(compositeKey);
            // setSelected(prev => ({ ...prev, actions: newActions }));
            _changeSelected({...selected,actions: newActions});
          }
        } else {
          // Deselect item
          const newItems = new Set(selected.items);
          newItems.delete(compositeKey);
          // setSelected(prev => ({ ...prev, items: newItems }));
          _changeSelected({...selected,items: newItems});
        }
      };

      useEffect(() => {
        // fieldSetter(selected);
        console.log(selected)
      },[selected]);
      
    return (
      processing
      ?
        <SelectSkeleton />
      :
        error
        ?
          <InsetAlert error={error} dismissible={false} style={{width: '100%'}}/>
        : 
          <div ref={containerRef}>
            {renderSections(sortedData)}
            <SelectionDisplay data={dataExtracted} selected={selected} onDeselect={deselectItemOrAction} deselectAllFunction={() => _deselectAll()} objectName={objectName} renderSelectionFunction={renderSelectionFunction} sortFunction={sortFunction}/>
          </div>
    )
  };

  const SelectionDisplay = ({ data, selected,onDeselect,deselectAllFunction,objectName,renderSelectionFunction,sortFunction = null }) => {
    const [selectedItems,setSelectedIitems] = useState([]);
    const _defaultSort = (a,b) => {
      if (a.actionName && b.actionName) {
        const actionNameDiff = a.actionName.localeCompare(b.actionName);
        if (actionNameDiff !== 0) return actionNameDiff;
      } else if (a.actionName) {
        return -1; // Items with actions come before those without
      } else if (b.actionName) {
        return 1;  // Correspondingly, items without actions come after those with actions
      }
      return a.itemName.localeCompare(b.itemName);
    }
    const getSelectionDetails = () => {
      let selections = [];

      const allSelectedCompositeKeys = new Set([
        ...selected.items,
        ...Array.from(selected.actions.keys()), // Ensure items with selected actions are included
      ]);

      allSelectedCompositeKeys.forEach(compositeKey => {
        const [sectionId, itemId] = compositeKey.split(':');
        const section = data.find(section => section.id === sectionId);
        const item = section?.items.find(item => item.id.toString() === itemId);
        if (!item) return;
    
        let actionsDetail = [];
    
        const actionIds = selected.actions.get(compositeKey);
        if (actionIds && actionIds.size > 0) {
          actionIds.forEach(actionId => {
            const action = item.actions?.find(action => action.id === actionId);
            if (action) {
              actionsDetail.push({
                sectionId,
                itemId,
                itemName: item.name,
                actionId: action.id,
                actionName: action.name
              });
            }
          });
        } else {
          // If no actions are selected for the item, still include the item without action
          actionsDetail.push({
            sectionId,
            itemId,
            itemName: item.name,
            actionId: null,
            actionName: null // This will ensure items without actions are sorted last
          });
        }
    
        selections = selections.concat(actionsDetail);
      });

      selections.sort((a,b) => a.itemName.localeCompare(b.itemName)).sort((a,b) => sortFunction ? sortFunction(a,b) : _defaultSort(a,b));

      return selections;
    };
    
    

    useEffect(() => {
        const _selections = getSelectionDetails();

        setSelectedIitems(_selections);
    },[data,selected]);

    const handleDeslectClick = (sectionId,itemId,actionId = null) => {
        onDeselect(sectionId,itemId,actionId);
    }
  
  
    return (
      <div className={`${styles.selectedItemContainer} mt-4`}>
        <div>
          <div className="d-flex justify-content-center">
            <strong className={styles.headerText}>
              {selectedItems.length} {objectName}(s) Selected
            </strong>
          </div>
        </div>
        <div className={`${styles.selectedItems} mt-1`}>
          {
            selectedItems.length
            ?
              selectedItems.map((selection,index) => {
                const styleObject = 
                  renderSelectionFunction
                  ?
                    renderSelectionFunction()
                  :
                    {itemClass: `${styles.selectedItem}`,buttonClass: `${styles.removeItem}`,actionClass: `${selection.actionId !== null ? styles.actionItem : ''}`}

                  return(
                    <div key={index} className={styleObject.itemClass}>
                      {selection.actionId !== null ? <span className={styleObject.actionClass}>{selection.actionName}</span> : null}
                      {selection.itemName}
                      <ButtonOverride type="button" onClick={() => handleDeslectClick(selection.sectionId,selection.itemId,selection.actionId)} className={styleObject.buttonClass} title={``} aria-label={``}><FontAwesomeIcon icon={faXmark} size="lg" /></ButtonOverride>
                    </div>
                  )
              })
            :
              null
          }
        </div>
        {
          selectedItems.length
          ?
            <div className="d-flex justify-content-end">
              <Button type="button" variant="link" style={{padding: 0, fontSize: '0.8em',textDecoration: 'none'}} onClick={deselectAllFunction} title="Clear Selections" aria-label="Clear Selections">Clear Selections</Button>
            </div>
          :
            null
        }
      </div>
    );
  };
  
  
  
  export default SelectionComponentTest;
  