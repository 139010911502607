import {Form} from "react-bootstrap";
import { Field, useField } from "formik";
const Checkbox = ({type,label,...props}) => {
  const [field,meta,helpers] = useField({...props,type: "checkbox"});
    return <>
    <Form.Check // prettier-ignore
        type={type}
        aria-label={label}
        {...props}
        {...field}
      />
        
    </>
}

export default Checkbox;