import { Button, Col, Row } from "react-bootstrap";
import InlineRadioGroup from "../../../formik/inlineradiogroup";
import { Formik,Form, Field } from "formik";
import FloatLabel from "../../../formik/floating-label";
import FormikCheckbox from "../../../formik/formikcheckbox";
import InputField from "../../../formik/input-field";
import LabelAboveTextarea from "../../../formik/labelabovetextarea";
import FloatingActionButton from "../../../components/floatingactionbutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import BaseForm from "./baseform";

const Map351IVForm = () => {
    const initialValues = {
    }

    const validation = {

    }

    const fields = [
        {
            labelHeading: 'Community Inclusion',
            label: '(What do you like to do or where would you like to go in the community, where do you go for recreation, do you not get to go somewhere that you would like to?)',
            name: 'communityinclusion'
        },
        {
            labelHeading: 'Relationships',
            label: '(How do you stay in contact with your friends and family, do you need assistance in making or keeping friends, who are your friends?)',
            name: 'relationships'
        },
        {
            labelHeading: 'Rights',
            label: '(Do you understand your rights, are any of your rights restricted, do you know what is abuse or neglect?)',
            name: 'rigts'
        },
        {
            labelHeading: 'Dignity and Respect',
            label: '(How are you treated by staff, do you have a place you can go to be with friends or to be alone or have privacy?)',
            name: 'dignityandrespect'
        },
        {
            labelHeading: 'Health',
            label: '(Who are your doctors, do you have any health concerns, what medicine do you take, how do they make you feel?)',
            name: 'health'
        },
        {
            labelHeading: 'Lifestyle',
            label: '(Do you have a job, do you want to work, do you want to go to school, do you go to the bank, do you have spending money to carry?)',
            name: 'Lifestyle'
        }
    ]

    return(
        <BaseForm
            initialValues={initialValues}
            onSubmit={() => alert("here")}
        >
            <h4 className="text-center">SECTION IV - SELF ASSESSMENT</h4>
            {
                fields.map((item,index) => (
                    <LabelAboveTextarea key={index} labelheading={item.labelHeading} label={item.label} name={item.name} as='textarea' rows={4} />
                ))
            }
        </BaseForm>
    )
}

export default Map351IVForm;