import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

const FloatLabel = ({label, ...props}) => {
    const [field, meta] = useField(props);
    const safeId = label.replace(/[^\w]/gi, "").substring(0, 9);
  return (
    <>
    <FloatingLabel
        controlId={`input-${safeId}`}
        label= {label}
        className='mb-3'
      >
        <Form.Control
                {...field}
                {...props}
                isInvalid={meta.touched && meta.error}
                // size="sm"
            />
            <Form.Control.Feedback type="invalid">
                {meta.error}
            </Form.Control.Feedback>
      </FloatingLabel>
    </>
      
    
  );
};

export default FloatLabel;