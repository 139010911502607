import { useField } from "formik";
import { Form } from "react-bootstrap";

const LabelAboveTextarea = ({labelheading,label,...props}) => {
    const [field, meta] = useField(props);
    const safeId = label.replace(/[^\w]/gi, "").substring(0, 9);

    return(
        <Form.Group controlId={safeId} className="mb-4">
            <Form.Label className="mb-0">
                <strong>{labelheading} </strong>
                {label}
            </Form.Label>
            <Form.Control
                {...field}
                {...props}
                isInvalid={meta.touched && meta.error}
                // size="sm"
            />
            <Form.Control.Feedback type="invalid">
                {meta.error}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default LabelAboveTextarea;