import { useField } from "formik";
import { Form } from "react-bootstrap";

const TabularInlineRadioGroup = ({name,primaryLabel,data}) => {
    const [field,meta,helpers] = useField(name);

    return(
        <tr>
                <td>
                    <strong>{primaryLabel}</strong>
                    {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {meta.error}
                </Form.Control.Feedback>
            ) : null}
                </td>
                {
                    data.map((item,index) => (
                        <td key={index}>
                            <Form.Check
                                type="radio"
                                label={item.title}
                                id={`${name}${index}`}
                                inline
                                {...field}
                                isInvalid={meta.touched && meta.error}
                                value={item.value}
                                checked={field.value === item.value}
                                onChange={() => helpers.setValue(item.value)}
                            />
                        </td>
                    ))
                }
            </tr>
    )
}

export default TabularInlineRadioGroup;