import './App.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import AuthLayout from './layouts/auth-layout';
import { BrowserRouter, Routes,Route } from 'react-router-dom';
import ProtectedRoutes from './utilities/protected-routes';
import SetPassword from './auth/setpassword';
import ManageUsers from './pages/admin/manageusers';
import useApiUrlStore from './stores/api-url-store';
import ViewReports from './pages/reports/viewreports';
import ClientSearch from './pages/Client/ClientSearch';
import HomePage from './pages/home/homepage';
import ManageRoles from './pages/admin/manageroles';
import ImpersonateUser from './pages/admin/impersonateuser';
import ManageGroupApp from './pages/admin/managegroupapp';
import ManageEmails from './pages/admin/manageemails';

function App() {


  const setBaseUrl = useApiUrlStore((state) => state.setBaseUrl);

  const apiBaseUrls = {
    Local: "https://localhost:7222/",
    Staging: "https://mfptest.uky.edu/api/",
    Production: "https://mfp.uky.edu/api/"
  };

  const currentUrl = window.location.href;   

  let apiBaseUrl;
  
  if(currentUrl.includes("localhost")){
    apiBaseUrl = apiBaseUrls.Local;
  }
  else if(currentUrl.includes("mfptest")){
    apiBaseUrl = apiBaseUrls.Staging;
  }
  else{
    apiBaseUrl = apiBaseUrls.Production;
  }


  setBaseUrl(apiBaseUrl);


  return (
    <BrowserRouter>
        <Routes>
          <Route path="/Auth/Login" element={<AuthLayout />} />
          <Route path="/" element={<ProtectedRoutes><HomePage /></ProtectedRoutes>} />
          <Route path="/Auth/SetPassword" element={<SetPassword />} />
          <Route path="/Client/ClientSearch" element={<ProtectedRoutes><ClientSearch /></ProtectedRoutes>} />
          <Route path="/Admin/ManageUsers" element={<ProtectedRoutes><ManageUsers /></ProtectedRoutes>} />
          <Route path="/Admin/ManageRoles" element={<ProtectedRoutes><ManageRoles /></ProtectedRoutes>} />
          <Route path="/Admin/ImpersonateUser" element={<ProtectedRoutes><ImpersonateUser /></ProtectedRoutes>} />
          <Route path="/Admin/ManageGroupApp" element={<ProtectedRoutes><ManageGroupApp /></ProtectedRoutes>} />
          <Route path="/Admin/ManageEmails" element={<ProtectedRoutes><ManageEmails /></ProtectedRoutes>} />
          <Route path="/Reports/ViewReports" element={<ProtectedRoutes><ViewReports /></ProtectedRoutes>} />
        </Routes>
      </BrowserRouter>
    
  );
}

export default App;
