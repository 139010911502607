import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import DotLoader from "./dotloader";
import ActionResultText from "../utilities/ActionResultText";
import styles from '../styles/FloatAlert.module.css';
import ButtonOverride from "./buttonoverride";
import { useEffect, useRef, useState } from "react";
import { useModalStatusContext } from "../contexts/modalstatuscontext";

const FloatingAlert = ({processing,expandedLog,closeExpandedErrorLog,triggerBtnRef,error,objectType,action,canClose = true}) => {
    const [fullCycle,setFullCycle] = useState(false);
    const {statusErrors} = useModalStatusContext();
    const statusWindowRef = useRef();
    const floatAreaRef = useRef();
    let errorMessage = '';
    if(error){
        errorMessage = typeof error.error == "object" ? JSON.stringify(error.error) : error.error;
    }
    
    useEffect(() => {
        if(processing){
            setFullCycle(false);
        }
    },[processing]);

    useEffect(() => {
        if(expandedLog){
            setFullCycle(true);
        }
    },[expandedLog]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if(triggerBtnRef.current && !triggerBtnRef.current.contains(event.target)){
                if(floatAreaRef.current && !floatAreaRef.current.contains(event.target)){
                    setFullCycle(true);
                }
                if(statusWindowRef.current && !statusWindowRef.current.contains(event.target)){
                    closeExpandedErrorLog();
                }
            }
        }

        document.addEventListener('mousedown',handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown',handleOutsideClick);
        };
    },[statusWindowRef,floatAreaRef]);

    useEffect(() => {
        if(expandedLog && statusWindowRef.current){
            statusWindowRef.current.scrollTop = 0;
        }

    },[statusWindowRef,expandedLog]);
    
    return(
        <div className={`${styles.floatAlertContainer} ${processing || expandedLog ? styles.loading : ''}`}>
            {
                processing
                ?
                    <DotLoader ButtonStyle={false} />
                :
                    expandedLog
                    ?
                        <div className={styles.statusWindow} ref={statusWindowRef}>
                            <div className={styles.statusWindowHeader}>
                                <h5 className="mb-0">Status Log</h5>
                                <ButtonOverride type="button" onClick={() => closeExpandedErrorLog()} className={styles.statusWindowCollapseBtn} title="Collapse Status Log." aria-label="Collapse Status Log."><FontAwesomeIcon icon={faXmark} size="lg" /></ButtonOverride>
                            </div>
                            <div className={styles.statusWindowBody}>
                                {
                                    statusErrors.length > 0 ?
                                    statusErrors.map((Error,index) => (
                                        <div className={`${styles.statusWindowItem} mt-2 mb-1`} key={index}>
                                            <FontAwesomeIcon icon={faXmarkCircle} size="2xl" className="me-2" style={{color: "red"}} />
                                            <strong>
                                                {
                                                    Error.error && typeof Error.error !== 'object'
                                                    ?
                                                        Error.error
                                                    :
                                                        "An unkown error occured."
                                                }
                                            </strong>
                                        </div>
                                    ))
                                    :
                                        <div className={`${styles.statusWindowItem} mt-2 mb-1`}>
                                            <FontAwesomeIcon icon={faCheckCircle} size="2xl" className="me-2" style={{color: "green"}}/>
                                            <strong>{ActionResultText({method: action, error: error, objectTarget: objectType})}</strong>
                                        </div>
                                }
                            </div>
                        </div>
                    :
                        !fullCycle
                        ?
                        <div className={`${styles.floatAlert} ${!canClose ? styles.noDismiss : ''}`} onAnimationEnd={() => setFullCycle(true)} ref={floatAreaRef}>
                            {
                                statusErrors.length > 0
                                ?
                                    <>
                                        <FontAwesomeIcon icon={faXmarkCircle} size="2xl" className="me-2" style={{color: "red"}}/>
                                        {
                                                statusErrors.length === 1
                                                ?
                                                        statusErrors.map((Error,idx) => (
                                                            <strong key={idx}>
                                                                {
                                                                    Error.error && typeof Error.error !== 'object'
                                                                    ?
                                                                        Error.error
                                                                    :
                                                                        "An unkown error occured."
                                                                }
                                                            </strong>
                                                        ))
                                                    
                                                :
                                                    <strong>
                                                        Problems occured when loading the page.
                                                    </strong>
                                            }
                                    </>
                                :
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} size="2xl" className="me-2" style={{color: "green"}}/>
                                        <strong>{ActionResultText({method: action, error: error, objectTarget: objectType})}</strong>
                                    </>
                            }
                        </div>
                        :
                            null
            }
        </div>
    )
}

export default FloatingAlert;