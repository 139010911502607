import React from "react";

const ScrollSection = React.forwardRef(({title,defaultStyling = true,children},ref) => (
    <section ref={ref} data-title={title} className={defaultStyling ? "scrollSectionMain mb-4" : null}>
        <div className={defaultStyling ? "scrollSectionHeader" : null}>
            <h5>{title}</h5>
        </div>
        <div className={defaultStyling ? "scrollSectionBody" : null}>
            {children}
        </div>
    </section>
));

export default ScrollSection;