import styles from '../styles/Loader.module.css'

const LineLoader = ({type,text}) =>{
    return(
        <div className={styles.parentbody}>
            <div>
                <div className={type === 'upload' ? styles["line-loader-upload"] : styles["line-loader-download"]}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <h5 className='d-flex justify-content-center'>{text != null ? <>{text}</> : type === 'upload' ? <>Sending Request...</> : <>Loading...</>}</h5>
            </div>
        </div>
        
    )
}

export default LineLoader;