import {Card, Col,ListGroup,Row } from "react-bootstrap";
import LoggedInLayout from "../../layouts/loggedin-layout";
import { useState,useEffect } from "react";
import styles from '../../styles/Reports.module.css';

const ViewReports = () => {
    useEffect(() => {
        document.title = "MFP - Reports";
    },[]);

    const [selectedReportID,setSelectedReportID] = useState(null);
    const ReportData = [
        {
            id: 0,
            name: 'Activity',
            reports: [
                {
                    id: 'A01',
                    title: 'A01 - Admin - User Activity Log',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            id: 1,
            name: 'Check Reports',
            reports: [
                {
                    id: 'CK01',
                    title: 'CK01 - Check Transactions',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            id: 2,
            name: 'Client Counts',
            reports: [
                {
                    id: 'C01A',
                    title: 'C01A - Client Count Summary',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'C01B',
                    title: 'C01A - Client Count Summary - Detail',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'C01C',
                    title: 'C01A - Client Count Summary - Detail Filtered',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'C01D',
                    title: 'C01A - Client Count Summary - Detail by Date',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'C01E',
                    title: 'C01A - Client Count Summary - Detail by MAID',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'C02',
                    title: 'C02 - Kentucky Referrals STT to RTT by Month and County',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'C03',
                    title: 'C03 - Client Counts ER Visits',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            id: 3,
            name: 'Client Lists',
            reports: [
                {
                    id: 'CL01',
                    title: 'CL01 - Admin - Client Number Py Parm',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'CL02',
                    title: 'CL02 - Admin - Client Number List - WARNING - Contains SSNs',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            id: 4,
            name: 'Forms',
            reports: [
                {
                    id: 'TESTOne',
                    title: 'TEST REPORT',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                },
                {
                    id: 'TERER',
                    title: 'TEST REPORT',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            id: 5,
            name: 'Etc.',
            reports: [
                {
                    id: 'Disclaimer',
                    title: 'Reports Development',
                    description : 'The reports currently shown are placeholders. Future refinement will occur prior to release to ensure only the necessary reports exist in the system.'
                }
            ]
        }
    ];

    const selectedCategory = ReportData.find(category => category.id === selectedReportID);
    return(
        <LoggedInLayout>
            <div style={{padding: '25px'}}>
                <Row>
                    <Col md={3} xl={2}>
                        <Card>
                            <Card.Header>
                                <h5 className="text-center card-title mb-0">Report Categories</h5>
                            </Card.Header>
                            <ListGroup>
                            {
                                ReportData.map((category) => (
                                    <ListGroup.Item key={category.id} action onClick={() => setSelectedReportID(category.id)} active={category.id === selectedReportID} className={styles.reportCategoryItem} type="button">
                                        {category.name}
                                    </ListGroup.Item>
                                ))
                            }
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col md={9} xl={10} className={styles.reportArea}>
                        <Row>
                            {
                                selectedCategory
                                ?
                                    selectedCategory.reports.map(report => (
                                        <Col lg={4} md={6} sm={12} className="mb-3" key={report.id}>
                                            {/* <Card>
                                                <Card.Header>
                                                    <h5 className="card-title mb-0">{report.title}</h5>
                                                </Card.Header>
                                                <Card.Body>
                                                    <p>
                                                        {report.description}
                                                    </p>
                                                </Card.Body>
                                            </Card> */}
                                            <button className={`${styles.reportCard} semanticBtnOverride`} type="button" aria-label={`View report titled ${report.title}`}>
                                                <strong className="mb-1">{report.title}</strong>
                                                <span className={styles.reportDescription}>
                                                    {report.description}
                                                </span>
                                            </button>
                                        </Col>
                                    ))
                                :
                                <Col sm={12}>
                                    <div className={styles.noCategorySelected}>
                                        <h5>Welcome to the Reports Dashboard!</h5>
                                        <p>
                                            Welcome to the Reports Dashboard! Here you can access a variety of reports that provide in-depth insights and analysis of the MFP program.
                                            Each report is designed to offer specific data and metrics tailored to different aspects of our operations. Please browse through the
                                            categories on the left and select one to view the reports available within that category. Once a category is selected, you can choose
                                            a report to get detailed information and actionable insights.
                                        </p>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </LoggedInLayout>
    )

}


export default ViewReports;