import styles from '../styles/Loader.module.css';

const GapRecipientSkeleton = () => {
    return(
        <>
            <div className={styles.skeletonGapRecipient}></div>
            <div className={styles.skeletonGapRecipient}></div>
            <div className={styles.skeletonGapRecipient}></div>
            <div className={styles.skeletonGapRecipient}></div>
            <div className={styles.skeletonGapActionButton}></div>
        </>
    )
}

export default GapRecipientSkeleton;