import LoggedInLayout from '../../layouts/loggedin-layout';
import UnderConstruction from '../other/underconstruction';

const ImpersonateUser = () => {
    return(
        <UnderConstruction />
    )

}

export default ImpersonateUser;