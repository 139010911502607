import { Offcanvas,Button, Col, Row } from "react-bootstrap";
import LoggedInLayout from "../../layouts/loggedin-layout";
import { useEffect, useState } from "react";
import styles from '../../styles/offcanvasnav.module.css';
import ButtonOverride from "../../components/buttonoverride";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import ApplicationForm from "./forms/applicationform";
import ScreeningForm from "./forms/screeningform";
import FaceSheet from "./forms/facesheet";
import Map351VIForm from "./forms/map351VIform";
import Map351VForm from "./forms/map351VForm";
import Map351IVForm from "./forms/map351IVform";
import Map351VIIForm from "./forms/map351VIIform";
import Map351IForm from "./forms/map351Iform";
import Map351IIForm from "./forms/map351IIform";
import Map351IIIForm from "./forms/map351IIIform";

const ClientFormsPage = ({applicationID,returnCallback,homeCallback}) => {
    const [showNavbar,setShowNavbar] = useState(false);
    const [navitems,setNavitems] = useState([]);
    const [navLookup,setNavLookup] = useState();
    const [activeIndex,setActiveIndex] = useState(1);
    const [activeSection,setActiveSection] = useState(null);

    const _handleNavClick = (navID) => {
        if(navID === 0)
        {
            homeCallback();
        }
        else
        {
            setShowNavbar(false);
            setActiveIndex(navID);
            // setShowNavbar(false);
        }
    }

    useEffect(() => {
        const nav = [
            {
                id: 0,
                title: 'Primary',
                children: [
                    {
                        id: 0,
                        title: 'Home'
                    },
                    {
                        id: 1,
                        title: 'Referral/Applications'
                    },
                    {
                        id: 2,
                        title: 'Screening'
                    },
                    {
                        id: 3,
                        title: 'Face Sheet'
                    }
                ]
            },
            {
                id: 1,
                title: 'Assesment MAP351',
                children: [
                    {
                        id: 4,
                        title: 'I - Demographics'
                    },
                    {
                        id: 5,
                        title: 'II - Waiver Eligibility'
                    },
                    {
                        id: 6,
                        title: 'III - Provider'
                    },
                    {
                        id: 7,
                        title: 'IV - Self Assesment'
                    },
                    {
                        id: 8,
                        title: 'V - Daily Living'
                    },
                    {
                        id: 9,
                        title: 'VI - Instrumental'
                    },
                    {
                        id: 10,
                        title: 'VII - Neuro/Emo/Beh'
                    },
                    {
                        id: 11,
                        title: 'VIII - Clinical Info'
                    },
                    {
                        id: 12,
                        title: 'IX - Environmental'
                    },
                    {
                        id: 13,
                        title: 'X - Household'
                    },
                    {
                        id: 14,
                        title: 'XI - Additional Services'
                    },
                    {
                        id: 15,
                        title: 'XII - CDO'
                    },
                    {
                        id: 16,
                        title: 'XIII - Signatures'
                    },
                    {
                        id: 17,
                        title: 'F6A - Map351 Report'
                    }
                ]
            },
            {
                id: 2,
                title: 'Transition',
                children: [
                    {
                        id: 18,
                        title: 'Cost Estimate'
                    },
                    {
                        id: 19,
                        title: 'Transition Plan'
                    },
                    {
                        id: 20,
                        title: 'Change Application Status'
                    },
                    {
                        id: 21,
                        title: 'Referral Application Status'
                    },
                    {
                        id: 22,
                        title: 'Housing'
                    },
                    {
                        id: 23,
                        title: 'Housing Information'
                    },
                    {
                        id: 24,
                        title: 'Transition Checklist'
                    },
                    {
                        id: 25,
                        title: 'History Detail'
                    }
                ]
            }
        ]

        const _lookupMap = {};

        nav.forEach((parent) => {
            if(parent.children){
                parent.children.forEach((child) => {
                    _lookupMap[child.id] = parent.id;
                })
            }
        })

        setNavLookup(_lookupMap);
        setNavitems(nav);
    },[])

    const _handleNavOpen = () => {
        setShowNavbar(true);
    }

    const _handleNavClose = () => {
        setShowNavbar(false);
    }

    const _handleNavExit = () => {
        setActiveSection(navLookup[activeIndex]);
    }

    const _renderActiveSection = (activeSessionId) => {
        const value = navitems.find(element => element.id === activeSessionId);

        return (
            <>
                {
                    value.children.map(child => (
                        <ButtonOverride key={child.id} className={`${styles.resultItem} ${activeIndex === child.id ? styles.active : ''}`} type="button" onClick={() => _handleNavClick(child.id)}>{child.title}</ButtonOverride>
                    ))
                }
            </>
        )
    }

    const _renderTitle = (activeSessionId) => {
        let text = 'Navigation';
        if(activeSessionId !== null){
            text = navitems.find(element => element.id === activeSessionId)?.title;
        }

        return(
            <>
            {text}
            </>
        )
    }

    const _isParentActive = (activeItemId) => {
        const value = navitems.find(element => element.id === activeItemId);

        return value.children.some(element => element.id === activeIndex);
    }

    const _renderSelectedPage = (activeIndex) => {
        switch(activeIndex){
            case 1:
                return <ApplicationForm />
            case 2:
                return <ScreeningForm />
            case 3:
                return <FaceSheet />
            case 4:
                return <Map351IForm />
            case 5:
                return <Map351IIForm />
            case 6:
                return <Map351IIIForm />
            case 7:
                return <Map351IVForm />
            case 8:
                return <Map351VForm />
            case 9:
                return <Map351VIForm />
            case 10:
                return <Map351VIIForm />
            default:
                return <span>test</span>
        }
    }

    return(
        <LoggedInLayout>
            <ButtonOverride type="button" title="Open Navigation" aria-label="Open Navigation" className={` ${styles.openOffcanvasBtn} ${styles.removepadding} `} onClick={_handleNavOpen}>
                <FontAwesomeIcon icon={faBars} size="xl" />
            </ButtonOverride>
            <div style={{padding: "25px"}}>
                <Row>
                    <Col xs={12} lg={3}>
                        <div className="clientInformation">
                            <h3 className="clientInformationTitle">
                                Client Information
                            </h3>
                            
                            <div className="infoItemBox">
                                <h5>Name</h5>
                                <p className="infoItemDataBody">John Doe</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Application #</h5>
                                <p className="infoItemDataBody">34513</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Application Date</h5>
                                <p className="infoItemDataBody">12/12/2021 12:31:04 AM</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Medicaid #</h5>
                                <p className="infoItemDataBody">1348654431</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Application Status</h5>
                                <p className="infoItemDataBody">In Progress</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Login Name</h5>
                                <p className="infoItemDataBody">Bradley Tyler</p>
                            </div>
                            <Button type="button" variant="link" className="p-0" style={{textDecoration: 'none',fontSize: '0.9em'}} onClick={() => returnCallback()}>Return to Client Search</Button>
                        </div>
                    </Col>
                    <Col xs={12} lg={9}>
                        {
                            _renderSelectedPage(activeIndex)
                        }
                    </Col>
                </Row>
            </div>
            <Offcanvas show={showNavbar} onHide={_handleNavClose} onExited={_handleNavExit}>
                <Offcanvas.Header className={styles.navHeader} closeButton>
                    <Offcanvas.Title>{_renderTitle(activeSection)}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={styles.NavContainer}>
                    {
                        activeSection !== null
                        ?
                            _renderActiveSection(activeSection)
                        :
                        navitems.map((item,index) => (
                            <ButtonOverride key={index} className={`${styles.topBtn} ${_isParentActive(item.id) ? styles.active : ''} mt-3`} type="button" onClick={() => setActiveSection(item.id)}>
                                <span>{item.title}</span>
                                <FontAwesomeIcon className={styles.navIcon} icon={faCircleArrowRight} size="xl" />
                            </ButtonOverride>
                        ))
                    }
                    </div>
                    <div className={styles.NavFooter}>
                        {
                            activeSection !== null
                            ?
                                <ButtonOverride className={`${styles.returnBtn} ${styles.back}`} type="button" onClick={() => setActiveSection(null)}>
                                    <FontAwesomeIcon icon={faCircleArrowLeft} size="2xl" />
                                </ButtonOverride>
                            :
                                <Button variant="link" type="button" className={`${styles.returnLink} ${styles.removepadding}`} onClick={() => returnCallback()}>Return to Client Search</Button>
                        }
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </LoggedInLayout>
    )
}

export default ClientFormsPage;