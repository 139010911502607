import { useField } from "formik";
import { useEffect } from "react";
import { Form } from "react-bootstrap";

const InlineRadioGroup = ({name,primaryLabel,data,inline = true}) => {
    const [field,meta,helpers] = useField(name);

    return(
        <Form.Group>
            <h6 className="mb-0">
                {primaryLabel}
            </h6>
            <div>
                {
                    data.map((item,index) => (
                        <Form.Check
                            key={index}
                            type="radio"
                            label={item.title}
                            id={`${name}${index}`}
                            inline={inline}
                            {...field}
                            isInvalid={meta.touched && meta.error}
                            value={item.value}
                            checked={field.value === item.value}
                            onChange={(e) => helpers.setValue(item.value)}
                        />
                    ))
                }
            </div>
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                    {meta.error}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    )
}

export default InlineRadioGroup;