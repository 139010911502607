import styles from '../styles/Loader.module.css';

const TreeSkeleton = () => {
    return(
        <>
            <div className={`${styles.treeSearchContainer} mb-2`}>
                <div className={styles.treeSearchBox}></div>
            </div>
            <div className={styles.treeBody}>
            </div>
        </>
    )
}

export default TreeSkeleton;