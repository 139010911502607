import { Modal } from "react-bootstrap";
import LineLoader from "./line-loader";
import styles from "../styles/Loader.module.css";

const ModalSkeleton = ({text,type}) => {
    return (
        <>
            <Modal.Header>
                <div className={styles.skeletonLine}></div>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.skeletonBox}></div>
                {
                    text && type?
                        <LineLoader text={text} type={type} />
                    :
                        null
                }
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <div className={styles.skeletonButton}></div>
                <div className={styles.skeletonButton}></div>
            </Modal.Footer>
        </>
    );
}

export default ModalSkeleton;