import LoggedInLayout from "../../layouts/loggedin-layout";
import { Button, Col,Row,Breadcrumb,Dropdown, Pagination} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useState,useEffect } from "react";
import useApi from "../../hooks/use-api";
import "../../index.css";
import UserListItem from "../../data/userlistitem";
import moment from "moment";
import UserManSkeleton from "../../components/usermanagementskeleton";
import InsetAlert from "../../components/insetalert";
import UserModal from "../../Modals/usermodal";
import NonExpandableSearchBox from "../../components/nonexpandablesearchbox";
import { ModalStatusContextProvider } from "../../contexts/modalstatuscontext";

function ManageUsers(){
    useEffect(() => {
        document.title = "MFP - Manage Users";
    },[]);

    const {processing, error, getRequest,getRequestMulti} = useApi();
    const [modalShown,setModalShown] = useState(false);
    const [roles,setRoles] = useState([]);
    const [permissions,setPermissions] = useState([]);
    const [emailgroups,setEmailgroups] = useState([]);
    const [edituser,setEditUser] = useState(null);
    const [userStuff,setUserList] = useState([]);
    const [isRefreshing,setIsRefreshing] = useState(false);
    
    useEffect(() => {
        const get = async () => {
            // const data = await getRequestMulti([{endpoint: "Users",params: {timestamp: Date.now()}},{endpoint: "Roles",params: {timestamp: Date.now()}},{endpoint: "EmailGroups",params: {timestamp: Date.now()}},{endpoint: "Permissions",params: {timestamp: Date.now()}}]);
            // setUserList(data["Users"].data);
            // setRoles(data["Roles"].data);
            const [data, status] = await getRequest("tester", {timestamp: Date.now()}); 
            if (status === 200) {
                setRoles(data.roles);
                setPermissions(data.permissions);
                setEmailgroups(data.emailGroups);
                setUserList(data.users);
            }
            else{
                setUserList([]);
            } 
        };

        get();
    }, []);

    const refreshUsers = () => {
        setIsRefreshing(true);
        const get = async () => {
            const [data,status] = await getRequest("tester",null);
            if(status === 200){
                setUserList(data.users);
                setIsRefreshing(false);
            }
            else{
                setUserList([]);
                setIsRefreshing(false);
            }
        }

        get();
    }


    const editOpen = (key) => {
        setEditUser(key);
        setModalShown(true);
    };

    const handleOpen = () => { setEditUser(null); setModalShown(true);};
    
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(10);
    const [searchTerm,setSearchTerm] = useState('');
    const [ordering,setOrdering] = useState('name-asc');
    const [orderingText,setOrderingText] = useState("Order by Name Ascending");
    const [filteredUsers,setFilteredUsers] = useState(userStuff);
    const [totalFilteredUsers,setTotalFilteredUsers] = useState(userStuff.length);
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const totalPages = Math.ceil(totalFilteredUsers / itemsPerPage);


    useEffect(() => {
        let processedUsers = [...userStuff];

        if(searchTerm){
            processedUsers = userStuff.filter(user => {
                return Object.values(user).some(value => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            });
        }

        if(ordering){
            let orderingtext;
            switch(ordering){
                case 'uname-asc':
                    orderingtext = "Order by Username (Ascending)";
                    processedUsers.sort((a,b) => a.username.localeCompare(b.username));
                    break;
                case 'uname-desc':
                    orderingtext = "Order by Username (Descending)";
                    processedUsers.sort((a,b) => b.username.localeCompare(a.username));
                    break;
                case 'name-asc':
                    orderingtext = "Order by Name (Ascending)";
                    processedUsers.sort((a,b) => a.name.localeCompare(b.name));
                    break;
                case 'name-desc':
                    orderingtext = "Order by Name (Descending)";
                    processedUsers.sort((a,b) => b.name.localeCompare(a.name));
                    break;
                case 'email-asc':
                    orderingtext = "Order by Email (Ascending)";
                    processedUsers.sort((a,b) => a.email.localeCompare(b.email));
                    break;
                case 'email-desc':
                    orderingtext = "Order by Username (Descending)";
                    processedUsers.sort((a,b) => b.email.localeCompare(a.email));
                    break;
                case 'lactive-asc':
                    orderingtext = "Order by Last Active (Ascending)";
                    processedUsers.sort((a,b) => moment(a.lastactive,"M/D/YYYY h:mm:ss A").valueOf() - moment(b.lastactive,"M/D/YYYY h:mm:ss A").valueOf());
                    break;
                case 'lactive-desc':
                    orderingtext = "Order by Last Active (Descending)";
                    processedUsers.sort((a,b) => moment(b.lastactive,"M/D/YYYY h:mm:ss A").valueOf() - moment(a.lastactive,"M/D/YYYY h:mm:ss A").valueOf());
                    break;
                case 'status-asc':
                    orderingtext = "Order by Status (Ascending)";
                    processedUsers.sort((a,b) => a.status.localeCompare(b.status));
                    break;
                case 'status-desc':
                    orderingtext = "Order by Status (Descending)";
                    processedUsers.sort((a,b) => b.status.localeCompare(a.status));
                    break;    
                default:
                    console.error("Invalid ordering state!");
            }
            setOrderingText(orderingtext);
        }

        setTotalFilteredUsers(processedUsers.length);


        setFilteredUsers(processedUsers.slice(startIndex,endIndex));
        

    },[userStuff,searchTerm,currentPage,itemsPerPage,ordering])

    const handleItemCountChange = (itemCount) => {
        const firstItemIndex = (currentPage - 1) * itemsPerPage;
        const newPageNumber = Math.ceil((firstItemIndex + 1) / itemCount);
        setItemsPerPage(itemCount);
        setCurrentPage(newPageNumber);

    }
    return(
            <LoggedInLayout>
                <div style={{padding: "25px"}}>
                    <Row className="mb-4">
                        <Col xs={12}>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <h3 className="me-3">User Management</h3>
                                    <Breadcrumb>
                                        <Breadcrumb.Item className="primarybreadcrumb">Admin</Breadcrumb.Item>
                                        <Breadcrumb.Item className="secondarybreadcrumb" active>User Management</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div>
                                    <Button variant="success" onClick={handleOpen} className="driverBtn" disabled={processing || error}><span className="driverBtnIcon"><FontAwesomeIcon icon={faUserPlus} size="lg"/></span><span className="driverBtnText">Add User</span></Button>
                                </div>
                            </div>
                            <div className="filteroptions">
                                <div className="filterparent">
                                    <div className="filteritems">
                                        <div className="me-2 filterunit">
                                            <Dropdown>
                                                <Dropdown.Toggle className="dropdowncustom internalfilter" id="dropdown-basic" disabled={processing || error}>
                                                    {orderingText}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("uname-asc")} type="button">Order by Username (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("uname-desc")} type="button">Order by Username (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("name-asc")} type="button">Order by Name (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("name-desc")} type="button">Order by Name (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("email-asc")} type="button">Order by Email (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("email-desc")} type="button">Order by Email (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("lactive-asc")} type="button">Order by Last Active (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("lactive-desc")} type="button">Order by Last Active (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("status-asc")} type="button">Order by Status (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("status-desc")} type="button">Order by Status (Descending)</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="filterunit">
                                            {/* <div className="search-box internalfilter">
                                                <input type="text" placeholder="Search..."  id="usersearch" className={`search-input ${processing || error ? 'opacitydisabled' : ''}`} value={searchTerm} onChange={e => {setCurrentPage(1); setSearchTerm(e.target.value);}} disabled={processing || error}
                                                    onKeyDown={(e) => clearSearch(e)}
                                                />
                                            </div> */}
                                            <NonExpandableSearchBox id="usersearch" placeholder="Search..." searchTerm={searchTerm} setSearchTerm={setSearchTerm} value={searchTerm} onChange={e => {setCurrentPage(1); setSearchTerm(e.target.value);}} disabled={processing || error} />
                                        </div>
                                    </div>
                                    <div className="pagingtogglez">
                                        <small className="me-2">{startIndex + 1} - {endIndex} of {totalFilteredUsers}</small>
                                        <Dropdown>
                                                <Dropdown.Toggle className="dropdowncustom" id="dropdown-basictwo" disabled={processing || error}>
                                                    {itemsPerPage}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(10)} type="button">10</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(25)} type="button">25</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(50)} type="button">50</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(100)} type="button">100</Dropdown.Item>
                                                </Dropdown.Menu>
                                        </Dropdown> 
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="seperator mb-2"></div>
                    {
                        processing && !isRefreshing
                        ?
                            <UserManSkeleton />
                        :
                            <Row className="mt-4">
                                <Col xs={12}>
                                    {
                                        error 
                                        ? 
                                            <div className="d-flex justify-content-center mb-2">
                                                <InsetAlert error={error} dismissible={false} />
                                            </div>
                                        :
                                            filteredUsers.length
                                            ?
                                                null
                                            :
                                                searchTerm
                                                ?
                                                    <div className="d-flex justify-content-center">
                                                        <p>No results found.</p>
                                                    </div>
                                                :
                                                    <div className="d-flex justify-content-center">
                                                        <p>No users currently exist.</p>
                                                    </div>
                                        
                                    }
                                    {filteredUsers.map(user => (
                                        <UserListItem user={user} key={user.id} modalCallback={() => {editOpen(user.id)}}/>
                                    ))}
                                    {
                                        userStuff.length
                                        ?
                                            <Pagination className="justify-content-end">
                                                <Pagination.Prev onClick={() => setCurrentPage((prev) => Math.max(prev - 1,1))} disabled={currentPage === 1 || totalPages === 0} />
                                                {[...Array(totalPages).keys()].map(page => (
                                                    <Pagination.Item
                                                        key={page + 1}
                                                        active={page + 1 === currentPage}
                                                        onClick={() => {
                                                            setCurrentPage(page + 1)
                                                        }}
                                                    >
                                                        {page + 1}
                                                    </Pagination.Item>
                                                ))}
                                                <Pagination.Next onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages || totalPages === 0} />
                                            </Pagination>
                                        :
                                            null
                                    
                                    }
                                    
                                </Col>
                        </Row>
                    }
                </div>
                <ModalStatusContextProvider>
                    <UserModal
                        modalShown={modalShown}
                        hideModal={() => setModalShown(false)}
                        targetID={edituser}
                        permissions={permissions}
                        emailgroups={emailgroups}
                        roles={roles}
                        userChangeCallback={() => refreshUsers()}
                    />
                </ModalStatusContextProvider>
            </LoggedInLayout>
    )

    
}

export default ManageUsers;