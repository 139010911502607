import { Badge, Button } from "react-bootstrap";

const ApplicationListItem = ({appID,status,statusText,creationDate,lastActiveDate,selectCallback}) => {

    const getStatusBadge = (status) => {
        switch(status){
            case 'inprogress':
                return 'warning'
            case 'denied':
                return 'danger'
            default:
                return 'success'
        }
    }

    const getStatusText = (status) => {
        switch(status){
            case 'inprogress':
                return 'In Progress'
            case 'denied':
                return 'Denied'
            default:
                return 'Completed'
        }
    }

    return(
        <div className={`applicationListing ${status}`}>
            <div className="d-flex justify-content-between">
                <h5 className="mb-0">{appID}</h5>
                <div>
                    <Button type="button" title="View History" aria-label="View History" variant="outline-secondary" size="sm" className="me-1">View History</Button>
                    <Button type="button" title="Select Application" aria-label="Select Application" variant="outline-primary" size="sm" onClick={selectCallback}>Select</Button>
                </div>
            </div>
                <Badge pill bg={getStatusBadge(status)}>{getStatusText(status)}</Badge>
                <h6 className="mb-0">Current Status</h6>
                <p>{statusText}</p>
                <h6 className="mb-0">Application Creation Date</h6>
                <p>{creationDate}</p>
                <h6 className="mb-0">Last Activity Date</h6>
                <p>{lastActiveDate}</p>
        </div>
    )
}

export default ApplicationListItem;