import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const useMessageStore = create(
  persist(
    (set) => ({
        activities: [],
        toasts: [],
        addActivity: (activity) => set((state) => ({activities: [activity,...state.activities]})),
        addToast: (toast) => set(state => ({toasts: [...state.toasts,toast]})),
        removeActivity: (id) => set((state) => ({
          activities: state.activities.filter(activity => activity.id !== id)
        })),
        removeToast: (id) => set((state) => ({
          toasts: state.toasts.filter(toast => toast.id !== id)
        }))
    }),
    {
      name: 'MFP-ActivityLog',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useMessageStore;