import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const InputField = ({colSize, label,labelType = null, ...props}) => {
    const [field, meta] = useField(props);
    const safeId = label.replace(/[^\w]/gi, "").substring(0, 9);
    return (
        <Form.Group className="mb-3" as={Col} sm={colSize} controlId={`input-${safeId}`}> 
        {
            labelType
            ?
                <>
                    <Form.Label className='mb-0' as={labelType}>{label}</Form.Label>
                    <Form.Control
                        {...field}
                        {...props}
                        isInvalid={meta.touched && meta.error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                </>
            :
                <>
                    <Form.Label className='mb-0' >{label}</Form.Label>
                    <Form.Control
                        {...field}
                        {...props}
                        isInvalid={meta.touched && meta.error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                </>
        }
        </Form.Group>
    );
};

export default InputField;
