import styles from '../styles/LegalWarning.module.css';

const LegalWarning = () => {
    return(
        <div className={styles.warningBox}>
            <strong>Legal Warning</strong>
            <p>
                Unauthorized access to this application is strictly prohibited and may be subject to criminal prosecution. Accessing or attempting to access this system without
                authorization constitutes an offense punishable by law. By proceeding, you acknowledge that you have read, understood, and agreed to comply with these terms.
            </p>
        </div>
    )
}

export default LegalWarning;