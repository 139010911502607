import { faMultiply, faSearch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import ButtonOverride from "./buttonoverride";

const SearchBox = ({hasSearchableExpansion = false,searchTerm,setSearchTerm,setClearHandler,...props}) => {
    const inputRef = useRef(null);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClear = () => {
        setSearchTerm('');
        inputRef.current.value = '';
    }

    const handleEscapeKey = (e) => {
        e.preventDefault();
        handleClear();
    }

    useEffect(() => {
        setClearHandler(handleClear);
    },[setClearHandler]);

    return(
        <div className={`searchBoxStyle ${hasSearchableExpansion ? 'Expanded' : ''}`}>
            <input type="text" {...props} className="searchBoxInput" onChange={handleChange} onKeyDown={(e) => {e.key === "Escape" && handleEscapeKey(e)}} ref={inputRef}/>
            {
                searchTerm === ''
                ?
                    <FontAwesomeIcon className="searchIcon" icon={faSearch} size="lg"/>
                :
                    <ButtonOverride type="button" title="Clear Search" aria-label="Clear Search" style={{padding: "0",width: "unset"}} onClick={() => handleClear()}>
                        <FontAwesomeIcon className="searchIcon hasAction" icon={faMultiply} size="lg" />
                    </ButtonOverride>
                    
            }
        </div>
    )
}

export default SearchBox;