import { useEffect, useRef, useState } from "react";
import useApi from "../hooks/use-api";
import useMessageHub from "../hooks/use-messagehub";
import { useModalStatusContext } from "../contexts/modalstatuscontext";
import * as Yup from 'yup';
import { Formik} from 'formik';
import BaseModal from "./basemodal";
import ActionResultText from "../utilities/ActionResultText";
import FloatLabel from "../formik/floating-label";
import { Col } from "react-bootstrap";
import BubbleSelection from "../components/bubbleselection";
import EmailEventSelection from "../components/emaileventselection";
import EmailRecipientSelection from "../components/emailrecipientselection";
import EmailVariableSelection from "../components/emailvariableselection";
import ControlledFloatLabel from "../formik/floating-label-controlled";

const EmailModal = ({modalShown,hideModal,targetID,emailChangeCallback,autoFocusedInput}) => {
    const {processing,error,getRequest,postRequest,putRequest} = useApi();
    const childSubmitHandlerRef = useRef(null);
    const [modalLocked,setModalLocked] = useState(false);
    const {registerActivity} = useMessageHub();
    const {isLoading,HasError,handleError} = useModalStatusContext();
    const [lockFocus,setLockFocus] = useState(false);
    const [bodyText,setBodyText] = useState('');

    const initialValues = {
        subject: '',
        body: '',
        recipients: {users: [],groups: []},
        events: []

    }; 

    const validationSchema = Yup.object({
        subject: Yup.string().required("Required"),
        body: Yup.string().required("Required"),
        recipients: Yup.object().test(
            'combined-length',
            'At least one recipient must be specified',
            function (value) {
                const { users, groups } = value;
                return users.length + groups.length >= 1;
            }
        ),
        events: Yup.array().of(Yup.number()).min(1,"At least one event must be supplied.").required("At least one event must be supplied.")
    });


    const[email,setEmail] = useState(initialValues);
    const [currentEvents,setCurrentEvents] = useState();
    useEffect(() => {
        setCurrentEvents(email.events);
    },[email]);
    
    const getEmail = async () => {
        const [data,status] = await getRequest("email",{id: targetID});
        return [data,status];
    };

    const submitEmailRequest = async (values) => {
        const [data, status] =  targetID ? await putRequest("editemail", {...values,id: targetID}) : await postRequest("addemail",{...values,id: 0});
        return [data,status];
    };

    const handleSubmitFunction = async (values) => {
        if(childSubmitHandlerRef.current){
            await childSubmitHandlerRef.current(values);
        }
    };

    const ActionCompleteCallback = (ActionResult) => {
        registerActivity(
            {
                body: ActionResultText({...ActionResult,objectTarget: 'Role'}),
                errorFlag: ActionResult.error
            },
            ActionResult.sessionExpired,
            true
        )
        emailChangeCallback();
    }
    return(
        <Formik
        initialValues={email}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmitFunction}
    >
        {
            formik => 
                <BaseModal
                    modalShown={modalShown}
                    closeModal={() => hideModal()}
                    ModalTitle={targetID ? <span>Edit Email [{email.subject}]</span> : <span>Add Email</span>}
                    loadData={getEmail}
                    submitData={submitEmailRequest}
                    objectID={targetID}
                    objectType="Email"
                    setData={setEmail}
                    error={error}
                    processing={processing}
                    initialValues={initialValues}
                    size="lg"
                    setSubmitHandler={handler => childSubmitHandlerRef.current = handler}
                    formikProps={formik}
                    modalLocked={modalLocked}
                    setModalLocked={setModalLocked}
                    actionCompleteCallback={ActionCompleteCallback}
                    focusLock={lockFocus}
                >
                     <div className="box box--emailinfo">
                        <EmailEventSelection selectedEventIds={email.events} fieldName={"events"} focusLock={lockFocus} lockFocusFunction={setLockFocus} setCurrentEvents={(data) => setCurrentEvents(data)}/>
                        <EmailRecipientSelection fieldName={"recipients"} selectedRecipients={email.recipients} focusLock={lockFocus} lockFocusFunction={setLockFocus}/>
                        {/* <EmailVariableSelection selectedEvents={currentEvents} filterText={bodyText} /> */}
                        <Col xs={12}>
                            <FloatLabel 
                                type="text"
                                name="subject"
                                label="Email Subject"
                                placeholder="Email Subject"
                                disabled={formik.isSubmitting || processing || HasError}
                                autoComplete="offz"
                                tabIndex={lockFocus && -1}
                            />
                        </Col>
                        <Col xs={12}>
                            {/* <FloatLabel
                                as="textarea"
                                type="text"
                                name="body"
                                label="Email Body"
                                placeholder="Email Body"
                                disabled={formik.isSubmitting || processing || HasError}
                                autoComplete="offz"
                                style={{height: '250px'}}
                                tabIndex={lockFocus && -1}
                                onChange={(e) => setBodyText(e.target.value)}
                                value={bodyText}
                            /> */}
                            <ControlledFloatLabel
                                as="textarea"
                                type="text"
                                name="body"
                                label="Email Body"
                                placeholder="Email Body"
                                disabled={formik.isSubmitting || processing || HasError}
                                autoComplete="offz"
                                style={{height: '250px'}}
                                tabIndex={lockFocus && -1}
                                valueChangeCallback={(value) => setBodyText(value)}
                            />
                        </Col>
                     </div>
                </BaseModal> 
        }
    
    </Formik>
    )
}

export default EmailModal;