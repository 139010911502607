import LoggedInLayout from "../../layouts/loggedin-layout";
import { Col, Row,Breadcrumb,Dropdown,Button, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useApi from "../../hooks/use-api";
import { useEffect,useState } from "react";
import RoleListItem from "../../data/rolelistitem";
import UserManSkeleton from "../../components/usermanagementskeleton";
import InsetAlert from "../../components/insetalert";
import moment from "moment";
import RoleModal from "../../Modals/rolemodal";
import NonExpandableSearchBox from "../../components/nonexpandablesearchbox";
import {ModalStatusContextProvider} from "../../contexts/modalstatuscontext";

const ManageRoles = () => {

    useEffect(() => {
        document.title = "MFP - Manage Roles";
    },[]);

    const {processing, error, getRequest} = useApi();
    const [modalShown,setModalShown] = useState(false);
    const [isRefreshing,setIsRefreshing] = useState(false);
    const [editRole,setEditRole] = useState(null);
    const [roles,setRoles] = useState([]);

    useEffect(() => {
        const get = async () => {
            const [data, status] = await getRequest("getroles", {timestamp: Date.now()}); 
            if (status === 200) {
                setRoles(data);
            }
            else{
                setRoles([]);
            }
        };

        get();
    }, []);

    const refreshRoles = () => {
        setIsRefreshing(true);
        const get = async () => {
            const [data,status] = await getRequest("getroles",null);
            if(status === 200){
                setRoles(data);
                setIsRefreshing(false);
            }
            else{
                setRoles([]);
                setIsRefreshing(false);
            }
        }

        get();
    }

    const handleOpen = () => {
        setEditRole(null);
        setModalShown(true);
    }

    const editOpen = (roleid) => {
        setEditRole(roleid);
        setModalShown(true);
    }
    
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(10);
    const [searchTerm,setSearchTerm] = useState('');
    const [ordering,setOrdering] = useState('name-asc');
    const [orderingText,setOrderingText] = useState("Order by Name Ascending");
    const [filteredRoles,setFilteredRoles] = useState(roles);
    const [totalFilteredRoles,setTotalFilteredRoles] = useState(roles.length);
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const totalPages = Math.ceil(totalFilteredRoles / itemsPerPage);

    useEffect(() => {
        let processedRoles = [...roles];

        if(searchTerm){
            processedRoles = roles.filter(role => {
                return Object.values(role).some(value => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            });
        }

        if(ordering){
            let orderingtext;
            switch(ordering){
                case 'name-asc':
                    orderingtext = "Order by Name (Ascending)";
                    processedRoles.sort((a,b) => a.name.localeCompare(b.name));
                    break;
                case 'name-desc':
                    orderingtext = "Order by Name (Descending)";
                    processedRoles.sort((a,b) => b.name.localeCompare(a.name));
                    break;
                case 'createdon-asc':
                    orderingtext = "Order by Created On (Ascending)";
                    processedRoles.sort((a,b) => moment(a.createdon,"M/D/YYYY h:mm:ss A").valueOf() - moment(b.createdon,"M/D/YYYY h:mm:ss A").valueOf());
                    break;
                case 'createdon-desc':
                    orderingtext = "Order by Created On (Descending)";
                    processedRoles.sort((a,b) => moment(b.createdon,"M/D/YYYY h:mm:ss A").valueOf() - moment(a.createdon,"M/D/YYYY h:mm:ss A").valueOf());
                    break;
                case 'usercount-asc':
                    orderingtext = "Order by User Count (Ascending)";
                    processedRoles.sort((a,b) => a.usercount - b.usercount);
                    break; 
                case 'usercount-desc':
                    orderingtext = "Order by User Count (Descending)";
                    processedRoles.sort((a,b) => b.usercount - a.usercount);
                    break; 
                case 'permcount-asc':
                    orderingtext = "Order by Permission Count (Ascending)";
                    processedRoles.sort((a,b) => a.permcount - b.permcount);
                    break; 
                case 'permcount-desc':
                    orderingtext = "Order by Permission Count (Descending)";
                    processedRoles.sort((a,b) => b.permcount - a.permcount);
                    break; 
                default:
                    console.error("Invalid ordering state!");
            }
            setOrderingText(orderingtext);
        }

        setTotalFilteredRoles(processedRoles.length);


        setFilteredRoles(processedRoles.slice(startIndex,endIndex));
        

    },[roles,searchTerm,currentPage,itemsPerPage,ordering])

    const handleItemCountChange = (itemCount) => {
        const firstItemIndex = (currentPage - 1) * itemsPerPage;
        const newPageNumber = Math.ceil((firstItemIndex + 1) / itemCount);
        setItemsPerPage(itemCount);
        setCurrentPage(newPageNumber);

    }

    const _paginationClick = (prev) => {
        window.scrollTo(0,0);
        if(prev){
            setCurrentPage((prev) => Math.max(prev - 1,1));
        }
        else{
            setCurrentPage((prev) => Math.min(prev + 1, totalPages));
        }
    }

    return(
        <LoggedInLayout>
            <div style={{padding: "25px"}}>
                <Row className="mb-4">
                    <Col xs={12}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h3 className="me-3">Role Management</h3>
                                <Breadcrumb>
                                    <Breadcrumb.Item className="primarybreadcrumb">Admin</Breadcrumb.Item>
                                    <Breadcrumb.Item className="secondarybreadcrumb" active>Role Management</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div>
                                <Button variant="success" onClick={handleOpen} className="driverBtn" disabled={processing || error}><span className="driverBtnIcon"><FontAwesomeIcon icon={faPlus} size="lg"/></span><span className="driverBtnText">Add Role</span></Button>
                            </div>
                        </div>
                            <div className="filteroptions">
                                <div className="filterparent">
                                    <div className="filteritems">
                                        <div className="filterunit funit">
                                            <Dropdown>
                                                <Dropdown.Toggle className="dropdowncustom internalfilter" id="dropdown-basic" disabled={processing || error}>
                                                    {orderingText}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("name-asc")} type="button">Order by Name (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("name-desc")} type="button">Order by Name (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("createdon-asc")} type="button">Order by Created On (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("createdon-desc")} type="button">Order by Created On (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("usercount-asc")} type="button">Order by User Count (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("usercount-desc")} type="button">Order by User Count (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("permcount-asc")} type="button">Order by Permission Count (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("permcount-desc")} type="button">Order by Permission Count (Descending)</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="filterunit">
                                            <NonExpandableSearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search..." id="rolesearch" value={searchTerm} onChange={e => {setCurrentPage(1); setSearchTerm(e.target.value);}} disabled={processing || error}/>
                                        </div>
                                    </div>
                                    <div className="pagingtogglez">
                                        <small className="me-2">{startIndex + 1} - {endIndex} of {totalFilteredRoles}</small>
                                        <Dropdown>
                                                <Dropdown.Toggle className="dropdowncustom" id="dropdown-basictwo" disabled={processing || error}>
                                                    {itemsPerPage}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(10)} type="button">10</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(25)} type="button">25</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(50)} type="button">50</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(100)} type="button">100</Dropdown.Item>
                                                </Dropdown.Menu>
                                        </Dropdown> 
                                    </div>
                                </div>
                            </div>
                    </Col>
                </Row>
                <div className="seperator mb-2"></div>
                {
                    processing && !isRefreshing
                    ?
                        <UserManSkeleton />
                    :
                        <Row className="mt-4">
                            <Col xs={12}>
                                {
                                    error
                                    ?
                                        <div className="d-flex justify-content-center mb-2">
                                            <InsetAlert error={error} dismissible={false} />
                                        </div>
                                    :
                                        filteredRoles.length
                                        ?
                                            null
                                        :
                                            searchTerm
                                            ?
                                                <div className="d-flex justify-content-center">
                                                    <p>No results found.</p>
                                                </div>
                                            :
                                                <div className="d-flex justify-content-center">
                                                    <p>No roles currently exist.</p>
                                                </div>
                                }
                                {filteredRoles.map(role => (
                                        <RoleListItem key={role.id} role={role} clickActionCallback={() => {editOpen(role.id)}} />
                                ))}
                                {
                                        roles.length
                                        ?
                                            <Pagination className="justify-content-end">
                                                <Pagination.Prev onClick={() => _paginationClick(true)} disabled={currentPage === 1 || totalPages === 0} />
                                                {[...Array(totalPages).keys()].map(page => (
                                                    <Pagination.Item
                                                        key={page + 1}
                                                        active={page + 1 === currentPage}
                                                        onClick={() => {
                                                            setCurrentPage(page + 1)
                                                        }}
                                                    >
                                                        {page + 1}
                                                    </Pagination.Item>
                                                ))}
                                                <Pagination.Next onClick={() => _paginationClick(false)} disabled={currentPage === totalPages || totalPages === 0} />
                                            </Pagination>
                                        :
                                            null
                                    
                                    }
                            </Col>
                        </Row>
                }
            </div>
            <ModalStatusContextProvider>
                <RoleModal 
                    modalShown={modalShown}
                    hideModal={() => setModalShown(false)}
                    targetID={editRole}
                    roleChangeCallback={() => console.log("changes")}
                />
            </ModalStatusContextProvider>
        </LoggedInLayout>
    )

}

export default ManageRoles;