import { Button } from "react-bootstrap";
import styles from "../styles/buttons.module.css";

const FloatingActionButton = ({className,children,...props}) => {
    return (
        <Button className={`${styles.floatingActionButton} ${className ? className : ''}`} {...props}>
            {children}
        </Button>
    )
}

FloatingActionButton.Icon = function ({children}) {
    return(
        <span className={styles.floatingActionButtonIcon}>
            {children}
        </span>
    )
}

FloatingActionButton.Text = function ({children}) {
    return(
        <span className={styles.floatingActionButtonText}>
            {children}
        </span>
    )
}

export default FloatingActionButton;