import { Col, Row,Breadcrumb,Dropdown,Button, Card } from "react-bootstrap";
import LoggedInLayout from "../../layouts/loggedin-layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState,useEffect } from "react";
import useApi from "../../hooks/use-api";
import NonExpandableSearchBox from "../../components/nonexpandablesearchbox";
import EmailListItem from "../../data/emaillistitem";
import EmailModal from "../../Modals/emailmodal";
import { ModalStatusContextProvider } from "../../contexts/modalstatuscontext";
import moment from "moment";
import UserManSkeleton from "../../components/usermanagementskeleton";
import InsetAlert from "../../components/insetalert";


const ManageEmails = () => {

    useEffect(() => {
        document.title = "MFP - Manage Emails";
    },[]);

    const {processing, error, getRequest} = useApi();


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(10);
    const [searchTerm,setSearchTerm] = useState('');
    const [ordering,setOrdering] = useState('sub-asc');
    const [orderingText,setOrderingText] = useState("Order by Subject Ascending");
    const [isRefreshing,setIsRefreshing] = useState(false);

    const [emails,setEmails] = useState([{id: 3}]);
    const [filteredEmails,setFilteredEmails] = useState(emails);
    const [totalFilteredEmails,setTotalFilteredEmails] = useState(filteredEmails.length);
    

    const [editEmail,setEditEmail] = useState(null);

    const [modalShown,setModalShown] = useState(false);
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const totalPages = Math.ceil(totalFilteredEmails / itemsPerPage);

    useEffect(() => {
        let processedEmails = [...emails];

        if(searchTerm){
            processedEmails = emails.filter(role => {
                return Object.values(role).some(value => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            });
        }

        if(ordering){
            let orderingtext;
            switch(ordering){
                case 'sub-asc':
                    orderingtext = "Order by Subject (Ascending)";
                    processedEmails.sort((a,b) => a.subject.localeCompare(b.subject));
                    break;
                case 'sub-desc':
                    orderingtext = "Order by Subject (Descending)";
                    processedEmails.sort((a,b) => b.subject.localeCompare(a.subject));
                    break;
                case 'createdon-asc':
                    orderingtext = "Order by Created On (Ascending)";
                    processedEmails.sort((a,b) => moment(a.createdon,"M/D/YYYY h:mm:ss A").valueOf() - moment(b.createdon,"M/D/YYYY h:mm:ss A").valueOf());
                    break;
                case 'createdon-desc':
                    orderingtext = "Order by Created On (Descending)";
                    processedEmails.sort((a,b) => moment(b.createdon,"M/D/YYYY h:mm:ss A").valueOf() - moment(a.createdon,"M/D/YYYY h:mm:ss A").valueOf());
                    break;
                case 'usercount-asc':
                    orderingtext = "Order by User Count (Ascending)";
                    processedEmails.sort((a,b) => a.usercount - b.usercount);
                    break; 
                case 'usercount-desc':
                    orderingtext = "Order by User Count (Descending)";
                    processedEmails.sort((a,b) => b.usercount - a.usercount);
                    break; 
                case 'permcount-asc':
                    orderingtext = "Order by Permission Count (Ascending)";
                    processedEmails.sort((a,b) => a.permcount - b.permcount);
                    break; 
                case 'permcount-desc':
                    orderingtext = "Order by Permission Count (Descending)";
                    processedEmails.sort((a,b) => b.permcount - a.permcount);
                    break; 
                default:
                    console.error("Invalid ordering state!");
            }
            setOrderingText(orderingtext);
        }

        setTotalFilteredEmails(processedEmails.length);


        setFilteredEmails(processedEmails.slice(startIndex,endIndex));
        

    },[emails,searchTerm,currentPage,itemsPerPage,ordering])

    const handleOpen = () => {
        setEditEmail(null);
        setModalShown(true);
    }

    const editOpen = (emailID,focusElement) => {
        console.log(focusElement);
        setEditEmail(emailID);
        setModalShown(true);
    }

    const handleItemCountChange = () => {

    }
    return(
        <LoggedInLayout>
            <div style={{padding: '25px'}}>
                <Row className="mb-4">
                    <Col xs={12}>
                    <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <h3 className="me-3">Email Management</h3>
                                    <Breadcrumb>
                                        <Breadcrumb.Item className="primarybreadcrumb">Admin</Breadcrumb.Item>
                                        <Breadcrumb.Item className="secondarybreadcrumb" active>Email Management</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div>
                                    <Button variant="success" onClick={handleOpen} className="driverBtn" disabled={processing || error}><span className="driverBtnIcon"><FontAwesomeIcon icon={faEnvelope} size="lg"/></span><span className="driverBtnText">Add Email</span></Button>
                                </div>
                            </div>
                            <div className="filteroptions">
                                <div className="filterparent">
                                    <div className="filteritems">
                                        <div className="me-2 filterunit">
                                            <Dropdown>
                                                <Dropdown.Toggle className="dropdowncustom internalfilter" id="dropdown-basic" disabled={processing || error}>
                                                    {orderingText}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("uname-asc")} type="button">Order by Username (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("uname-desc")} type="button">Order by Username (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("name-asc")} type="button">Order by Name (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("name-desc")} type="button">Order by Name (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("email-asc")} type="button">Order by Email (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("email-desc")} type="button">Order by Email (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("lactive-asc")} type="button">Order by Last Active (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("lactive-desc")} type="button">Order by Last Active (Descending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("status-asc")} type="button">Order by Status (Ascending)</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => setOrdering("status-desc")} type="button">Order by Status (Descending)</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="filterunit">
                                            <NonExpandableSearchBox id="usersearch" placeholder="Search..." searchTerm={searchTerm} setSearchTerm={setSearchTerm} value={searchTerm} onChange={e => {setCurrentPage(1); setSearchTerm(e.target.value);}} disabled={processing || error} />
                                        </div>
                                    </div>
                                    <div className="pagingtogglez">
                                        <small className="me-2">{startIndex + 1} - {endIndex} of {totalFilteredEmails}</small>
                                        <Dropdown>
                                                <Dropdown.Toggle className="dropdowncustom" id="dropdown-basictwo" disabled={processing || error}>
                                                    {itemsPerPage}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(10)} type="button">10</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(25)} type="button">25</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(50)} type="button">50</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => handleItemCountChange(100)} type="button">100</Dropdown.Item>
                                                </Dropdown.Menu>
                                        </Dropdown> 
                                    </div>
                                </div> 
                            </div>
                    </Col>
                </Row>
                <div className="seperator mb-2"></div>
                {
                    processing && !isRefreshing
                    ?
                        <UserManSkeleton />
                    :
                        <Row className="mt-4">
                            <Col xs={12}>
                                {
                                    error
                                    ?
                                        <div className="d-flex justify-content-center mb-2">
                                            <InsetAlert error={error} dismissible={false} />
                                        </div>
                                    :
                                        filteredEmails.length
                                        ?
                                            null
                                        :
                                            searchTerm
                                            ?
                                                <div className="d-flex justify-content-center">
                                                    <p>No results found.</p>
                                                </div>
                                            :
                                                <div className="d-flex justify-content-center">
                                                    <p>No emails currently exist.</p>
                                                </div>
                                }
                                {filteredEmails.map((email) => (
                                    <EmailListItem email={email} key={email.id} clickActionCallback={(focusElement) => editOpen(email.id,focusElement)} />
                                ))}
                            </Col>
                        </Row>  
                }
            </div>
            <ModalStatusContextProvider>
                <EmailModal modalShown={modalShown} hideModal={() => setModalShown(false)} targetID={editEmail} emailChangeCallback={() => alert('fsd')}></EmailModal>
            </ModalStatusContextProvider>
        </LoggedInLayout>
    )

}

export default ManageEmails;