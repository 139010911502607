import { useField } from "formik";
import { Form } from "react-bootstrap";

const FormikCheckbox = ({label,name}) => {
    const [field,meta] = useField(name);

    return(
        <Form.Check
            id={name}
            label={label}
            name={name}
            isInvalid={meta.touched && meta.error}
            checked={field.value === true}
            {...field}
        />
    )
}

export default FormikCheckbox;