import styles from '../styles/Loader.module.css';

const SelectSkeleton = () => {
    return(
        <>
            <div className={styles.skeletonSelectSearch}></div>
            <div className={`${styles.skeletonSelectBox} mt-4`}></div>
        </>
    )
}

export default SelectSkeleton;