import styles from "../styles/Loader.module.css";
import { Row,Col} from "react-bootstrap";

const UserManSkeleton = () => {
    return(
        <>
            <Row className="mt-4">
                <Col xs={12}>
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                    <div className={`mb-3 ${styles.skeletonRoundedTableItem}`} />
                </Col>
            </Row>
        </>
    )
}


export default UserManSkeleton;