import { Form, Formik } from 'formik';
import React from 'react';
import { Col,Row } from 'react-bootstrap';
import FloatingLabelSelect from '../../../formik/floating-label-select';
import FloatLabel from '../../../formik/floating-label';
import InlineRadioGroup from '../../../formik/inlineradiogroup';
import FloatingActionButton from '../../../components/floatingactionbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../formik/input-field';
import FormikCheckbox from '../../../formik/formikcheckbox';

const Map351IIIForm = () => {
    const initialValues = {

    }
    
    return(
        <div className='clientCard'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={() => alert("here")}
      >
        {
          formik => (
            <Form>
              <div className='clientCardInner'>
                <h4 className="text-center">SECTION III - ASSESSMENT PROVIDER INFORMATION</h4>
                <div className='mt-4 scrollSectionMain'>
                  <div className='scrollSectionHeader'>
                    <h4>Provider Information</h4>
                  </div>
                  <div className='scrollSectionBody'>
                  <Row>
                            <Col xs={12}>
                            <FloatLabel
                                    type="text"
                                    name="providername"
                                    label="Assessment/Reassessment Provider Name"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                    type="text"
                                    name="providernumber"
                                    label="Provider Number"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        name="provideraddress"
                                        label="Provider Address"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="providerstate" label="State" data={[{id: -1,name : "Select a state"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="providercounty" label="County" data={[{id: -1,name : "Select a county"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="providerzip" label="Zip" data={[{id: -1,name : "Select a zip"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatLabel
                                    type="text"
                                    name="providercity"
                                    label="Provider City"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                    type="text"
                                    name="providercontactperson"
                                    label="Provider Contact Person"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                <FloatingActionButton type="submit" variant="success">
                  <FloatingActionButton.Icon>
                    <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
                  </FloatingActionButton.Icon>
                  <FloatingActionButton.Text>
                    Save
                  </FloatingActionButton.Text>
                </FloatingActionButton>
              </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
    )
}

export default Map351IIIForm;