import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useState, useEffect } from 'react';
import { Card} from 'react-bootstrap';
import useApi from '../hooks/use-api';
import FloatLabel from '../formik/floating-label';
import LegalWarning from '../components/legalwarning';
import InsetAlert from '../components/insetalert';
import DotLoader from '../components/dotloader';

function RecoverPassword({showLogin}){

    useEffect(() => {
        document.title = "MFP - Recover Password";
    },[]);


    const {processing, error, postRequest} = useApi();
    const [showRecPassFailure, setRecPassFailure] = useState(0);

    const initialValues = {
        Username: ""
    };

    const validationSchema = Yup.object({
        Username: Yup.string().required("Required")
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        const [data, status] = await postRequest("recoverpass", values);
        if (status !== 200) {
            setRecPassFailure(-1);
        } else {
            setRecPassFailure(1);
        }
    };

    return(
        <Row>
                <Col xs={12}>
                    <LegalWarning />
                    <Card>
                        <Card.Img variant="top" src="../mfpbanner.png" alt="Money Follows The Person Banner"/>
                        <Card.Body>
                            <h3>Recover Password</h3>
                            
                                <>
                                {
                                    error ? 
                                        showRecPassFailure === -1
                                        ? 
                                            <InsetAlert heading="Login Failed" error={error} style={{width: '100%'}} />
                                        : 
                                            null 
                                     : 
                                     showRecPassFailure === 1
                                        ? 
                                            <InsetAlert type="Success" heading="Success!" body="An email has been sent to the email address associated with this account to recover your password." style={{width: '100%'}} />
                                        : null
                                }
                                </>
                            

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {formik => 
                                    <>
                                        <Form>
                                            <FloatLabel 
                                                label="Username" 
                                                type="text"
                                                name="Username"
                                                placeholder="Username"
                                                autoComplete="Username"
                                                disabled = {formik.isSubmitting}
                                            />
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <Button variant='link' className='ps-0' disabled={formik.isSubmitting} onClick={showLogin}>Login</Button>
                                                    <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                                                        {formik.isSubmitting ? 
                                                        <>
                                                        <DotLoader ButtonStyle={true} />
                                                      </> : <>Submit</>}
                                                    </Button>
                                            </div>
                                        </Form>
                                    </>
                            }
                        </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
    )
}

export default RecoverPassword;