import { Button, Col, Row } from "react-bootstrap";
import InlineRadioGroup from "../../../formik/inlineradiogroup";
import { Formik,Form } from "formik";
import FloatLabel from "../../../formik/floating-label";
import FormikCheckbox from "../../../formik/formikcheckbox";

const Map351VForm = () => {
    const initialValues = {
        dressing: null,
        grooming: null,
        bedmobility: null,
        bathing: null,
        toileting: null,
        eating: null,
        ambulation: null,
        transferring: null,
        dressingcomment: '',
        groomingcomment: '',
        bedmobilitycomment: '',
        bathingcomment: '',
        toiletingcomment: '',
        eatingcomment: '',
        ambulationcomment: '',
        transferringcomment: ''
    }

    const validation = {

    }

    const yesNo = [
        {
            title: 'Yes',
            value: 1
        },
        {
            title: 'No',
            value: 0
        }
    ]

    const ancillaryDressing = [
        {
            title: 'Requires supervision or verbal cues',
            name: 'dressingsupervision'
        },
        {
            title: 'Requires hands-on assistance with upper body',
            name: 'dressinghandsonupper'
        },
        {
            title: 'Requires hands-on assistance with lower body',
            name: 'dressinghandsonlower'
        },
        {
            title: 'Requires total assistance',
            name: 'dressingtotal'
        }
    ]

    const ancillaryGrooming = [
        {
            title: 'Requires supervision or verbal cues',
            name: 'groomingsupervision'
        },
        {
            title: 'Requires hands-on assistance with oral care',
            name: 'groominghandsonoral'
        },
        {
            title: 'Requires hands-on assistance with nail care',
            name: 'groominghandsonnail'
        },
        {
            title: 'Requires hands-on assistance with shaving',
            name: 'groominghandsonshaving'
        },
        {
            title: 'Requires hands-on assistance with hair',
            name: 'groominghandsonhair'
        },
        {
            title: 'Requires total assistance',
            name: 'groomingtotal'
        }
    ]

    const ancillaryBed = [
        {
            title: 'Requires supervision or verbal cues',
            name: 'bedsupervision'
        },
        {
            title: 'Ocassionally requires hands-on assistance',
            name: 'bedhandsonoccasional'
        },
        {
            title: 'Always requires hands-on assistance',
            name: 'bedhandsonalways'
        },
        {
            title: 'Bed-bound',
            name: 'bedbound'
        },
        {
            title: 'Required bedrails',
            name: 'bedrails'
        }
    ]

    const ancillaryBathing = [
        {
            title: 'Requires supervision or verbal cues',
            name: 'bathingsupervision'
        },
        {
            title: 'Requires hands-on assistance with upper body',
            name: 'bathinghandsonupper'
        },
        {
            title: 'Requires hands-on assistance with lower body',
            name: 'bbathinghandsonlower'
        },
        {
            title: 'Requires Peri-Care',
            name: 'bathingpericare'
        },
        {
            title: 'Requires total assistance',
            name: 'bathingtotal'
        }
    ]

    const ancillaryToileting = [
        {
            title: 'Bladder incontinence',
            name: 'toiletingbladder'
        },
        {
            title: 'Bowel incontinence',
            name: 'toiletingbowel'
        },
        {
            title: 'Occasionally requires hands-on assistance',
            name: 'toiletinghandsonoccasional'
        },
        {
            title: 'Always requires hands-on assistance',
            name: 'toiletinghandsonalways'
        },
        {
            title: 'Requires total assistance',
            name: 'toiletingtotalassistance'
        },
        {
            title: 'Bowel and bladder regimen',
            name: 'toiletingbowelbladderregimen'
        }
    ]

    const ancillaryEating = [
        {
            title: 'Requires supervision or verbal cues',
            name: 'eatingsupervision'
        },
        {
            title: 'Requires assistance cutting meat or arranging food',
            name: 'eatingassistnacecutting'
        },
        {
            title: 'Partial/occasional help',
            name: 'eatingpartial'
        },
        {
            title: 'Totally fed (by mouth)',
            name: 'eatingtotallyfed'
        },
        {
            title: 'Tube feeding (type and tube location)',
            name: 'eatingtube'
        }
    ]

    const ancillaryAmbulation = [
        {
            title: 'Dependent on device',
            name: 'ambulationdevice'
        },
        {
            title: 'Requires aid of one person',
            name: 'ambulationoneperson'
        },
        {
            title: 'Requires aid of two people',
            name: 'ambulationtwoperson'
        },
        {
            title: 'History of falls (number of falls, and date of last fall)',
            name: 'ambulationfalls'
        },
    ]

    const ancillaryTransferring = [
        {
            title: 'Requires supervision or verbal cues',
            name: 'transferringsupervision'
        },
        {
            title: 'Hands-on assistance of one person',
            name: 'transferringoneperson'
        },
        {
            title: 'Hands-on assistance of two people',
            name: 'transferringtwopeople'
        },
        {
            title: 'Requires mechanical device',
            name: 'transferringdevice'
        },
        {
            title: 'Bedfast',
            name: 'transferringbedfast'
        }
    ]

    return(
        <div className="clientCard">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={() => alert("here")}
            >
                {
                    formik => (
                        <Form>
                            <div className="clientCardInner">
                            <h4 className="text-center">SECTION V - ACTIVITIES OF DAILY LIVING</h4>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="dressing"
                                            primaryLabel="1) Is member independent with dressing/undressing?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.dressing === 0 &&
                                            ancillaryDressing.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="dressingcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="grooming"
                                            primaryLabel="2) Is member independent with grooming?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.grooming === 0 &&
                                            ancillaryGrooming.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="groomingcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="bedmobility"
                                            primaryLabel="3) Is member independent with bed mobility?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.bedmobility === 0 &&
                                            ancillaryBed.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="bedmobilitycomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="bathing"
                                            primaryLabel="4) Is member independent with bathing?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.bathing === 0 &&
                                            ancillaryBathing.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="bathingcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="toileting"
                                            primaryLabel="5) Is member independent with toileting?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.toileting === 0 &&
                                            ancillaryToileting.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="toiletingcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="eating"
                                            primaryLabel="6) Is member independent with eating?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.eating === 0 &&
                                            ancillaryEating.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="eatingcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="ambulation"
                                            primaryLabel="7) Is member independent with ambulation?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.ambulation === 0 &&
                                            ancillaryAmbulation.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="ambulationcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="transferring"
                                            primaryLabel="8) Is member independent with transferring?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.transferring === 0 &&
                                            ancillaryTransferring.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="transferringcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Button type="submit">Submit</Button>
                        
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

export default Map351VForm;