import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../styles/buttons.module.css';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

const ScrollToTopButton = () => {
    const [isVisible,setisVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if(window.scrollY > 300){
                setisVisible(true);
            }
            else{
                setisVisible(false);
            }
        }

        window.addEventListener('scroll',toggleVisibility);

        return () => {
            window.removeEventListener('scroll',toggleVisibility);
        }
    },[])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return(
        <button type='button' className={`semanticBtnOverride ${styles.scrollToTopButton} ${!isVisible ? styles.hide : ''}`} onClick={() => scrollToTop()} aria-label='Scroll To Top'>
            <div className={styles.icon}>
                <FontAwesomeIcon icon={faArrowUp} size="lg"/>
            </div>
        </button>
    )
}

export default ScrollToTopButton;