import { useState } from 'react';
import {Container } from 'react-bootstrap';
import Login from '../auth/login';
import RecoverPassword from '../auth/recoverpassword';

function AuthLayout(){
    const [recoverPassword, showRecoverPassword] = useState(false);

    return(
        <Container fluid className='d-flex LoginForm'>
            {recoverPassword ? <RecoverPassword showLogin={() => showRecoverPassword(false)} /> : <Login showRecoverPassword={() => showRecoverPassword(true)}/>}
        </Container>
    )
}
export default AuthLayout;

