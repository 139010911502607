import { Formik } from "formik";
import useScrollSpy from "../../../hooks/useScrollSpy";
import ScrollSection from "../../../components/scrollsection";
import { Col, Form, Row, Table } from "react-bootstrap";
import InputField from "../../../formik/input-field";
import TabularInlineRadioGroup from "../../../formik/tabularinlineradigroup";
import FloatLabel from "../../../formik/floating-label";

const ScreeningForm = () => {
    const {sectionRefs,activeSection} = useScrollSpy(1);
    const initialValues = {

    }

    const ambulationData = [
        {
            title: 'Independent',
            value: 1
        },
        {
            title: 'Requires assistance via use of a device; or requires assistance of 1 person',
            value: 2
        },
        {
            title: 'Requires assistance via use of a device and 1 or more person(s); or requires assistance of 2 or more persons',
            value: 3
        }
    ]

    const trasnferHygeineData = [
        {
            title: 'Independent',
            value: 1
        },
        {
            title: 'Requires assistance of 1 person',
            value: 2
        },
        {
            title: 'Requires assistance of 2 or more persons',
            value: 3
        }
    ]

    const toiletingData = [
        {
            title: 'Independent or Occasionally incontient but requires no assistance',
            value: 1
        },
        {
            title: 'Requires assistance of 1 person',
            value: 2
        },
        {
            title: 'Requires assistance of 2 or more persons',
            value: 3
        }
    ]

    const cognitionData = [
        {
            title: 'No problems',
            value: 1
        },
        {
            title: 'Decisions poor (e.g., cues, supervision required, behavior indicators present but easily altered',
            value: 2
        },
        {
            title: 'Severely impaired (e.g., never or rarely makes decisions/behavior indicators present and not easily altered)',
            value: 3
        }
    ]

    const medicalNursingData = [
        {
            title: 'Stable with routine medical/nursing monitoring and care',
            value: 1
        },
        {
            title: 'Requires skilled treatment or frequent monitoring in addition to routine medication administration, (e.g., sterile dressings,new colostomy, etc. )',
            value: 2
        },
        {
            title: 'Requires intense professional intervention to provide skilled treatment (e.g., tracheotomy, IV access, NG/G Tube care, extensive decub uclers, etc.',
            value: 3
        }
    ]

    const communication = [
        {
            title: 'No problem, able to understand and be understood. Able to make needs known. May require communication tools, board',
            value: 1
        },
        {
            title: 'Usually understood. May have difficulty (e.g., finding words), responds best to simple, direct communication',
            value: 2
        },
        {
            title: 'Rarely/Never able to understand or to make self understood. Unable to make needs known.',
            value: 3
        }
    ]



    return (
        <div className="clientCard">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={null}
                onSubmit={() => console.log("here")}
            >
                {
                    formik => (
                        <Form>
<div className="clientCardInner">
                    <h4 className="text-center">Screening</h4>
                    <div className="mt-4">
                        <ScrollSection ref={sectionRefs.current[0]} title="Screening Form">
                            <Row>
                                <Col xs={12} md={4}>
                                    <InputField
                                        type="date"
                                        name="completeddate"
                                        label="Screening Completed Date"
                                        max={new Date().toISOString().split('T')[0]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <p className="mb-0">Screening System Date</p>
                                    <p>12/1/2013 11:33:02 PM</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <p className="mb-0">RTT During Screening Completion Date</p>
                                    <p>12/1/2013 11:33:02 PM</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <strong>
                                        A score of level 3 on any area requires discussion on viability of transition with STT staff. Gather all necessary documentation for support of transition.
                                    </strong>
                                    <div className="mt-2">
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Level 1</strong></td>
                                                    <td><strong>Level 2</strong></td>
                                                    <td><strong>Level 3</strong></td>
                                                </tr>
                                            </thead>
                                           <tbody>
                                            <TabularInlineRadioGroup 
                                                name="ambulation"
                                                data={ambulationData}
                                                primaryLabel="Ambulation"
                                            />
                                            <TabularInlineRadioGroup 
                                                name="transfer"
                                                data={trasnferHygeineData}
                                                primaryLabel="TRANSFER"
                                            />
                                            <TabularInlineRadioGroup 
                                                name="toileting"
                                                data={toiletingData}
                                                primaryLabel="TOILETING"
                                            />
                                            <TabularInlineRadioGroup 
                                                name="personalhygiene"
                                                data={trasnferHygeineData}
                                                primaryLabel="PERSONAL HYGIENE"
                                            />
                                            <TabularInlineRadioGroup 
                                                name="cognition"
                                                data={cognitionData}
                                                primaryLabel="COGNITION BEHAVIOR"
                                            />
                                            <TabularInlineRadioGroup 
                                                name="medtreatment"
                                                data={medicalNursingData}
                                                primaryLabel="MEDICAL/NURSING TREATMENT"
                                            />
                                            <TabularInlineRadioGroup 
                                                name="communication"
                                                data={communication}
                                                primaryLabel="COMMUNICATION"
                                            />
                                            <tr>
                                                <td><strong>Score</strong></td>
                                                <td>
                                                    Level 1: <input type="text" />
                                                </td>
                                                <td>
                                                    Level 2: <input type="text" />
                                                </td>
                                                <td>
                                                    Level 3: <input type="text" />
                                                </td>
                                            </tr>
                                           </tbody>
                                        </Table>
                                        <div>
                                            <strong>Acuity Determination Level : </strong>
                                            <span>12</span>
                                        </div>
                                        <br />
                                        <FloatLabel
                                        type="text"
                                        as="textarea"
                                        name="comments"
                                        label="Comments"
                                        placeholder="Email"
                                        autoComplete="offz"
                                        style={{height: '100px'}}
                                    />
                                    </div>
                                </Col>
                            </Row>
                        </ScrollSection>
                    </div>
                </div>
                        </Form>
                    )
                }
                
            </Formik>
        </div>
    )
}

export default ScreeningForm;