import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useState, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useNavigate,useLocation } from "react-router-dom";
import useApi from '../hooks/use-api';
import FloatLabel from '../formik/floating-label';
import '../App.css';
import LegalWarning from '../components/legalwarning';
import InsetAlert from '../components/insetalert';
import DotLoader from '../components/dotloader';

function SetPassword(){

    useEffect(() => {
        document.title = "MFP - Set Password";
    },[]);


    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const PasswordToken = query.get("setpasstoken");

    const {processing, error, postRequest} = useApi();
    const [showRecPassFailure, setRecPassFailure] = useState(false);

    const initialValues = {
        Password: "",
        ConfirmPassword : ""
    };

    const validationSchema = Yup.object({
        Password: Yup
            .string()
            .required("Required")
            .min(15,'Password must be at least 15 characters.')
            .matches(/(?=.*[^a-zA-Z0-9\s-"]).*$/,'Password must contain at least one special character that is not a hyphen or quotation mark.')
            .matches(/[A-Z]/,'Password must contain at least one uppercase letter.')
            .matches(/[a-z]/,'Password must contain at least one lowercase letter.')
            .matches(/[0-9]/,'Password must contain at least one number'),
        ConfirmPassword: Yup
            .string()
            .required("Required")
            .oneOf([Yup.ref('Password'),null],'Passwords must match')
    });

    const handleSubmit = async (values) => {
        const dataToSend = {...values,PasswordToken};
        const [data, status] = await postRequest("setpassword", dataToSend);
        if (status !== 200) {
            setRecPassFailure(true);
        } else {
            navigate("/")
        }
    };


    return (
        <Container fluid className='d-flex LoginForm'>
            <Row>
                <Col xs={12}>
                    <LegalWarning />
                    <Card>
                        <Card.Img variant="top" src="/mfpbanner.png" alt="Money Follows The Person Banner"/>
                        <>
                        {
                            !PasswordToken ? 
                            <Card.Body>
                                <InsetAlert type="Error" heading="Error!" body="A problem occured while loading the page. Please contact your system administrator." dismissible={false} style={{width: '100%'}}/>
                            </Card.Body>
                            :
                            <Card.Body>
                            <h3>Set Password</h3>
                            
                                <>
                                {
                                showRecPassFailure && error ?
                                    <InsetAlert error={error}  />
                                :   
                                    null
                                }
                                </>
                            
                            

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {formik => 
                                    <>
                                        <Form>
                                            <FloatLabel 
                                                label="New Password" 
                                                type="password"
                                                name="Password"
                                                placeholder="Password"
                                                disabled = {formik.isSubmitting}
                                            />
                                            <FloatLabel
                                                label="Confirm Password"
                                                type="password"
                                                name="ConfirmPassword"
                                                placeholder="Confirm Password"
                                                disabled = {formik.isSubmitting}
                                            />
                                            <div className='d-flex justify-content-end'>
                                                    <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                                                        {formik.isSubmitting ? 
                                                        <>
                                                        <DotLoader ButtonStyle={true} />
                                                      </> : <>Submit</>}
                                                    </Button>
                                            </div>
                                        </Form>
                                    </>
                            }
                        </Formik>
                        </Card.Body>
                            
                        }
                        </>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
        
    )
}

export default SetPassword;