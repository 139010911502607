import styles from '../styles/Loader.module.css';

const GapButtonSkeleton = () => {
    return(
        <>
            <div className={styles.skeletonGapButton}></div>
            <div className={styles.skeletonGapButton}></div>
            <div className={styles.skeletonGapButton}></div>
            <div className={styles.skeletonGapButton}></div>
            <div className={styles.skeletonGapActionButton}></div>
        </>
    )
}

export default GapButtonSkeleton;