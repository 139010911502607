const endpointMap = new Map([

    // AUTH
    ["login", "auth/login"],
    ["recoverpass","auth/recoverpassword"],
    ["setpassword","auth/setpassword"],
    ["refreshtoken","auth/refreshtoken"],
    ["tester","user/gettest"],
    ["getuser","user/getuser"],
    ["adduser","user/adduser"],
    ["edituser","user/edituser"],
    ["getroles","user/getroles"],
    ["getrole","user/getrole"],
    ["addrole","user/addrole"],
    ["editrole","user/editrole"],
    // ["clientsearch","Users/clientsearch"],
    ["clientsearch","user/clientsearch"],
    ["tree","user/tree"],
    ["events","user/events"],
    ["rec","user/rec",],
    ["emaildetails","user/emaildetails"],
    ["emailrec","user/emailrecipients"],
    ["email","user/GetEmail",],
    ["editemail","user/editemail"],
    ["addemail","user/addemail"],
    ["emailvars","user/emailvars"],
    ["Users","Users"],
    ["Roles","Roles"]
    
]); 

export default endpointMap;
