const Footer = () => {
    return(
        <footer>
            <div className="footer">
                Application Developed By: University of Kentucky's <a href="https://ibi.uky.edu" target="_blank" rel="noreferrer">Institute for Biomedical Informatics (IBI)</a>
                <br />
                With Funding Provided By: Commonwealth of Kentucky through the Federal Grant "Money Follows the Person (MFP)"
            </div>
        </footer>
        
    )

}

export default Footer;