import { create } from "zustand";

const initialState ={
    baseUrl: ""
} ;

const useApiUrlStore = create((set, get) => ({
    ...initialState,
    setBaseUrl: (value) => set({baseUrl: value}),
    reset: () => set(initialState),
  
}));

export default useApiUrlStore;
