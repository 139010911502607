import React from "react"

const ButtonOverride = ({className,children,...props}) => {
    return(
        <button className={`semanticBtnOverride ${className ? className : ''}`} {...props}>
            {children}
        </button>
    )
}


export default ButtonOverride;