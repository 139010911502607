import { Dropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';


function MainNavbar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <LinkContainer to="/">
            <Navbar.Brand>MFP</Navbar.Brand>
          </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Client/ClientSearch">
              <Nav.Link>Client Search</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Reports/ViewReports">
              <Nav.Link>Reports</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Admin">
              <LinkContainer to="/Admin/ManageUsers">
                <NavDropdown.Item>Manage Users</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/Admin/ManageRoles">
                <NavDropdown.Item>Manage Roles</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/Admin/ImpersonateUser">
                <NavDropdown.Item>Impersonate User</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/Admin/ManageGroupApp">
                <NavDropdown.Item>Edit/Update Group App Status</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/Admin/ManageEmails">
                <NavDropdown.Item>Edit/Update Emails</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}

export default MainNavbar;