import { useState,useRef, useEffect } from "react";
import BaseModal from "./basemodal";
import useApi from "../hooks/use-api";
import { Tabs,Tab,Col,Popover,Overlay} from "react-bootstrap";
import { Formik, useField} from 'formik';
import * as Yup from 'yup';
import FloatLabel from "../formik/floating-label";
import useMessageHub from "../hooks/use-messagehub";
import ActionResultText from "../utilities/ActionResultText";
import SelectComponent from "../components/selectcomponent";
import { useModalStatusContext } from "../contexts/modalstatuscontext";
import TreeComponent from "../components/treecomponent";
import emailStyles from '../styles/emaillistitem.module.css';
import SelectionComponentTest from "../components/selectnew/selectcomponenttest";

const RoleModal = ({modalShown,hideModal,targetID,roleChangeCallback}) => {

    const {processing,error,getRequest,postRequest,putRequest} = useApi();
    const childSubmitHandlerRef = useRef(null);
    const [modalLocked,setModalLocked] = useState(false);
    const {registerActivity} = useMessageHub();
    const {isLoading,HasError,handleError} = useModalStatusContext();

    const initialValues = {
        name: '',
        description: '',
        createdon: '',
        usercount: 0,
        permcount: 0,
        selectedIds: [],
        selectedpermids: []

    }; 

    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        description: Yup.string().required("Required")
    });


    const[role,setRole] = useState(initialValues);
    
    const getRole = async () => {
        const [data,status] = await getRequest("getrole",{id: targetID});
        return [data,status];
    };

    const submitRoleRequest = async (values) => {
        const [data, status] =  targetID ? await putRequest("edituser", {...values,id: targetID}) : await postRequest("adduser",{...values,id: 0});
        return [data,status];
    };

    const handleSubmitFunction = async (values) => {
        if(childSubmitHandlerRef.current){
            await childSubmitHandlerRef.current(values);
        }
    }

    const timeoutRef = useRef(null);
    const [showPopover, setShowPopover] = useState(false);
    const [popovertext,setPopoverText] = useState({heading: '',body : ''});
    const [target, setTarget] = useState(null);

    const popoverHoverEvent = (e,heading,body) =>{
        timeoutRef.current = setTimeout(() => {
            setPopoverText({heading: heading,body: body});
            setShowPopover(true);
            setTarget(e.target);
        }, 1000);
    }

    const popoverLeaveEvent = () => {
        clearTimeout(timeoutRef.current);
        setShowPopover(false);
    }

    const ActionCompleteCallback = (ActionResult) => {
        registerActivity(
            {
                body: ActionResultText({...ActionResult,objectTarget: 'Role'}),
                errorFlag: ActionResult.error
            },
            ActionResult.sessionExpired,
            true
        )
        roleChangeCallback();
    }

    const _extractDataForUsers = (values) => {
        const dataSet = [
            {
                id: 'users',
                items: values.users?.map((item) => ({
                    id: item.id,
                    name: item.name
                }))
            }
        ]

        return dataSet;
    }

    return(
        <>
        <Overlay
            show={showPopover}
            target={target}
            placement="right"
            containerPadding={20}
            >
            <Popover id="popover-contained">
                <Popover.Header as="h3">{popovertext.heading}</Popover.Header>
                <Popover.Body>
                    {popovertext.body}
                </Popover.Body>
            </Popover>
        </Overlay>
    <Formik
            initialValues={role}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmitFunction}
        >
            {
                formik => 
                    <BaseModal
                        modalShown={modalShown}
                        closeModal={() => hideModal()}
                        ModalTitle={targetID ? <span>Edit Role [{role.name}]</span> : <span>Add Role</span>}
                        loadData={getRole}
                        submitData={submitRoleRequest}
                        objectID={targetID}
                        objectType="Role"
                        setData={setRole}
                        error={error}
                        processing={processing}
                        initialValues={initialValues}
                        size="lg"
                        setSubmitHandler={handler => childSubmitHandlerRef.current = handler}
                        formikProps={formik}
                        modalLocked={modalLocked}
                        popoverHover={popoverHoverEvent}
                        popoverLeave={popoverLeaveEvent}
                        setModalLocked={setModalLocked}
                        actionCompleteCallback={ActionCompleteCallback}
                    >
                         <div>
                            <Tabs
                                defaultActiveKey="roleinfo"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                                >
                                <Tab eventKey="roleinfo" title="Role Information" disabled={formik.isSubmitting || processing}>
                                    <div className="box box--roleinformation">
                                        <Col xs={12}>
                                            <FloatLabel
                                                type="text"
                                                name="name"
                                                label="Role Name"
                                                placeholder="Role Name"
                                                disabled={formik.isSubmitting || processing || HasError}
                                                autoComplete="offz"
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <FloatLabel
                                                as="textarea"
                                                type="text"
                                                name="description"
                                                label="Description"
                                                placeholder="Description"
                                                disabled={formik.isSubmitting || processing || HasError}
                                                autoComplete="offz"
                                                style={{height: '100px'}}
                                            />
                                        </Col>
                                    </div>
                                </Tab>
                                <Tab eventKey="users" title="Users" disabled={formik.isSubmitting || processing} >
                                    <div className="box box--userroles">
                                        {/* <SelectComponent
                                                    objectName="User"
                                                    name="selectedIds"
                                                    retrieveDataEndpoint="tester"
                                                    extractDataFunction={(data) => data.users.map(user => ({id: user.id, title: user.name}))}
                                                    searchboxid={"searchUsers"}
                                                    disabled={HasError}
                                        /> */}
                                        <SelectWrapper
                                            objectName="User"
                                            name="selectedIds"
                                            retrieveDataEndpoint="tester"
                                            extractDataFunction={(data) => _extractDataForUsers(data)}
                                            searchboxid={"searchUsers"}
                                            disabled={HasError}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="permissions" title="Permissions" disabled={formik.isSubmitting || processing}>
                                    <div className="box box--permissions">
                                        <TreeComponent
                                            name="selectedpermids" 
                                            objectName="Permission" 
                                            searchboxid="tester"  
                                            retrieveDataEndpoint="tree"
                                            extractDataFunction={(data) => data.map(permission => ({id: permission.id,name: permission.name, path: permission.path}))}
                                            disabled={HasError}
                                        />
                                    </div>
                                </Tab>
                        </Tabs>
                        </div>
                    </BaseModal> 
            }
        
        </Formik>
    </>
    )
}

const SelectWrapper = ({name,objectName,retrieveDataEndpoint,extractDataFunction,searchboxid,disabled}) => {
    const [field,meta,helpers] = useField(name);
    const[fieldExtracted,setFieldExtracted] = useState({items: new Set(),actions: new Map()});

    const _translateData = (fieldValues) => {
        const dataSet = new Set(fieldValues.map(id => `users:${id}`));
        
        let newSelections = {
            items: dataSet, 
            actions: new Map(),
        }

        return newSelections;
    }

    const _translateMapping = (data) => {
        let result = [];

        data.items.forEach(key => {
            const id = parseInt(key.split(':')[1]);

            result.push(id);
        })

        return result.sort((a,b) => a - b);
    }

    return(
        <SelectionComponentTest 
            objectName={objectName}
            retrieveDataEndpoint={retrieveDataEndpoint}
            searchboxid={searchboxid}
            disabled={disabled}
            dataExtractFunction={extractDataFunction}
            field={_translateData(field.value)}
            fieldSetter={(data) => helpers.setValue(_translateMapping(data))}
        />
    )
}

export default RoleModal;