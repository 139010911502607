import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faListCheck, faPenToSquare,faUser, faUserGear, faUserTie, faUsers } from "@fortawesome/free-solid-svg-icons";
import InlineRadioGroup from "../../../formik/inlineradiogroup";
import InformationCard from "../../../components/informationcard";
import Masonry from 'react-masonry-css';

const FaceSheet = () => {
    const breakpointColumnsObj = {
        default: 3,
        576: 1,
        768: 2
      };
    return(
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <InformationCard>
                    <InformationCard.Icon>
                        <FontAwesomeIcon icon={faUser} size="xl" />
                    </InformationCard.Icon>
                    <InformationCard.Header>
                        <h6>Membership Information</h6>
                    </InformationCard.Header>
                    <InformationCard.Body>
                    <ul className="custom-list">
                        <li>
                            <h5 className="mb-0">Name</h5>
                            <p className="mb-0">Bradley Tyler</p>
                        </li>
                        <li>
                            <h5 className="mb-0">MAID</h5>
                            <p className="mb-0">0019221177</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Web APP ID</h5>
                            <p className="mb-0">123456</p>
                        </li>
                        <li>
                            <h5 className="mb-0">DOB</h5>
                            <p className="mb-0">08/08/1988</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Demographic</h5>
                            <p className="mb-0">Placeholder</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Referral Date</h5>
                            <p className="mb-0">12/12/2023</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Transition Date</h5>
                            <p className="mb-0">12/13/2023</p>
                        </li>
                        <li>
                            <h5 className="mb-0">MFP Transition Coordinator</h5>
                            <p className="mb-0">John Doe</p>
                            <p className="mb-0">(859)595-5959</p>
                        </li>
                        <li>
                            <h5 className="mb-0">MFP Case Coordinator</h5>
                            <p className="mb-0">John Doe</p>
                            <p className="mb-0">(859)111-1212</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Medicare</h5>
                            <Form.Check
                                type="checkbox"
                                label="Medicare?"
                                className="mb-1"
                            />
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Medicare #"
                                className="mb-1"
                            >
                                <Form.Control type="text" placeholder="name@example.com" />
                            </FloatingLabel>
                            <Form.Label className='mb-0'>MEUPS Checked Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="referraldate"
                                max={new Date().toISOString().split('T')[0]}
                            />
                        </li>
                    </ul>
                    </InformationCard.Body>
                </InformationCard>
                <InformationCard>
                    <InformationCard.Icon>
                        <FontAwesomeIcon icon={faUserGear} size="xl" />
                    </InformationCard.Icon>
                    <InformationCard.Header>
                        <h6>Consumer Directed Option</h6>
                    </InformationCard.Header>
                    <InformationCard.Body>
                    <ul className="custom-list">
                        <li>
                            <h5 className="mb-0">Does Member Have CDO?</h5>
                            <p className="mb-0">No</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Start Date</h5>
                            <p className="mb-0">12/13/2023</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Name</h5>
                            <p className="mb-0">John Doe</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Address</h5>
                            <p className="mb-0">123 xyz street, Winchester, KY 40391</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Relation</h5>
                            <p className="mb-0">Brother</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Phone</h5>
                            <p>(859)222-2222</p>
                        </li>
                    </ul>
                    </InformationCard.Body>
                </InformationCard>
                <InformationCard>
                    <InformationCard.Icon>
                        <FontAwesomeIcon icon={faBuilding} size="xl" />
                    </InformationCard.Icon>
                    <InformationCard.Header>
                        <h6>Referral Form - Facility Information</h6>
                    </InformationCard.Header>
                    <InformationCard.Body>
                    <ul className="custom-list">
                        <li>
                            <h5 className="mb-0">Does Member Have CDO?</h5>
                            <p className="mb-0">No</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Start Date</h5>
                            <p className="mb-0">12/13/2023</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Name</h5>
                            <p className="mb-0">John Doe</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Address</h5>
                            <p className="mb-0">123 xyz street, Winchester, KY 40391</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Relation</h5>
                            <p className="mb-0">Brother</p>
                        </li>
                        <li>
                            <h5 className="mb-0">Rep Phone</h5>
                            <p>(859)222-2222</p>
                        </li>
                    </ul>
                    </InformationCard.Body>
                </InformationCard>
                <InformationCard>
                    <InformationCard.Icon>
                        <FontAwesomeIcon icon={faListCheck} size="xl" />
                    </InformationCard.Icon>
                    <InformationCard.Header>
                        <h6>Transition Checklist - Transition Location Information</h6>
                    </InformationCard.Header>
                    <InformationCard.Body>
                        <strong className="text-center">Location Information</strong>
                        <ul className="custom-list">
                            <li>
                                <h5 className="mb-0">Address</h5>
                                <p className="mb-0">123 xyz street, Winchester KY, 40391</p>
                            </li>
                            <li>
                                <h5 className="mb-0">Phone</h5>
                                <p className="mb-0">(859) 323-3232</p>
                            </li>
                            <li>
                                <h5 className="mb-0">Type</h5>
                                <p className="mb-0">Placeholder</p>
                            </li>
                        </ul>
                        <strong>Residential Provider Information</strong>
                        <ul className="custom-list">
                            <li>
                                <h5 className="mb-0">Provider Name</h5>
                                <p className="mb-0">John Doe</p>
                            </li>
                            <li>
                                <h5 className="mb-0">Phone</h5>
                                <p className="mb-0">(859) 323-3232</p>
                            </li>
                            <li>
                                <h5 className="mb-0">Provider #</h5>
                                <p className="mb-0">Placeholder</p>
                            </li>
                            <li>
                                <h5 className="mb-0">Address</h5>
                                <p className="mb-0">123 xyz street, Winchester KY, 40391</p>
                            </li>
                            <li>
                                <h5 className="mb-0">Contact Name</h5>
                                <p className="mb-0">John Doe</p>
                            </li>
                            <li>
                                <h5 className="mb-0">Phone</h5>
                                <p className="mb-0">(859) 323-3232</p>
                            </li>
                        </ul>
                    </InformationCard.Body>
                </InformationCard>
                <InformationCard>
                    <InformationCard.Icon>
                        <FontAwesomeIcon icon={faUserTie} size="xl" />
                    </InformationCard.Icon>
                    <InformationCard.Header>
                        <h6>Independent Case Manager</h6>
                    </InformationCard.Header>
                    <InformationCard.Body>
                        <strong>Case Manager Information</strong>
                        <ul className="custom-list">
                            <li>
                                <h5 className="mb-0">Name</h5>
                                <FloatingLabel
                                controlId="icmname"
                                label="Name"
                                className="mb-1"
                                >
                                <Form.Control type="text" placeholder="fds"/>
                                </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Phone</h5>
                                <FloatingLabel
                                controlId="icmphone"
                                label="Phone"
                                className="mb-1"
                            >
                                <Form.Control type="tel" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Provider #</h5>
                                <FloatingLabel
                                controlId="icmprovnum"
                                label="Provider #"
                                className="mb-1"
                            >
                                <Form.Control type="text" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Address</h5>
                                <FloatingLabel
                                controlId="icmaddr"
                                label="Address"
                                className="mb-1"
                            >
                                <Form.Control type="text" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">City</h5>
                                <FloatingLabel
                                controlId="icmaddrcity"
                                label="City"
                                className="mb-1"
                            >
                                <Form.Control type="text" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">State</h5>
                                <FloatingLabel controlId="floatingSelect" label="State" className="mb-1">
                                <Form.Select aria-label="Floating label select example">
                                    <option>Kentucky</option>
                                </Form.Select>
                            </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Zip</h5>
                                <FloatingLabel
                                controlId="icmaddrzip"
                                label="Zip"
                                className="mb-1"
                            >
                                <Form.Control type="text" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                        </ul>
                        <strong>Contact</strong>
                        <ul className="custom-list">
                            <li>
                                <h5 className="mb-0">First Name</h5>
                                <FloatingLabel
                                controlId="icmconfirst"
                                label="First Name"
                                className="mb-1"
                            >
                                <Form.Control type="text" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Last Name</h5>
                                <FloatingLabel
                                controlId="icmconlast"
                                label="Last Name"
                                className="mb-1"
                            >
                                <Form.Control type="text" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Phone</h5>
                                <FloatingLabel
                                controlId="icmconphone"
                                label="Phone"
                                className="mb-1"
                            >
                                <Form.Control type="tel" placeholder="fds"/>
                            </FloatingLabel>
                            </li>
                        </ul>
                    </InformationCard.Body>
                </InformationCard>
                <InformationCard>
                    <InformationCard.Icon>
                        <FontAwesomeIcon icon={faUsers} size="xl" />
                    </InformationCard.Icon>
                    <InformationCard.Header>
                        <h6>Adult Day Program</h6>
                    </InformationCard.Header>
                    <InformationCard.Body>
                        <strong>Program Information</strong>
                        <ul className="custom-list">
                            <li>
                                <h5 className="mb-0">Name</h5>
                                <FloatingLabel controlId="adname" label="Name" className="mb-1">
                                    <Form.Control type="text" placeholder="fds"/>
                                </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Phone</h5>
                                <FloatingLabel controlId="adphone" label="Phone" className="mb-1">
                                    <Form.Control type="tel" placeholder="fds"/>
                                </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Address</h5>
                                <FloatingLabel controlId="adaddr" label="Address" className="mb-1">
                                    <Form.Control type="text" placeholder="fds"/>
                                </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">City</h5>
                                <FloatingLabel controlId="adcity" label="City" className="mb-1">
                                    <Form.Control type="text" placeholder="fds"/>
                                </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">State</h5>
                                <FloatingLabel controlId="floatingSelecttwo" label="State" className="mb-1">
                                    <Form.Select aria-label="Floating label select example">
                                        <option>Kentucky</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </li>
                            <li>
                                <h5 className="mb-0">Zip</h5>
                                <FloatingLabel controlId="adzip" label="Zip" className="mb-1">
                                    <Form.Control type="text" placeholder="fds"/>
                                </FloatingLabel>
                            </li>
                        </ul>
                    </InformationCard.Body>
                </InformationCard>
                <div></div>
                <div></div>
                <InformationCard>
                    <InformationCard.Icon>
                        <FontAwesomeIcon icon={faPenToSquare} size="xl" />
                    </InformationCard.Icon>
                    <InformationCard.Header>
                        <h6>Transition Plan</h6>
                    </InformationCard.Header>
                    <InformationCard.Body>
                        <strong>Therapies</strong>
                        <p>--CLIENT HAS NO THERAPIES ENTERED--</p>
                        <strong>Services</strong>
                        <p>--CLIENT HAS NO SERVICES ENTERED--</p>
                    </InformationCard.Body>
                </InformationCard>
          </Masonry>
        // <Row>
        //     <Col xs={12} lg={4} md={6} className="mb-4 d-flex align-items-stretch">
        //         <InformationCard>
        //             <InformationCard.Icon>
        //                 <FontAwesomeIcon icon={faUser} size="xl" />
        //             </InformationCard.Icon>
        //             <InformationCard.Header>
        //                 <h6>Membership Information</h6>
        //             </InformationCard.Header>
        //             <InformationCard.Body>
        //             <ul className="custom-list">
        //                 <li>
        //                     <h5 className="mb-0">Name</h5>
        //                     <p className="mb-0">Bradley Tyler</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">MAID</h5>
        //                     <p className="mb-0">0019221177</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Web APP ID</h5>
        //                     <p className="mb-0">123456</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">DOB</h5>
        //                     <p className="mb-0">08/08/1988</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Demographic</h5>
        //                     <p className="mb-0">Placeholder</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Referral Date</h5>
        //                     <p className="mb-0">12/12/2023</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Transition Date</h5>
        //                     <p className="mb-0">12/13/2023</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">MFP Transition Coordinator</h5>
        //                     <p className="mb-0">John Doe</p>
        //                     <p className="mb-0">(859)595-5959</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">MFP Case Coordinator</h5>
        //                     <p className="mb-0">John Doe</p>
        //                     <p className="mb-0">(859)111-1212</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Medicare</h5>
        //                     <Form.Check
        //                         type="checkbox"
        //                         label="Medicare?"
        //                         className="mb-1"
        //                     />
        //                     <FloatingLabel
        //                         controlId="floatingInput"
        //                         label="Medicare #"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="text" placeholder="name@example.com" />
        //                     </FloatingLabel>
        //                     <Form.Label className='mb-0'>MEUPS Checked Date</Form.Label>
        //                     <Form.Control
        //                         type="date"
        //                         name="referraldate"
        //                         max={new Date().toISOString().split('T')[0]}
        //                     />
        //                 </li>
        //             </ul>
        //             </InformationCard.Body>
        //         </InformationCard>
        //     </Col>
        //     <Col xs={12} lg={4} md={6} className="mb-4 d-flex align-items-stretch ">
        //         <InformationCard>
        //             <InformationCard.Icon>
        //                 <FontAwesomeIcon icon={faUserGear} size="xl" />
        //             </InformationCard.Icon>
        //             <InformationCard.Header>
        //                 <h6>Consumer Directed Option</h6>
        //             </InformationCard.Header>
        //             <InformationCard.Body>
        //             <ul className="custom-list">
        //                 <li>
        //                     <h5 className="mb-0">Does Member Have CDO?</h5>
        //                     <p className="mb-0">No</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Start Date</h5>
        //                     <p className="mb-0">12/13/2023</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Name</h5>
        //                     <p className="mb-0">John Doe</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Address</h5>
        //                     <p className="mb-0">123 xyz street, Winchester, KY 40391</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Relation</h5>
        //                     <p className="mb-0">Brother</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Phone</h5>
        //                     <p>(859)222-2222</p>
        //                 </li>
        //             </ul>
        //             </InformationCard.Body>
        //         </InformationCard>
        //     </Col>
        //     <Col xs={12} lg={4} md={6} className="mb-4 d-flex align-items-stretch">
        //         <InformationCard>
        //             <InformationCard.Icon>
        //                 <FontAwesomeIcon icon={faBuilding} size="xl" />
        //             </InformationCard.Icon>
        //             <InformationCard.Header>
        //                 <h6>Referral Form - Facility Information</h6>
        //             </InformationCard.Header>
        //             <InformationCard.Body>
        //             <ul className="custom-list">
        //                 <li>
        //                     <h5 className="mb-0">Does Member Have CDO?</h5>
        //                     <p className="mb-0">No</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Start Date</h5>
        //                     <p className="mb-0">12/13/2023</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Name</h5>
        //                     <p className="mb-0">John Doe</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Address</h5>
        //                     <p className="mb-0">123 xyz street, Winchester, KY 40391</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Relation</h5>
        //                     <p className="mb-0">Brother</p>
        //                 </li>
        //                 <li>
        //                     <h5 className="mb-0">Rep Phone</h5>
        //                     <p>(859)222-2222</p>
        //                 </li>
        //             </ul>
        //             </InformationCard.Body>
        //         </InformationCard>
        //     </Col>
        //     <Col xs={12} lg={4} md={6} className="mb-4 d-flex align-items-stretch">
        //         <InformationCard>
        //             <InformationCard.Icon>
        //                 <FontAwesomeIcon icon={faListCheck} size="xl" />
        //             </InformationCard.Icon>
        //             <InformationCard.Header>
        //                 <h6>Transition Checklist - Transition Location Information</h6>
        //             </InformationCard.Header>
        //             <InformationCard.Body>
        //                 <strong className="text-center">Location Information</strong>
        //                 <ul className="custom-list">
        //                     <li>
        //                         <h5 className="mb-0">Address</h5>
        //                         <p className="mb-0">123 xyz street, Winchester KY, 40391</p>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Phone</h5>
        //                         <p className="mb-0">(859) 323-3232</p>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Type</h5>
        //                         <p className="mb-0">Placeholder</p>
        //                     </li>
        //                 </ul>
        //                 <strong>Residential Provider Information</strong>
        //                 <ul className="custom-list">
        //                     <li>
        //                         <h5 className="mb-0">Provider Name</h5>
        //                         <p className="mb-0">John Doe</p>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Phone</h5>
        //                         <p className="mb-0">(859) 323-3232</p>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Provider #</h5>
        //                         <p className="mb-0">Placeholder</p>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Address</h5>
        //                         <p className="mb-0">123 xyz street, Winchester KY, 40391</p>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Contact Name</h5>
        //                         <p className="mb-0">John Doe</p>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Phone</h5>
        //                         <p className="mb-0">(859) 323-3232</p>
        //                     </li>
        //                 </ul>
        //             </InformationCard.Body>
        //         </InformationCard>
        //     </Col>
        //     <Col xs={12} lg={4} md={6} className="mb-4 d-flex align-items-stretch">
        //         <InformationCard>
        //             <InformationCard.Icon>
        //                 <FontAwesomeIcon icon={faUserTie} size="xl" />
        //             </InformationCard.Icon>
        //             <InformationCard.Header>
        //                 <h6>Independent Case Manager</h6>
        //             </InformationCard.Header>
        //             <InformationCard.Body>
        //                 <strong>Case Manager Information</strong>
        //                 <ul className="custom-list">
        //                     <li>
        //                         <h5 className="mb-0">Name</h5>
        //                         <FloatingLabel
        //                         controlId="icmname"
        //                         label="Name"
        //                         className="mb-1"
        //                         >
        //                         <Form.Control type="text" placeholder="fds"/>
        //                         </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Phone</h5>
        //                         <FloatingLabel
        //                         controlId="icmphone"
        //                         label="Phone"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="tel" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Provider #</h5>
        //                         <FloatingLabel
        //                         controlId="icmprovnum"
        //                         label="Provider #"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="text" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Address</h5>
        //                         <FloatingLabel
        //                         controlId="icmaddr"
        //                         label="Address"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="text" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">City</h5>
        //                         <FloatingLabel
        //                         controlId="icmaddrcity"
        //                         label="City"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="text" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">State</h5>
        //                         <FloatingLabel controlId="floatingSelect" label="State" className="mb-1">
        //                         <Form.Select aria-label="Floating label select example">
        //                             <option>Kentucky</option>
        //                         </Form.Select>
        //                     </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Zip</h5>
        //                         <FloatingLabel
        //                         controlId="icmaddrzip"
        //                         label="Zip"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="text" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                 </ul>
        //                 <strong>Contact</strong>
        //                 <ul className="custom-list">
        //                     <li>
        //                         <h5 className="mb-0">First Name</h5>
        //                         <FloatingLabel
        //                         controlId="icmconfirst"
        //                         label="Zip"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="text" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Last Name</h5>
        //                         <FloatingLabel
        //                         controlId="icmconlast"
        //                         label="Zip"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="text" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Phone</h5>
        //                         <FloatingLabel
        //                         controlId="icmconphone"
        //                         label="Zip"
        //                         className="mb-1"
        //                     >
        //                         <Form.Control type="tel" placeholder="fds"/>
        //                     </FloatingLabel>
        //                     </li>
        //                 </ul>
        //             </InformationCard.Body>
        //         </InformationCard>
        //     </Col>
        //     <Col xs={12} lg={4} md={6} className="mb-4 d-flex align-items-stretch">
        //         <InformationCard>
        //             <InformationCard.Icon>
        //                 <FontAwesomeIcon icon={faUsers} size="xl" />
        //             </InformationCard.Icon>
        //             <InformationCard.Header>
        //                 <h6>Adult Day Program</h6>
        //             </InformationCard.Header>
        //             <InformationCard.Body>
        //                 <strong>Program Information</strong>
        //                 <ul className="custom-list">
        //                     <li>
        //                         <h5 className="mb-0">Name</h5>
        //                         <FloatingLabel controlId="adname" label="Name" className="mb-1">
        //                             <Form.Control type="text" placeholder="fds"/>
        //                         </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Phone</h5>
        //                         <FloatingLabel controlId="adphone" label="Phone" className="mb-1">
        //                             <Form.Control type="tel" placeholder="fds"/>
        //                         </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Address</h5>
        //                         <FloatingLabel controlId="adaddr" label="Address" className="mb-1">
        //                             <Form.Control type="text" placeholder="fds"/>
        //                         </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">City</h5>
        //                         <FloatingLabel controlId="adcity" label="City" className="mb-1">
        //                             <Form.Control type="text" placeholder="fds"/>
        //                         </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">State</h5>
        //                         <FloatingLabel controlId="floatingSelecttwo" label="State" className="mb-1">
        //                             <Form.Select aria-label="Floating label select example">
        //                                 <option>Kentucky</option>
        //                             </Form.Select>
        //                         </FloatingLabel>
        //                     </li>
        //                     <li>
        //                         <h5 className="mb-0">Zip</h5>
        //                         <FloatingLabel controlId="adzip" label="Zip" className="mb-1">
        //                             <Form.Control type="text" placeholder="fds"/>
        //                         </FloatingLabel>
        //                     </li>
        //                 </ul>
        //             </InformationCard.Body>
        //         </InformationCard>
        //     </Col>
        //     <Col xs={12} lg={4} md={6} className="mb-4 d-flex align-items-stretch">
        //         <InformationCard>
        //             <InformationCard.Icon>
        //                 <FontAwesomeIcon icon={faPenToSquare} size="xl" />
        //             </InformationCard.Icon>
        //             <InformationCard.Header>
        //                 <h6>Transition Plan</h6>
        //             </InformationCard.Header>
        //             <InformationCard.Body>
        //                 <strong>Therapies</strong>
        //                 <p>--CLIENT HAS NO THERAPIES ENTERED--</p>
        //                 <strong>Services</strong>
        //                 <p>--CLIENT HAS NO SERVICES ENTERED--</p>
        //             </InformationCard.Body>
        //         </InformationCard>
        //     </Col>
        // </Row>
    )
}

export default FaceSheet;