import { Button, Col, Modal, Row } from "react-bootstrap";
import FloatLabel from "../../../formik/floating-label";
import BaseForm from "./baseform";
import InputField from "../../../formik/input-field";
import useScrollSpy from '../../../hooks/useScrollSpy';
import ScrollSection from '../../../components/scrollsection';
import ScrollProgress from "../../../components/scrollprogress";
import InlineRadio from "../../../formik/inlineradio";
import * as Yup from 'yup';
import { Formik, useFormik,Form, useField } from "formik";
import InlineRadioGroup from "../../../formik/inlineradiogroup";
import FloatingLabelSelect from "../../../formik/floating-label-select";
import SelectionComponentTest from "../../../components/selectnew/selectcomponenttest";
import { useState } from "react";

const ApplicationForm = () => {

    const initialValues = {
        targetpopulations: '',
        levelofcare: '',
        referraldate: '',
        clientcounties: []

    }; 

    const sectionOneSchema = Yup.object({
        targetpopulations: Yup.string().required("Target populations is a required field.")
    })

    const sectionTwoSchema = Yup.object({
        levelofcare: Yup.string().required("Level of Care is a required field.")
    })

    const combinedSchema = sectionOneSchema.concat(sectionTwoSchema);
    
    const validationSchemas = {
        "Eligibility" : sectionOneSchema,
        "Facility Information" : sectionTwoSchema
    }

    const targetPopulations = [
        {
            title: 'Elderly',
            value: 0
        },
        {
            title: 'BHDID',
            value: 1
        },
        {
            title: 'PD',
            value: 2
        },
        {
            title: 'ABI',
            value: 3
        },
        {
            title: 'MH',
            value: 4
        }
    ];

    const levelOfCare = [
        {
            title: 'Nursing Facility',
            value: 0
        },
        {
            title: 'ICF/MR/DD',
            value: 1
        },
        {
            title: 'Inpatient Psy Facility',
            value: 2
        }
    ]

    const guardianStatus = [
        {
            title: 'Family',
            value: 0
        },
        {
            title: 'Gaurdian',
            value: 1
        },
        {
            title: 'Power of Attorney',
            value: 2
        }
    ]

    const yesNo = [
        {
            title: 'Yes',
            value: 1
        },
        {
            title: 'No',
            value: 0
        }
    ]

    const _validateSection = async (sectionName,formValues) => {
        const schema = validationSchemas[sectionName];

        if(!schema){
            return false;
        }

        try{
            await schema.validate(formValues,{abortEarly: false});
            return true;
        }
        catch(error){
            return false;
        }
        
    }

    const {sectionRefs,activeSection} = useScrollSpy(7);
    return(
        <div className="clientCard">
            <Formik
                initialValues={initialValues}
                validationSchema={combinedSchema}
                enableReinitialize={true}
                onSubmit={() => alert("here")}
            >
                {
                    formik => (
<Form>
                <div className="clientCardInner">
            <h4 className="text-center">Update Referral</h4>
            {/* <BaseForm
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={null}
                onSubmit={() => alert("here")}
            >
                
            </BaseForm> */}
            <div className="mt-4">
                    <ScrollSection ref={sectionRefs.current[0]} title="Eligibility">
                        <Row>
                            <Col xs={12}>
                                <InlineRadioGroup
                                    name="targetpopulations"
                                    primaryLabel="Pick one MFP target population group"
                                    data={targetPopulations}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <InlineRadioGroup
                                    name="levelofcare"
                                    primaryLabel="Level Of Care"
                                    data={levelOfCare}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <InputField
                                type="date"
                                name="referraldate"
                                label="Referral Date"
                                max={new Date().toISOString().split('T')[0]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                            <FloatingLabelSelect name="supervisor" label="Received by Admin staff" data={[{id: -1,name : "--Select from the MFP Team List--"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <InputField
                                type="date"
                                name="facadmitdate"
                                label="Facility Admit Date"
                                max={new Date().toISOString().split('T')[0]}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <InputField
                                type="date"
                                name="facdisdate"
                                label="Facility Discharge Date"
                                max={new Date().toISOString().split('T')[0]}
                                />
                            </Col>
                        </Row>
                    </ScrollSection>
                    <ScrollSection ref={sectionRefs.current[1]} title="Facility Information">
                        <div>
                        <Row>
                            <Col xs={12} md={6} lg={5}>
                                <FloatLabel
                                    type="text"
                                    name="facilityname"
                                    label="Current Facility"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                    <FloatLabel
                                        type="text"
                                        name="facilityaddress"
                                        label="Facility Address"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                                </Col>
                            <Col xs={12} md={6} lg={3}>
                                <FloatLabel
                                    type="tel"
                                    name="facilityphone"
                                    label="Facility Phone"
                                    placeholder="fdsfsfd"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col xs={12} sm={6} md={4}>
                                <FloatLabel
                                    type="tel"
                                    name="facilityphone"
                                    label="Facility Phone"
                                    placeholder="fdsfsfd"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col xs={12} md={6}>
                                    <FloatLabel
                                        type="text"
                                        name="facilityaddress"
                                        label="Facility Address"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                                </Col>
                        </Row> */}
                        {/* <Row>
                        <Col xs={12} md={5}>
                                    <FloatLabel
                                        type="text"
                                        name="facilityaddress"
                                        label="Facility Address"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                                </Col>
                            <Col xs={12} md={2}>
                                <FloatLabel
                                    type="tel"
                                    name="facilityphone"
                                    label="Facility Phone"
                                    placeholder="fdsfsfd"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="supervisor" label="Facility State" data={[{id: -1,name : "Select a state"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="supervisor" label="Facility County" data={[{id: -1,name : "Select a county"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="supervisor" label="Facility Zip" data={[{id: -1,name : "Select a zip"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatLabel
                                    type="text"
                                    name="facilitycity"
                                    label="Facility City"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={8} lg={8} xl={9}>
                                <FloatLabel
                                    type="text"
                                    name="facilitycontact"
                                    label="Facility Contact"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatLabel
                                    type="text"
                                    name="facilitycontactphone"
                                    label="Facility Contact Phone"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                        </div>
                    </ScrollSection>
                    <ScrollSection ref={sectionRefs.current[2]} title="Client Information">
                        <Row>
                            <Col xs={12} md={4}>
                                <FloatingLabelSelect name="supervisor" label="Marital Status" data={[{id: -1,name : "Select a marital status"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <InlineRadioGroup
                                    name="gaurdianstatus"
                                    primaryLabel="Gaurdian/POA/Family"
                                    data={guardianStatus}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <FloatingLabelSelect name="supervisor" label="Relationship Status" data={[{id: -1,name : "Select a marital status"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={9}>
                                <FloatLabel
                                    type="text"
                                    name="clientname"
                                    label="Name"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <FloatLabel
                                    type="tel"
                                    name="clientphone"
                                    label="Phone"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="clientstate" label="State" data={[{id: -1,name : "Select a state"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="clientcounty" label="County" data={[{id: -1,name : "Select a county"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="clientzip" label="Zip" data={[{id: -1,name : "Select a zip"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatLabel
                                    type="text"
                                    name="clientcity"
                                    label="City"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                        <Row>
                        <Col xs={12} md={6}>
                                <FloatLabel
                                    type="text"
                                    name="tdperson"
                                    label="To Discuss Contact Person"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FloatLabel
                                    type="tel"
                                    name="tdemail"
                                    label="To Discuss Contact Email"
                                    placeholder="Email"
                                    autoComplete="offz"
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <FloatLabel
                                    type="tel"
                                    name="tdphone"
                                    label="To Discuss Contact Phone"
                                    placeholder="fdsfsfd"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FloatingLabelSelect name="referralsource" label="Referral Source" data={[{id: -1,name : "Select a source"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={6}>
                                <FloatLabel
                                    type="text"
                                    name="referralsourceother"
                                    label="If Other please specify"
                                    placeholder="fdsfsfd"
                                    autoComplete="offz"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FloatingLabelSelect name="statetransmemberassigned" label="State Transition Team member assigned." data={[{id: -1,name : "--Select from STT list--"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                        </Row>
                    </ScrollSection>
                    <ScrollSection ref={sectionRefs.current[3]} title="Medical Information">
                        <Row>
                            <Col xs={12}>
                                <InlineRadioGroup
                                    name="medicaidrecipientone"
                                    primaryLabel="Medicaid recipient at least one day."
                                    data={yesNo}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FloatingLabelSelect name="medicaidrecipientoneverified" label="Verified" data={[{id: -1,name : "--Select from MFP Team list--"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <InlineRadioGroup
                                    name="institutionalized"
                                    primaryLabel="Institutionalized at least 90 days in facility"
                                    data={yesNo}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FloatingLabelSelect name="institutionalizedverified" label="Verified" data={[{id: -1,name : "--Select from MFP Team list--"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <InputField
                                type="date"
                                name="anticipiatedtransition"
                                label="Anticipiated Transition Date"
                                max={new Date().toISOString().split('T')[0]}
                                />
                            </Col>
                        </Row>
                    </ScrollSection>
                    <ScrollSection ref={sectionRefs.current[4]} title="Client Preferences">
                        <Row>
                            <Col xs={12}>
                                <FloatLabel
                                        type="text"
                                        name="prevaddress"
                                        label="Previous Home Address"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={6}>
                                <FloatLabel
                                        type="text"
                                        name="prevcity"
                                        label="Previous City"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="prevstate" label="Previous State" data={[{id: -1,name : "Select a state"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={4} lg={4} xl={3}>
                                <FloatingLabelSelect name="prevzip" label="Previous Zip" data={[{id: -1,name : "Select a zip"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        name="preftranslocation"
                                        label="Preffered Transition Location"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="clientcounty">
                                    <div className="mb-3">
                                        <strong>Counties in which the client would consider living</strong>
                                    </div>
                                    <div>
                                    <SelectWrapper
                                        objectName="Counties"
                                        name="clientcounties"
                                        searchboxid={"clientcounty"}
                                    />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ScrollSection>
                    <ScrollSection ref={sectionRefs.current[5]} title="Functional Considerations">
                        <Row>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        as="textarea"
                                        name="visionconsideration"
                                        label="Vision"
                                        placeholder="Email"
                                        autoComplete="offz"
                                        style={{height: '100px'}}
                                    />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        as="textarea"
                                        name="mobilityconsideration"
                                        label="Mobility"
                                        placeholder="Email"
                                        autoComplete="offz"
                                        style={{height: '100px'}}
                                    />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        as="textarea"
                                        name="safetyconsideration"
                                        label="Safety"
                                        placeholder="Email"
                                        autoComplete="offz"
                                        style={{height: '100px'}}
                                    />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        as="textarea"
                                        name="otherconsideration"
                                        label="Other"
                                        placeholder="Email"
                                        autoComplete="offz"
                                        style={{height: '100px'}}
                                    />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        as="textarea"
                                        name="actionscommentsconsideration"
                                        label="Actions/Comments"
                                        placeholder="Email"
                                        autoComplete="offz"
                                        style={{height: '100px'}}
                                    />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        name="housingref"
                                        label="Housing Referral"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                            </Col>
                        </Row>
                    </ScrollSection>
                    <ScrollSection ref={sectionRefs.current[6]} title="Transition Coordinator">
                        <Row>
                            <Col xs={12}>
                            <FloatingLabelSelect name="regionaltc" label="Regional TC Assigned" data={[{id: -1,name : "--Make a selection--"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                            </Col>
                            <Col xs={12} md={9}>
                            <FloatLabel
                                        type="text"
                                        name="tccontact"
                                        label="TC Contact Person"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                            </Col>
                            <Col xs={12} md={3}>
                            <FloatLabel
                                        type="tel"
                                        name="tccontactphone"
                                        label="TC Contact Phone"
                                        placeholder="Email"
                                        autoComplete="offz"
                                    />
                            </Col>
                            <Col xs={12}>
                            <InputField
                                type="date"
                                name="telephonecontactdate"
                                label="Date of telephone contact"
                                max={new Date().toISOString().split('T')[0]}
                                />
                            </Col>
                            <Col xs={12}>
                            <InputField
                                type="date"
                                name="schedapptdate"
                                label="Scheduled Appointment Date"
                                max={new Date().toISOString().split('T')[0]}
                                />
                            </Col>
                            <Col xs={12}>
                            <InputField
                                type="date"
                                name="appoitnmentoccured"
                                label="Date Appointment Occured"
                                max={new Date().toISOString().split('T')[0]}
                                />
                            </Col>
                            <Col xs={12}>
                            <FloatLabel
                                        type="text"
                                        as="textarea"
                                        name="tcnoted"
                                        label="TC Notes"
                                        placeholder="Email"
                                        autoComplete="offz"
                                        style={{height: '100px'}}
                                    />
                            </Col>
                            <Col xs={12}>
                            <InlineRadioGroup
                                    name="informationforms"
                                    primaryLabel="Information forms given to client?"
                                    data={yesNo}
                                />
                            </Col>
                            <Col xs={12}>
                            <InlineRadioGroup
                                    name="rightsresp"
                                    primaryLabel="Rights and Responsibilities given to client?"
                                    data={yesNo}
                                />
                            </Col>
                            <Col xs={12}>
                            <InlineRadioGroup
                                    name="consentform"
                                    primaryLabel="Client completed consent form?"
                                    data={yesNo}
                                />
                            </Col>
                        </Row>
                    </ScrollSection>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button type="submit">Submit</Button>
            </div>
            <ScrollProgress sectionCount={2} activeSection={activeSection} sectionRefs={sectionRefs} formik={formik} validateSection={async (sectionName) => await _validateSection(sectionName,formik.values)}/>
                </Form>
                    )
                }
            </Formik>         
        </div>
    )
}

const SelectWrapper = ({name,objectName,searchboxid}) => {
    const counties = [
        {
            id: 'counties',
            items: [
                {
                    id: 0,
                    name: 'Adair'
                }
            ]
        }
    ]
    
    const [field,meta,helpers] = useField(name);
    const[fieldExtracted,setFieldExtracted] = useState({items: new Set(),actions: new Map()});

    const _translateData = (fieldValues) => {
        const dataSet = new Set(fieldValues.map(id => `counties:${id}`));
        
        let newSelections = {
            items: dataSet, 
            actions: new Map(),
        }

        return newSelections;
    }

    const _translateMapping = (data) => {
        let result = [];

        data.items.forEach(key => {
            const id = parseInt(key.split(':')[1]);

            result.push(id);
        })

        return result.sort((a,b) => a - b);
    }

    return(
        <SelectionComponentTest 
            objectName={objectName}
            data={counties}
            searchboxid={searchboxid}
            field={_translateData(field.value)}
            fieldSetter={(data) => helpers.setValue(_translateMapping(data))}
        />
    )
}

export default ApplicationForm;