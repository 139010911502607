import useMessageStore from "../stores/message-store";

const useMessageHub = () => {
    const addActivity = useMessageStore(state => state.addActivity);
    const addToast = useMessageStore(state => state.addToast);

    const registerActivity = (activity,showToast = false,autoHideToast = true) => {
        const id = new Date().getTime();
        const timestamp = Date.now() - 1000; //Offset required due to interval in messagehub
        if(showToast){
            addToast(
                {
                    id: id,
                    body: activity.body,
                    autoHide: autoHideToast,
                    errorFlag: activity.errorFlag,
                    timestamp: timestamp
                }
            );
        }
        addActivity(
            {
                id: id,
                body: activity.body,
                errorFlag: activity.errorFlag,
                timestamp: timestamp
            }
        );
    }

    return {registerActivity};
}

export default useMessageHub;