import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../styles/bubbleselection.module.css';
import emailStyles from '../styles/emaillistitem.module.css';
import { useCallback, useEffect, useRef, useState } from "react";
import ButtonOverride from './buttonoverride';
import { faAdd, faCheck, faMultiply } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from './selectcomponent';
import { useModalStatusContext } from '../contexts/modalstatuscontext';
import { useField } from 'formik';
import SelectionComponentTest from './selectnew/selectcomponenttest';
import useApi from '../hooks/use-api';
import GapRecipientSkeleton from '../components/gaprecipientskeleton';
import InsetAlert from './insetalert';

const EmailRecipientSelection = ({fieldName,selectedRecipients,focusLock = false,lockFocusFunction}) => {
    const {isLoading,setLoading,HasError,handleError} = useModalStatusContext();
    const [field,meta,helpers] = useField(fieldName);
    const [displayItems,setDisplayItems] = useState([]);
    const [pendingSelections,setPendingSelections] = useState([]);
    const [fieldExtracted,setFieldExtracted] = useState({items: new Set(),actions: new Map()});
    const [selectionDataStructure,setSelectionDataStructure] = useState();
    const [rawData,setRawData] = useState([]);
    const[editMode,setEditMode] = useState(false);
    const windowRef = useRef(null);
    const {processing, error, getRequest} = useApi();
    
    useEffect(() => {
        setLoading(true);
        const get = async () => {
            const [data, status] = await getRequest('emaildetails', {timestamp: Date.now()}); 
            if (status === 200) {
                setSelectionDataStructure(_dataExtractFunction(data));
                setRawData(data);
                setLoading(false);
            }
            else{
                setSelectionDataStructure([]);
                setRawData([]);
                setLoading(false);
            }
        };

        get();
    },[])

    useEffect(() => {
        setPendingSelections(field.value);

        if(!Array.isArray(rawData) && !Array.isArray(field.value)){
            const displayItemList = _flattenSortAndEnrich(field.value,rawData);

            setDisplayItems(displayItemList);
        }
    },[field.value,rawData]);

    // const trapFocus = (event) => {
    //     if(!editMode) return;
    //     if(event.key !== 'Tab') return;

    //     const focusableElements = Array.from(windowRef.current.querySelectorAll('input, button, textarea'));

    //     const currentFocusIndex = focusableElements.findIndex(element => element === document.activeElement);
    //     let nextFocusIndex = 0;

    //     if(event.shiftKey){
    //         nextFocusIndex = currentFocusIndex <= 0 ? focusableElements.length - 1 : currentFocusIndex - 1;
    //     }
    //     else{
    //         nextFocusIndex = currentFocusIndex >= focusableElements.length - 1 ? 0 : currentFocusIndex + 1;
    //     }

    //     focusableElements[nextFocusIndex].focus();
    //     event.preventDefault();
    // };

    const _handleCloseClick = () => {
        setEditMode(false);
        setPendingSelections(field.value);
    }

    const _handleEditOpen = () => {
        if(!meta.touched){
            helpers.setTouched(true);
        }

        setEditMode(true);
    }

    const _flattenSortAndEnrich = (data, additionalInfo) => {
        // Create a single array of items by mapping over users and groups, then enrich and merge them
        const items = [...data.users.map(u => ({ ...u, itemType: 'user' })),
                       ...data.groups.map(g => ({ ...g, itemType: 'group' }))]
          .map(item => {
            // Find the corresponding item info and action info based on the item type and action id
            const info = additionalInfo[item.itemType === 'user' ? 'users' : 'emailGroups']
              .find(infoItem => infoItem.id === parseInt(item.id));
            const actionInfo = additionalInfo.actions.find(action => action.id === item.actions);
            
            return { ...info, action: actionInfo ? actionInfo.name : '',actionId : actionInfo ? actionInfo.id : 0, itemType: item.itemType };
          });
      
        // Sort by actions in descending order
        items.sort((a,b) => a.name.localeCompare(b.name)).sort(InverseOrdinalCaseSorter);
      
        return items;
      }
      
    const _handleSaveClick = (items) => {
        // const displayItemList = _flattenSortAndEnrich(items,selectionDataStructure);
        const displayItemList = _flattenSortAndEnrich(items,rawData);
        helpers.setValue(items);
        setDisplayItems(displayItemList);
        setEditMode(false);
    }

    const addToMap = (map,type, { id, actions }) => {
        const key = `${type}:${id}`;
        if (!map.has(key)) {
          map.set(key, new Set());
        }
        map.get(key).add(actions);
      };

    const _translateData = (fieldValues) => {
        const compositeMap = new Map();

        fieldValues.users?.forEach(user => addToMap(compositeMap,'users',user));

        fieldValues.groups?.forEach(group => addToMap(compositeMap,'groups',group));


        let newSelections = {
            items: new Set(), 
            actions: compositeMap,
        }

        return newSelections;
    }

    const _translateMapping = (values) => {
        const result = {};
        result['users'] = [];
        result['groups'] = [];

     values.actions.forEach((actionSet, compositeKey) => {
        const [type, id] = compositeKey.split(':');
        const value = Array.from(actionSet);

        if (!result[type]) {
        result[type] = [];
        }

        result[type].push({ id: parseInt(id), actions : value[0] });
        });

        result.users.sort((a,b) => a.id - b.id);
        result.groups.sort((a,b) => a.id - b.id);

        return result;
    }

    useEffect(() => {
        if(editMode){
            lockFocusFunction(true);
        }
        else{
            lockFocusFunction(false);
        }
        // const currentFocusElement = windowRef.current;
        // currentFocusElement.addEventListener('keydown',trapFocus);

        // return () => {
        //     currentFocusElement.removeEventListener('keydown',trapFocus);
        // };

    },[editMode]);

    const InverseOrdinalCaseSorter = (a,b) => {
        if(a.actionId !== undefined && b.actionId !== undefined){
            if(a.actionId > b.actionId)
                return 1;
            if(a.actionId < b.actionId)
                return -1;

            return 0;
        }
        else{
            if(a.id > b.id)
                return 1;
            if(a.id < b.id)
                return -1;

            return 0;
        }
    }

    const _dataExtractFunction = (dataSet) => {
        // setSelectionDataStructure(dataSet);
        setRawData(dataSet);
        const recipientSelections = [
            {
                id: 'users',
                name: 'users',
                items: dataSet.users.map(user => ({
                    ...user,
                    allowMultipleActionSelections: false,
                    actions: dataSet.actions
                }))
            },
            {
                id: 'groups',
                name: 'Email Groups',
                items: dataSet.emailGroups.map(group => ({
                    ...group,
                    allowMultipleActionSelections: false,
                    actions: dataSet.actions
                }))
            }
        ]

        return recipientSelections;
    }

    const _getSendStyle = (actionID) => {
        if(actionID === 0){
            return emailStyles.sendTypeTo;
        }
        else if(actionID === 1){
            return emailStyles.sendTypeCc;
        }
        else{
            return emailStyles.sendTypeBcc;
        }
    }

    return(
        editMode
        ?
            <>
                <div className={styles.overlay}></div>
                <div className={styles.innerOverlay}></div>
                <div className={`${styles.primaryContainer} ${styles.editmode}`} ref={windowRef}>
                    <div className={`${styles.header} ${styles.editmode}`}>
                        <strong>Edit Recipients</strong>
                    </div>
                    <SelectionComponentTest
                        searchboxid={"searchRecipients"}
                        objectName={"Recipients"}
                        disabled={HasError}
                        ignoreOutsideClickDataAttribute={"data-override-event"}
                        data={selectionDataStructure }
                        sortFunction={InverseOrdinalCaseSorter}
                        field={_translateData(pendingSelections)}
                        fieldSetter={(items) => setPendingSelections(_translateMapping(items))}
                    />
                    <div className={styles.footer}>
                        <ButtonOverride className={styles.actionBtn} type="button" onClick={_handleCloseClick} data-override-event="true">
                            <FontAwesomeIcon icon={faMultiply} size='lg' style={{color: 'red'}} data-override-event="true"/>
                        </ButtonOverride>
                        <ButtonOverride className={styles.actionBtn} type="button" onClick={() => _handleSaveClick(pendingSelections)} data-override-event="true">
                            <FontAwesomeIcon icon={faCheck} size='lg' style={{color: 'green'}} data-override-event="true" />
                        </ButtonOverride>
                    </div>
                </div>
            </>
        :
            <div className={`${styles.primaryContainer} ${styles.noneditmode}`} ref={windowRef}>
                <div className={`${styles.header} ${styles.noneditmode}`}>
                    <strong>Recipients</strong>
                </div>
                {
                    meta.error && !error && meta.touched
                    ?
                        <div className={styles.formError}>
                            {meta.error}
                        </div>
                    :
                        null
                }
                {
                    error
                    ?
                        <InsetAlert error={error} dismissible={false} style={{width: '100%'}}/>
                    :
                    <div className={styles.bubbleContainer}>
                    <div className={emailStyles.recipientTags}>
                        {
                            processing
                            ? 
                                <GapRecipientSkeleton />
                            :
                            <>
                                {
                                    displayItems.map((item,index) => (
                                        <ButtonOverride key={index} type='button' className={`${emailStyles.recipientTag} ${_getSendStyle(item.actionId)}`} onClick={_handleEditOpen} tabIndex={focusLock && -1}>
                                            <span className={emailStyles.sendTypeText}>{item.action}</span>
                                            {item.name}
                                        </ButtonOverride>
                                    ))
                                }
                                {
                                    renderAddButton(displayItems,_handleEditOpen,focusLock)
                                }
                            </>
                        }
                    </div>
                </div>
                }
            </div>
        // <>
        // {
        //     editMode
        //     ?
        //         <>
        //             <div className={styles.overlay}></div>
        //             <div className={styles.innerOverlay}></div>
        //         </>
        //     :
        //         null
        // }
        //     <div className={`${styles.primaryContainer} ${editMode ? styles.editmode : styles.noneditmode}`} ref={windowRef}>
        //         <div className={`${styles.header} ${editMode ? styles.editmode : styles.noneditmode}`}>
        //             <strong>{editMode ? <>Edit Recipients</> : <>Recipients</>}</strong>
        //         </div>
        //         {
        //             meta.error && !error && meta.touched
        //             ?
        //                 <div className={styles.formError}>
        //                     {meta.error}
        //                 </div>
        //             :
        //                 null
        //         }
        //         {
        //             editMode
        //             ?
        //                 <SelectionComponentTest
        //                     searchboxid={"searchRecipients"}
        //                     objectName={"Recipients"}
        //                     disabled={HasError}
        //                     ignoreOutsideClickDataAttribute={"data-override-event"}
        //                     // retrieveDataEndpoint="emaildetails"
        //                     // dataExtractFunction={(data) => _dataExtractFunction(data)}
        //                     data={selectionDataStructure }
        //                     sortFunction={InverseOrdinalCaseSorter}
        //                     field={_translateData(pendingSelections)}
        //                     fieldSetter={(items) => setPendingSelections(_translateMapping(items))}
        //                 />
        //             :
        //                 <div className={styles.bubbleContainer}>
        //                     <div className={emailStyles.recipientTags}>
        //                         {
        //                             displayItems.map((item,index) => (
        //                                 <ButtonOverride key={index} type='button' className={`${emailStyles.recipientTag} ${_getSendStyle(item.actionId)}`} onClick={() => setEditMode(true)} tabIndex={focusLock && -1}>
        //                                     <span className={emailStyles.sendTypeText}>{item.action}</span>
        //                                     {item.name}
        //                                 </ButtonOverride>
        //                             ))
        //                         }
        //                         {
        //                             renderAddButton(displayItems,setEditMode,focusLock)
        //                         }
        //                         {/* <ButtonOverride className={`${styles.actionBtn} ${styles.addItemBtn} ${emailStyles.emailEventTag}`} type="button" onClick={() => setEditMode(true)}>
        //                             <FontAwesomeIcon icon={faAdd} size='xl' style={{color: "#4a707a"}}/>
        //                         </ButtonOverride> */}
        //                     </div>
        //                 </div>
        //         }
        //         {
        //             editMode
        //             ?
        //                 <div className={styles.footer}>
        //                     <ButtonOverride className={styles.actionBtn} type="button" onClick={_handleCloseClick} data-override-event="true">
        //                         <FontAwesomeIcon icon={faMultiply} size='lg' style={{color: 'red'}} data-override-event="true"/>
        //                     </ButtonOverride>
        //                     <ButtonOverride className={styles.actionBtn} type="button" onClick={() => _handleSaveClick(pendingSelections)} data-override-event="true">
        //                         <FontAwesomeIcon icon={faCheck} size='lg' style={{color: 'green'}} data-override-event="true" />
        //                     </ButtonOverride>
        //                 </div>
        //             :
        //                 null
        //         }
        //     </div>
        // </>
    )
}

const renderAddButton = (items,openFunction,focusLock) => {
    const finalItem = items[items.length - 1];

    const _getAddStyle = (actionId) => {
        if(actionId === 0){
            return styles.to;
        }
        else if (actionId === 1){
            return styles.cc;
        }
        else{
            return styles.bcc
        }
    }
    return(
        finalItem
        ?
            <ButtonOverride className={`${styles.actionBtn} ${styles.addItemBtn} ${emailStyles.emailEventTag} ${_getAddStyle(finalItem.actionId)}`} type="button" onClick={openFunction} tabIndex={focusLock && -1}>
                <FontAwesomeIcon icon={faAdd} size='xl' />
            </ButtonOverride>
        :
            <ButtonOverride className={`${styles.actionBtn} ${styles.addItemBtn} ${emailStyles.emailEventTag}`} type="button" onClick={openFunction} tabIndex={focusLock && -1}>
                <FontAwesomeIcon icon={faAdd} size='xl' />
            </ButtonOverride>
    )
}


export default EmailRecipientSelection;