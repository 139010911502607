import { useEffect, useState } from 'react';
import styles from '../styles/TreeComponent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus,faSquareMinus } from '@fortawesome/free-regular-svg-icons';
import NonExpandableSearchBox from './nonexpandablesearchbox';
import { useField } from 'formik';
import useApi from '../hooks/use-api';
import { useModalStatusContext } from '../contexts/modalstatuscontext';
import TreeSkeleton from './treeskeleton';
import InsetAlert from './insetalert';
import { Overlay, Popover } from 'react-bootstrap';

const createVirtualGroupings = (nodes) => {
    let flattened = [];

    nodes.forEach(node => {
        const pathSegments = node.path.split('.');
        const lastIndex = pathSegments.length - 1;

        pathSegments.forEach((segment,index) => {
            const alreadyExists = flattened.some(item => item.path === pathSegments.slice(0,index + 1).join('.'));

            if(!alreadyExists){
                if(index === lastIndex){
                    flattened.push({...node,level: index, isReal: true});
                }
                else{
                    //Virtual Node with generated id
                    flattened.push({id: segment + index,name: segment, path: pathSegments.slice(0,index + 1).join('.'), level : index, isReal: false});
                }
            }
        })
    });

    return flattened.sort((a,b) => a.path.localeCompare(b.path));
}

const getAncestorPaths = (path) => {
    const segments = path.split('.');
    return segments.map((_,index) => segments.slice(0,index + 1).join('.'));
}

const TreeComponent = ({name,objectName,actionName = 'Grant',retrieveDataEndpoint,extractDataFunction,searchboxid,disabled = false}) => {
    const [field,meta,helpers] = useField(name);

    const [displayNodes,setDisplayNodes] = useState([]);
    const [collapsedPaths,setCollapsedPaths] = useState(new Set());
    const [searchTerm,setSearchTerm] = useState('');
    const {processing, error, getRequest} = useApi();
    const {setLoading,handleError,statusErrors} = useModalStatusContext();

    const [items,setItems] = useState([]);

    useEffect(() => {
        setLoading(true);

        const get = async () => {
            const [data, status] = await getRequest(retrieveDataEndpoint, {timestamp: Date.now()}); 
            if (status === 200) {
                let values = extractDataFunction(data);

                setItems(values);
                setLoading(false);
            }
            else{
                setItems([]);
                setLoading(false);
            }
        };

        get();
    },[]);

    useEffect(() => {
        if(error){
            handleError(error);
        }
    },[error])

    useEffect(() => {
        updateFilteredSet(items,collapsedPaths,searchTerm);
    },[items,collapsedPaths,searchTerm]);

    const updateFilteredSet = (nodes, collapsedPaths,searchTerm) => {
        let updatedSet = createVirtualGroupings(nodes);

        if(searchTerm){
            const lowerCaseSearch = searchTerm.toLowerCase();
            let searchSet = new Set();

            updatedSet.forEach(node => {
                if(node.path.toLowerCase().includes(lowerCaseSearch)){
                    getAncestorPaths(node.path).forEach(ancestorPath => searchSet.add(ancestorPath));
                }
            });

            updatedSet = updatedSet.filter(node => searchSet.has(node.path));
        }

        updatedSet = updatedSet.filter(node => {
            const nodePathSegments = node.path.split('.');
            for(let collapsedPath of collapsedPaths){
                const collapsedPathSegments = collapsedPath.split('.');

                if(collapsedPathSegments.length < nodePathSegments.length && 
                    collapsedPathSegments.every((seg,i) => 
                        seg === nodePathSegments[i])){
                            return false;
                        }
                        
            }
            
            return true;
        })


        setDisplayNodes(updatedSet);
    };

    const toggleCollapse = (path) => {
        if(!path){
            return;
        }

        setCollapsedPaths(prev => {
           const newCollapsedPaths = new Set(prev);
           
           if(newCollapsedPaths.has(path)){
            newCollapsedPaths.delete(path);
           }
           else{
            newCollapsedPaths.add(path);
           }

           updateFilteredSet(items,newCollapsedPaths,searchTerm);

           return newCollapsedPaths;
        });
    }

    const childrenSelected = (path,selectedItemList,filteredSet,isSearching) => {
        const isChildSelected = (childPath) => {
            const virtualGroupings = createVirtualGroupings(items);
            const selItem = new Set(selectedItemList);

            const revisedItemList = new Set(virtualGroupings.filter(node => selItem.has(node.id)).map(node => node.path));

            if(revisedItemList.has(childPath)){
                return true;
            }


            const childIsVirtual = !createVirtualGroupings(items).find(node => node.path === childPath).isReal;

            if(childIsVirtual){

                const children = isSearching
                ? 
                    collapsedPaths.has(childPath)
                    ?
                        createVirtualGroupings(items).filter(node => node.path.startsWith(childPath + '.') && node.path !== childPath && node.path.toLowerCase().includes(searchTerm)) 
                    :
                        filteredSet.filter(node => node.path.startsWith(childPath + '.') && node.path !== childPath) 

                : 
                    createVirtualGroupings(items).filter(node => node.path.startsWith(childPath + '.') && node.path !== childPath);

                return children.length > 0 && children.every(node => isChildSelected(node.path));
            }

            return false;
        }

        const children = isSearching
            ?
                collapsedPaths.has(path)
                ?
                    createVirtualGroupings(items).filter(node => node.path.startsWith(path + '.') && node.path !== path && node.path.toLowerCase().includes(searchTerm))
                :
                    filteredSet.filter(node => node.path.startsWith(path + '.') && node.path !== path)

            :
                createVirtualGroupings(items).filter(node => node.path.startsWith(path + '.') && node.path !== path)

        return children.length > 0 && children.every(node => isChildSelected(node.path));

        
    }

    const toggleSelection = (item) => {
        if(item.isReal){
            if(field.value.includes(item.id)){
                const newItems = field.value.filter(selItem => selItem !== item.id).sort();
                helpers.setValue(newItems);
            }
            else{
                const newItems = [...field.value,item.id].sort();
                helpers.setValue(newItems);
            }
        }
        else{
            let childrenToUpdate;
            let newItems = new Set([...field.value]);

            if(collapsedPaths.has(item.path)){
                if(!searchTerm){
                    childrenToUpdate = createVirtualGroupings(items).filter(child => child.path.startsWith(item.path + '.') && child.isReal);
                }
                else{
                    childrenToUpdate = createVirtualGroupings(items).filter(child => child.path.startsWith(item.path + '.') && child.isReal && child.path.toLowerCase().includes(searchTerm.toLowerCase()));
                }
            }
            else{
                childrenToUpdate = displayNodes.filter(child => child.path.startsWith(item.path + '.') && child.isReal);
            }

            const shouldSelect = childrenToUpdate.some(child => !newItems.has(child.id));

            childrenToUpdate.forEach(child => {
                shouldSelect
                ?
                    newItems.add(child.id)
                :
                    newItems.delete(child.id)
            })

            let returnValue = Array.from(newItems);

            if(returnValue.length > 0){
                returnValue = [...returnValue].sort();
            }

            helpers.setValue(returnValue);
        }
    }
    
    const TestItemSelected = (_ItemID) => {
        const items = new Set([...field.value]);

        if(items.has(_ItemID)){
            return true;
        }
        else{
            return false;
        }
    }

    return(
        <>
        {
            processing
            ?
                <TreeSkeleton />
            :
                error
                ?
                    <InsetAlert error={error} dismissible={false} style={{width: '100%'}}/>
                :
                <>
                <div className='mb-2' >
            <div className={styles.searchContainer}>
                <NonExpandableSearchBox id={searchboxid} style={{color: 'black'}} placeholder={`Search ${objectName}'s`} searchTerm={searchTerm} setSearchTerm={setSearchTerm} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
            </div>
        </div>
        <div className={styles.treeBody}>
            <div style={{width: '95%'}}>
                <div className={styles.treeHeader}>
                    <strong>{`${objectName}s`}</strong>
                    <strong className={styles.treeHeaderRight}>{actionName}</strong>
                </div>
                <div >
                    {
                        displayNodes.length ?
                            displayNodes.map((node,index) => (
                                <div key={index} className={`${styles.treeNode} ${index === displayNodes.length - 1 || index === 0 ? styles.noBorder : ''}`} style={{paddingLeft: `${node.level * 20}px`}}>
                                    {
                                        !node.isReal
                                        ?
                                            collapsedPaths.has(node.path)
                                            ?
                                                <button className={`${styles.collapseBtn} me-1`} type='button' title='Expand' aria-label='Expand' onClick={() => !node.isReal && toggleCollapse(node.path)}>
                                                    <FontAwesomeIcon icon={faSquarePlus}  />
                                                </button>
                                            :
                                                <button className={`${styles.collapseBtn} me-1`} type='button' title='Collapse' aria-label='Collapse' onClick={() => !node.isReal && toggleCollapse(node.path)}>
                                                    <FontAwesomeIcon icon={faSquareMinus}  />
                                                </button>
                                        :
                                            null
                                    }
                                    <div className='d-flex justify-content-between' style={{width: '100%'}}>
                                        <span>
                                            {node.name}
                                        </span>
                                        <div className={styles.treeHeaderRight}>
                                            <input type='checkbox' onChange={() => toggleSelection(node) } checked={TestItemSelected(node.id) || (!node.isReal && childrenSelected(node.path,field.value,displayNodes,searchTerm ? true : false))}/>
                                        </div>
                                    </div>
                                </div>
                                
                            ))
                            :
                                searchTerm ?
                                    <div className='d-flex justify-content-center mt-1'>
                                        <span>No Results Found</span>
                                    </div>
                                :
                                    <div className='d-flex justify-content-center mt-1'>
                                        <span>No Items Exist</span>
                                    </div>
                        }
                
                </div>
            </div>
        </div>
                </>
        }
        </>
    )
}

export default TreeComponent;