import { Button, Col, Row } from "react-bootstrap";
import InlineRadioGroup from "../../../formik/inlineradiogroup";
import { Formik,Form } from "formik";
import FloatLabel from "../../../formik/floating-label";
import FormikCheckbox from "../../../formik/formikcheckbox";

const Map351VIForm = () => {
    const initialValues = {
        preparemeals: null,
        shopindependently: null,
        lighthousekeeping: null,
        heavyhousework: null,
        laundry: null,
        medicine: null,
        finances: null,
        telephone: null,
        heavyhouseworkcomment: '',
        laundrycomment: '',
        medicinecomment: '',
        financescomment: '',
        telephonecomment: '',
        lighthousekeepingcomment: '',
        shopindependentlycomment: '',
        preparemealscomment: '',
        tessfsd: '',
        arrangesmealprep: true
    }

    const validation = {

    }

    const yesNo = [
        {
            title: 'Yes',
            value: 1
        },
        {
            title: 'No',
            value: 0
        }
    ]

    const ancillaryPrepareMeals = [
        {
            title: 'Arranges for meal preperation',
            name: 'arrangesmealprep'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'mealssupervisionrequired'
        },
        {
            title: 'Requires assistance with meal preparation',
            name: 'requiresassistance'
        },
        {
            title: 'Requires total meal preperation',
            name: 'requirestotal'
        }
    ]

    const ancillaryShopIndepdently = [
        {
            title: 'Arranges for shopping to be done',
            name: 'arrangesshopping'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'shoppingsupervisionrequired'
        },
        {
            title: 'Requires assistance with shopping',
            name: 'shoppingrequiresassistance'
        },
        {
            title: 'Unable to participate in shopping',
            name: 'shoppingunable'
        }
    ]

    const ancillaryLightHousekeeping = [
        {
            title: 'Arranges for light housekeeping duties to be performed.',
            name: 'arrangeshousekeeping'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'lighthousekeepingsupervisionrequired'
        },
        {
            title: 'Requires assistance with light housekeeping',
            name: 'lighthousekeepingrequiresassistance'
        },
        {
            title: 'Unable to perform any light housekeeping',
            name: 'lighthousekeepingunable'
        }
    ]

    const ancillaryHeavyHousework = [
        {
            title: 'Arranges for heavy housework to be performed.',
            name: 'arrangesheavyhousework'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'heavyhouseworksupervisionrequired'
        },
        {
            title: 'Requires assistance with heavy housework',
            name: 'heavyhouseworkrequiresassistance'
        },
        {
            title: 'Unable to perform heavy housework',
            name: 'heavyhouseworkunable'
        }
    ]

    const ancillaryLaundry = [
        {
            title: 'Arranges for laundry to be done',
            name: 'arrangeslaundry'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'laundrysupervisionrequired'
        },
        {
            title: 'Requires assistance with laundry tasks',
            name: 'laundryrequiresassistance'
        },
        {
            title: 'Unable to perform any laundry tasks',
            name: 'laundryunable'
        }
    ]

    const ancillaryMedicine = [
        {
            title: 'Arranges for medication to be obtained and taken correctly',
            name: 'arrangesmedication'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'medicationsupervisionrequired'
        },
        {
            title: 'Requires assistance with obtaining and taking medication correctly',
            name: 'medicationrequiresassistance'
        },
        {
            title: 'Unable to obtain medication and take correctly',
            name: 'medicationunable'
        }
    ]

    const ancillaryFinances = [
        {
            title: 'Arranges for someone else to handle finances',
            name: 'arrangesfinances'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'financessupervisionrequired'
        },
        {
            title: 'Requires assistance with handling finances',
            name: 'financesrequiresassistance'
        },
        {
            title: 'Unable to handle finances',
            name: 'financesunable'
        }
    ]

    const ancillaryTelephone = [
        {
            title: 'Requires adaptive device to use telephone',
            name: 'arrangestelephone'
        },
        {
            title: 'Requires supervision or verbal cues',
            name: 'telephonesupervisionrequired'
        },
        {
            title: 'Requires assistance when using telephone',
            name: 'telephonerequiresassistance'
        },
        {
            title: 'Unable to use telephone',
            name: 'telephoneunable'
        }
    ]

    return(
        <div className="clientCard">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={() => alert("here")}
            >
                {
                    formik => (
                        <Form>
                            <div className="clientCardInner">
                            <h4 className="text-center">SECTION VI - INSTRUMENTAL ACTIVITIES OF DAILY LIVING</h4>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="preparemeals"
                                            primaryLabel="1) Is member able to prepare meals independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.preparemeals === 0 &&
                                            ancillaryPrepareMeals.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="preparemealscomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="shopindependently"
                                            primaryLabel="2) Is member able to shop independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.shopindependently === 0 &&
                                            ancillaryShopIndepdently.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="shopindependentlycomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                            <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="lighthousekeeping"
                                            primaryLabel="3) Is member able to perform light housekeeping independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.lighthousekeeping === 0 &&
                                            ancillaryLightHousekeeping.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="lighthousekeepingcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                            <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="heavyhousework"
                                            primaryLabel="4) Is member able to perform heavy housework independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.heavyhousework === 0 &&
                                            ancillaryHeavyHousework.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="heavyhouseworkcomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                            <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="laundry"
                                            primaryLabel="5) Is member able to perform laundry tasks independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.laundry === 0 &&
                                            ancillaryLaundry.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="laundrycomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                            <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="medicine"
                                            primaryLabel="6) Is member able to plan/arrange for pick-up, delivery, or some means of gaining possession of medication(s) and take them independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.medicine === 0 &&
                                            ancillaryMedicine.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="medicinecomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                            <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="finances"
                                            primaryLabel="7) Is member able to handle finances independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.finances === 0 &&
                                            ancillaryFinances.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="financescomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-4">
                            <Row>
                                    <Col xs={12} md={6}>
                                        <InlineRadioGroup  
                                            name="telephone"
                                            primaryLabel="8) Is member able to use the telephone independently?"
                                            data={yesNo}
                                        />
                                        <p className="mb-0">(If no, check below all that apply and comment)</p>
                                        {
                                            formik.values.telephone === 0 &&
                                            ancillaryTelephone.map((item,index) => (
                                                <FormikCheckbox 
                                                    key={index}
                                                    label={item.title}
                                                    name={item.name}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatLabel
                                            type="text"
                                            as="textarea"
                                            name="telephonecomment"
                                            label="Comment"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            style={{height: '215px'}} 
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Button type="submit">Submit</Button>
                        
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

export default Map351VIForm;