import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useField } from 'formik';

function FloatingLabelSelect({label,data,...props}){
    const [field, meta] = useField(props);
    const safeId = label.replace(/[^\w]/gi, "").substring(0, 9);
    return (
        <>
        <FloatingLabel
            controlId={`input-${safeId}`}
            label= {label}
            className='mb-3'
          >
                <Form.Select aria-label={label} {...props} {...field}
                isInvalid={meta.touched && meta.error}>
                    {
                        data.map((row,idx) => {
                            return <option value={row.id} key={idx}>{row.name}</option>
                        })
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {meta.error}
                </Form.Control.Feedback>
          </FloatingLabel>
        </>
          
        
      );
};

export default FloatingLabelSelect;