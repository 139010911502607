import { Form, Formik } from 'formik';
import React from 'react';
import { Col,Row } from 'react-bootstrap';
import FloatingLabelSelect from '../../../formik/floating-label-select';
import FloatLabel from '../../../formik/floating-label';
import InlineRadioGroup from '../../../formik/inlineradiogroup';
import FloatingActionButton from '../../../components/floatingactionbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../formik/input-field';
import FormikCheckbox from '../../../formik/formikcheckbox';

const Map351IIForm = () => {
    const initialValues = {

    }
  
    const yesNo = [
      {
          title: 'Yes',
          value: 1
      },
      {
          title: 'No',
          value: 0
      }
  ]

  const applicationType = [
    {
      title: 'Adjudicated',
      value: 0
    },
    {
      title: 'Nonadjudicated',
      value: 1
    },
    {
      title: 'Certification',
      value: 2
    },
    {
      title: 'Re-certification',
      value: 3
    },
    {
      title: 'Re-application',
      value: 4
    }
  ]

  const admittedFrom = [
    {
      title: 'Nursing Facility',
      value: 0
    },
    {
      title: 'ICF/MR/DD',
      value: 1
    },
    {
      title: 'Home',
      value: 2
    },
    {
      title: 'Hospital',
      value: 3
    },
    {
      title: 'Other',
      value: 4
    }
  ]

    return(
        <div className='clientCard'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={() => alert("here")}
      >
        {
          formik => (
            <Form>
              <div className='clientCardInner'>
                <h4 className="text-center">SECTION II - MEMBER WAIVER ELIGIBILITY</h4>
                <div className='mt-4 scrollSectionMain'>
                  <div className='scrollSectionHeader'>
                    <h4>Eligibility Information</h4>
                  </div>
                  <div className='scrollSectionBody'>
                    <Row>
                        <Col xs={12}>
                        <FloatingLabelSelect name="program" label="Type of program applied for" data={[{id: -1,name : "-- Select Waiver --"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                        </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InlineRadioGroup 
                          primaryLabel='Type of application'
                          name="applicationtype"
                          data={applicationType}
                          inline={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <h6>Certification Period</h6>
                      <Col xs={12} md={6}>
                        <InputField
                          type="date"
                          name="certbegindate"
                          label="Begin Date"
                          max={new Date().toISOString().split('T')[0]}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <InputField
                          type="date"
                          name="certenddate"
                          label="End Date"
                          max={new Date().toISOString().split('T')[0]}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <FloatLabel
                          type="number"
                          name="certnumber"
                          label="Certification Number"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InlineRadioGroup 
                          primaryLabel='Member admitted from'
                          name="admittedfrom"
                          data={admittedFrom}
                          inline={true}
                        />
                      </Col>
                      <Col xs={12}>
                        <FloatLabel
                          type="text"
                          name="admittedfromother"
                          label="Other"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                      <InlineRadioGroup 
                          primaryLabel="Has member's freedom of choice been explained and verified by a signature on the MAP350 form?"
                          name="freedomofchoice"
                          data={yesNo}
                          inline={true}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                      <FloatLabel
                        type="text"
                        as="textarea"
                        name="freedomofchoicecomment"
                        label="Comments"
                        placeholder="Email"
                        autoComplete="offz"
                        style={{height: '100px'}}
                      />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                      <InlineRadioGroup 
                          primaryLabel="Has member been informed of the process to make a complaint?"
                          name="complaintprocess"
                          data={yesNo}
                          inline={true}
                        />
                      </Col>
                      <Col xs={12} xl={9} lg={8}>
                      <FloatLabel
                          type="text"
                          name="physicianname"
                          label="Physician's Name"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      <Col xs={12} xl={3} lg={4}>
                      <FloatLabel
                          type="tel"
                          name="physicianphone"
                          label="Phone"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      <Col xs={12}>
                      <FloatLabel
                          type="text"
                          name="licensenumber"
                          label="License Number"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      <Col xs={12}>
                      <FloatLabel
                          type="text"
                          name="memberprimarydiagnosis"
                          label="Member's primary diagnosis"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <strong>Enter all diagnosis including DMS or ICD-9 codes</strong>
                      <Col xs={12}>
                      <FloatLabel
                        type="text"
                        as="textarea"
                        name="AXISI"
                        label="AXIS I (mental illness)"
                        placeholder="Email"
                        autoComplete="offz"
                        style={{height: '100px'}}
                      />
                      </Col>
                      <Col xs={12}>
                      <FloatLabel
                        type="text"
                        as="textarea"
                        name="AXISII"
                        label="AXIS II (MR/DD)"
                        placeholder="Email"
                        autoComplete="offz"
                        style={{height: '100px'}}
                      />
                      </Col>
                      <Col xs={12}>
                      <FloatLabel
                        type="text"
                        as="textarea"
                        name="AXISIII"
                        label="AXIS III (Medical)"
                        placeholder="Email"
                        autoComplete="offz"
                        style={{height: '100px'}}
                      />
                      </Col>
                    </Row>
                    <Row>
                      <strong>Is the member diagnosed with one of the following?</strong>
                      <div className='questionItem'>
                      <Col xs={12}>
                      <FormikCheckbox 
                        label='Mental Ret....'
                        name='mentalr'
                      />
                      </Col>
                      <Col xs={6}>
                      <InputField
                          type="date"
                          name="mronsetdate"
                          label="Date of onset"
                          max={new Date().toISOString().split('T')[0]}
                        />
                        <FloatLabel
                          type="number"
                          name="memberiq"
                          label="IQ (Intelligence Quotient)"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      </div>
                      <div className='questionItem mt-2'>
                      <Col xs={12}>
                      <FormikCheckbox 
                        label='Developmental Disability'
                        name='devdisable'
                      />
                      </Col>
                      <Col xs={6}>
                      <InputField
                          type="date"
                          name="ddonsetdate"
                          label="Date of onset"
                          max={new Date().toISOString().split('T')[0]}
                        />
                      </Col>
                      </div>
                      <div className='questionItem mt-2'>
                      <Col xs={12}>
                      <FormikCheckbox 
                        label='Mental Illness'
                        name='mentalillnesscheck'
                      />
                      </Col>
                      <Col xs={6}>
                      <InputField
                          type="date"
                          name="mentalillnessonset"
                          label="Date of onset"
                          max={new Date().toISOString().split('T')[0]}
                        />
                      </Col>
                      </div>
                      <div className='mt-2'>
                      <Col xs={12}>
                      <FormikCheckbox 
                        label='Brain Injury'
                        name='braininjury'
                      />
                      </Col>
                      <Col xs={6}>
                      <InputField
                          type="date"
                          name="braininjuryonsetdate"
                          label="Date of Brain Injury"
                          max={new Date().toISOString().split('T')[0]}
                        />
                      </Col>
                      <Col xs={12}>
                      <FloatLabel
                          type="text"
                          name="braininjurycause"
                          label="Cause of Brain Injury"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      <Col xs={12}>
                      <FloatLabel
                          type="text"
                          name="ranchoscale"
                          label="Rancho Scale"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      </div>
                    </Row>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                <FloatingActionButton type="submit" variant="success">
                  <FloatingActionButton.Icon>
                    <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
                  </FloatingActionButton.Icon>
                  <FloatingActionButton.Text>
                    Save
                  </FloatingActionButton.Text>
                </FloatingActionButton>
              </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
    )
}

export default Map351IIForm;