import React, { useState, useRef, useEffect, useCallback } from 'react';
import debounce  from 'lodash.debounce';

const useScrollSpy = (sectionCount) => {
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef([...Array(sectionCount)].map(() => React.createRef()));

  const handleScroll = useCallback(() => {
    let currentActive = null;
    sectionRefs.current.forEach((ref, index) => {
      if (ref.current) { // Ensure the ref is pointing to a mounted element
        const { offsetTop, offsetHeight } = ref.current;
        if (window.scrollY + window.innerHeight / 2 >= offsetTop &&
            window.scrollY < offsetTop + offsetHeight) {
          currentActive = index;
        }
      }
    });
    setActiveSection(currentActive);
  }, []);

  useEffect(() => {
    const _debouncedHandleScroll = debounce(handleScroll,20);
    window.addEventListener('scroll', _debouncedHandleScroll);
    handleScroll(); // Initialize on mount to set the initial active section
    return () => window.removeEventListener('scroll', _debouncedHandleScroll);
  }, [handleScroll]);

  return { sectionRefs, activeSection }; };



  export default useScrollSpy;