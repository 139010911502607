import { Alert } from 'react-bootstrap';
import styles from '../styles/Alert.module.css';

const InsetAlert = ({heading,body,type,error = null,dismissible = true,...props}) => {
    const errorMessage =  error ? typeof error.error == "object" ? JSON.stringify(error.error) : error.error : null;
    return(
        <>
            <Alert className={`${styles.insetBox} ${error ? styles.insetError : styles["inset" + type]}`} dismissible={dismissible} {...props}>
                <Alert.Heading>
                    {
                        heading
                        ?
                            heading
                        :
                            error
                            ?
                                <>Error!</>
                            :
                                type === "Success" ? <>Success!</> : type === "Warn" ? <>Warning!</> : <>Error!</>
                    }
                </Alert.Heading>
                    <p>
                        {
                            error
                            ?
                                errorMessage
                                ?
                                    errorMessage
                                :
                                    <>An unkown error has occured. If this issue persists please contact support.</>
                            :
                                body
                        }
                    </p>
            </Alert>
        </>
    )
}


export default InsetAlert;