import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const initialValues = {
    loggedIn: false,
    jwt: null
};

const useUserStore = create(
  persist(
    (set, get) => ({
	  ...initialValues,
	  setLoggedIn: (value) => set({loggedIn: value}),
    logout: () => set(initialValues),
    login: (data) => set({loggedIn: true, jwt : data}),
    setJwt: (newJwt) => set({jwt : newJwt})
    }),
    {
      name: 'MFP-USER', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useUserStore; 
