import useMessageStore from "../stores/message-store";
import styles from '../styles/ActivityLog.module.css';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft} from '@fortawesome/free-solid-svg-icons';
import { useState,useEffect,useRef } from "react";
import { Toast,ToastContainer } from "react-bootstrap";
import moment from "moment";

const MessageHub = () => {
    const activities = useMessageStore(state => state.activities);
    const toasts = useMessageStore(state => state.toasts);
    const removeToast = useMessageStore(state => state.removeToast);
    const [expanded,setExpanded] = useState(false);
    const [currentDate,setCurrentDate] = useState(Date.now());
    const ref = useRef();
    const btnRef = useRef();
    const logContainerRef = useRef();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    },[]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if(ref.current && !ref.current.contains(event.target)){
                setExpanded(false);
            }
        }

        const handleKeyPress = (e) => {
            if(e.key === "Escape"){
                setExpanded(false);
                btnRef.current.blur();
            }
        }

        document.addEventListener('mousedown',handleOutsideClick);
        document.addEventListener('keydown',handleKeyPress);

        return () => {
            document.removeEventListener('mousedown',handleOutsideClick);
            document.removeEventListener('keydown',handleKeyPress);
        };
    },[ref]);

    useEffect(() => {
        if(expanded && logContainerRef.current){
            logContainerRef.current.scrollTop = 0;
        }

    },[logContainerRef,expanded])

    return(
        <>
            <ToastContainer position="top-center" className="toastcontainer">
                {toasts.map(toast => (
                    <Toast key={toast.id}
                        onClose={() => removeToast(toast.id)}
                        bg={toast.errorFlag ? "danger" : "success"}
                        delay={7000}
                        animation={true}
                        autohide={toast.autoHide}
                    >
                        <Toast.Header>
                            <strong className="me-auto">MFP Information System</strong>
                            <small>{moment(toast.timestamp).from(currentDate)}</small>
                        </Toast.Header>
                        <Toast.Body className={'text-white'}>
                            {toast.body}
                        </Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
            <div className={`${styles.activityContainer} ${expanded ? styles.expanded : ''}`} ref={ref}>
                <Button className={styles.activityLogButton} onClick={() => setExpanded(!expanded)} type='button' title='Activity Log' ref={btnRef} aria-label="Open or Close Activity Log">
                    <FontAwesomeIcon icon={faClockRotateLeft} size='xl' />
                </Button>
                <div className={styles.logContainer} ref={logContainerRef}>
                {
                    activities.length
                    ?
                        activities.map(activity => (
                            <div className={`${styles.logEntry} ${activity.errorFlag ? styles.error : styles.success}`} key={activity.id}>
                                <span className={styles.description}>
                                    {activity.body}
                                </span>
                                <span className={styles.timestamp}>
                                    {moment(activity.timestamp).format("M/D/YYYY h:mm:ss A")}
                                </span>
                            </div>
                        ))
                    :
                        <>Nothing here yet...</>
                }
                </div>
            </div>
        </>
    )
}

export default MessageHub;
