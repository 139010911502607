
const InformationCard = ({children}) => {
    return(
        <div className="clientCard">
            <div className="clientCardInner">
                {children}
            </div>
        </div>
    )
} 

InformationCard.Icon = function({children}) {
    return(
        <div className="d-flex justify-content-center">
            {children}
        </div>
    )
}

InformationCard.Header = function({children}) {
    return (
        <div className="d-flex justify-content-center">
            {children}
        </div>
    )
}

InformationCard.Body = function({children}) {
    return(
        <div>
            {children}
        </div>
    )
}

export default InformationCard;