import { Col,Row,Breadcrumb,Button,Overlay,Popover, Modal, ProgressBar, Badge } from "react-bootstrap";
import LoggedInLayout from "../../layouts/loggedin-layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { useState,useEffect } from "react";
import ButtonOverride from "../../components/buttonoverride";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import FloatLabel from "../../formik/floating-label";
import { Form, Formik} from "formik";
import InputField from "../../formik/input-field";
import useMessageStore from "../../stores/message-store";
import useApi from "../../hooks/use-api";
import UserManSkeleton from "../../components/usermanagementskeleton";
import ClientSearchListItem from "../../data/clientsearchlistitem";
import InsetAlert from "../../components/insetalert";
import ClientLandingPage from "./ClientLandingPage";
import ClientFormsPage from "./ClientFormsPage";

const ClientSearch = () => {
    const [searchParams,setSearchParams] = useState({});
    const [interimSearch,setInterimSearch] = useState({});
    const [showSearchModal,setShowSearchModal] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [popovertext,setPopoverText] = useState({heading: '',body : ''});
    const [popoverPlacement,setPopoverPlacement] = useState("bottom");
    const [target, setTarget] = useState(null);
    const [activeInput,setActiveInput] = useState('');
    const [results,setResults] = useState([]);

    const [clientEditMode,setClientEditMode] = useState(false);
    const [clientEditId,setClientEditId] = useState(null);

    const {processing, error, getRequest} = useApi();

    const addToast = useMessageStore(state => state.addToast);


    useEffect(() => {
        document.title = "MFP - Client Search";
    },[]);

    const paramConfig ={
        primary: {
            appid: {weight: 100,type: 'number',label: "Application ID"},
            medid: {weight: 100,type: 'number',label: "Medicaid ID"}
        },
        secondaryGroupings: [
            {
                fName: {weight: 20, type: 'text',label: "First Name"},
                lName: {weight: 20, type: 'text',label: "Last Name"},
                // dob: {weight: 10, type: 'date',label: "Date of birth"},
                groupWeight: 100,
                groupTitle: "Identifying Information"
            }
        ]
    }

    const requiredWeight = 100; //The required weight threshold for a search to commence

    const getInitialParams = () => {
        const initialParams = {};

        Object.keys(paramConfig.primary).forEach(key => {
            initialParams[key] = '';
        });

        paramConfig.secondaryGroupings.forEach(group => {
            Object.keys(group).filter(key => key !== 'groupWeight' && key !== 'groupTitle').forEach(key => {
                initialParams[key] = '';
            });
        });

        return initialParams;
    }

    useEffect(() => {

        const initialParams = getInitialParams();

        setSearchParams(initialParams);
        setInterimSearch(initialParams);
    },[]);

    const extractSuppliedParams = (params) => {
        const filteredParams = Object.entries(params).reduce((acc,[key,value]) => {
            if(value){
                acc[key] = value;
            }

            return acc;
        },{});

        return filteredParams;
    };

    useEffect(() => {
        const initialParams = getInitialParams();
        const searchJSON = JSON.stringify(searchParams);
        const initialJSON = JSON.stringify(initialParams);
        if((searchJSON !== initialJSON) && (searchJSON !== '{}' )){
            const get = async () => {
                const dataToSend = extractSuppliedParams(searchParams);
                const [data, status] = await getRequest("clientsearch", {timestamp: Date.now(),...dataToSend}); 
                if (status === 200) {
                    setResults(data);
                    addToast(
                        {
                            id: Math.random(),
                            body: "The search was executed successfully!",
                            autoHide: true,
                            errorFlag: false,
                            timestamp: Date.now() - 1000
                        }
                    );
                }
                else{
                    setResults([]);
                }
            };
    
            get();
        }
    },[searchParams]);

    const renderInputFields = () => {
        const inputFields = [];

        for(const key in paramConfig.primary){
            if(paramConfig.primary[key].type === 'date'){
                inputFields.push(
                    <InputField
                        key={key}
                        type={paramConfig.primary[key].type}
                        name={key}
                        label={paramConfig.primary[key].label}
                        max={new Date().toISOString().split('T')[0]}
                        onChange={(e) => handleInputChange(e)}
                        autoFocus={activeInput === key}
                    />
                )
            }
            else{
                inputFields.push(
                    <FloatLabel 
                        key={key}
                        type={paramConfig.primary[key].type}
                        name={key}
                        label={paramConfig.primary[key].label}
                        placeholder={paramConfig.primary[key].label}
                        autoComplete="offz"
                        onChange={(e) => handleInputChange(e)}
                        autoFocus={activeInput === key}
                    />
                )
            }
        }

        paramConfig.secondaryGroupings.forEach(group => {
            Object.keys(group).filter(key => key !== 'groupWeight' && key !== 'groupTitle').forEach(key => {
                if(group[key].type === 'date'){
                    inputFields.push(
                        <InputField
                            key={key}
                            type={group[key].type}
                            name={key}
                            label={group[key].label}
                            max={new Date().toISOString().split('T')[0]}
                            onChange={(e) => handleInputChange(e)}
                            autoFocus={activeInput === key}
                        />
                    )
                }
                else{
                    inputFields.push(
                        <FloatLabel 
                            key={key}
                            type={group[key].type}
                            name={key}
                            label={group[key].label}
                            placeholder={group[key].label}
                            autoComplete="offz"
                            onChange={(e) => handleInputChange(e)}
                            autoFocus={activeInput === key}
                        />
                    )
                }
            });
        });

        // return inputFields;
        return(
            <search>
                {inputFields}
            </search>
        )
    }

    const calculateSearchParamWeights = (params) => {
        let totalWeight = 0;

        for (const key in paramConfig.primary){
            if(params[key]){
                totalWeight += paramConfig.primary[key].weight;
            }
        }

        paramConfig.secondaryGroupings.forEach(group => {
            const groupKeys = Object.keys(group).filter(key => key !== 'groupWeight' && key !== 'groupTitle');
            const groupWeight = groupKeys.every(key => params[key]) ? group.groupWeight : 0;
            const individualWeights = groupKeys.reduce((total,key) => total + (params[key] ? group[key].weight : 0),0);

            totalWeight += Math.max(groupWeight,individualWeights);
        });

        return totalWeight;
    }
    

    const currentWeight = calculateSearchParamWeights(interimSearch);

    const isFilled = (paramKey) => {
        return Boolean(interimSearch[paramKey]);
    }

    const buildWeightsPopover = () => {
        return (
            <div className="popbod">
                <small className="d-flex justify-content-end">{calculateSearchParamWeights(interimSearch)} / {requiredWeight}</small>
                {
                    Object.entries(paramConfig.primary).map(([name,param]) => (
                        <div className="group-section mb-3" key={name}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    {
                                        isFilled(name) 
                                        ?
                                            <span><FontAwesomeIcon icon={faCheck} style={{color: '#28a745'}}/></span>
                                        :
                                            <span><FontAwesomeIcon icon={faXmark} style={{color: '#dc3545'}} /></span>
                                    }
                                    <strong className="fw-bold">{param.label}</strong>
                                </div>
                                <strong>{param.weight}</strong>
                            </div>
                        </div>
                    ))
                }
                {
                    paramConfig.secondaryGroupings.map((group,index) => (
                        <div className="group-section mb-3" key={index}>
                            <div className="d-flex justify-content-between align-items-center">
                                <strong>{group.groupTitle}</strong>
                                <strong>{group.groupWeight}</strong>
                            </div>
                            {
                                Object.keys(group).filter(key => key !== 'groupTitle' && key !== 'groupWeight').map(paramName => (
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {
                                                isFilled(paramName)
                                                ?
                                                    <span><FontAwesomeIcon icon={faCheck} style={{color: '#28a745'}}/></span>
                                                :
                                                    <span><FontAwesomeIcon icon={faXmark} style={{color: '#dc3545'}} /></span>
                                            }
                                            <small className="fw-bold">{group[paramName].label}</small>
                                        </div>
                                        <small className="fw-bold">{group[paramName].weight}</small>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    const togglePopover = (e,heading,body,placement = "bottom") => {
        setPopoverPlacement(placement);
        if(showPopover){
            setShowPopover(false);
            return;
        }
        else{
            setTarget(e.target);
            setPopoverText({heading: heading, body: body});
            setShowPopover(true);
        }
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setInterimSearch(prev => ({...prev,[name] : value}));
    }

    const handleSearchUpdate = async (values) => {
        if(currentWeight < requiredWeight){
            alert("Can not perform search. Please double check the weights.");
            return;
        }
        const searchJSON = JSON.stringify(searchParams);
        const valuesJSON = JSON.stringify(values);
        if(searchJSON !== valuesJSON){
            setSearchParams(values);
        }
        setShowSearchModal(false);
        setInterimSearch(getInitialParams());
    }


    const findLabelForParams = (paramName,config) => {
        if(config.primary[paramName]){
            return config.primary[paramName].label;
        }

        for(const group of config.secondaryGroupings){
            if(group[paramName]){
                return group[paramName].label;
            }
        }

        return paramName;
    }

    return(
        clientEditMode
        ?
            <ClientLandingPage clientID={clientEditId} returnCallback={() => {setClientEditId(null); setClientEditMode(false);}}/>
            // <ClientFormsPage clientID={clientEditId} returnCallback={() => {setClientEditId(null); setClientEditMode(false);}} />
        :
        <>
        <Overlay
                show={showPopover}
                target={target}
                placement={popoverPlacement}
                containerPadding={20}
                rootClose
                onHide={() => setShowPopover(false)}
                >
                <Popover id="popover-contained">
                    <Popover.Header as="h3">{popovertext.heading}</Popover.Header>
                    <Popover.Body>
                        {popovertext.body}
                    </Popover.Body>
                </Popover>
            </Overlay>
            <LoggedInLayout>
            <div style={{padding: '25px'}}>
                <Row className="mb-4">
                    <Col xs={12}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h3 className="me-3">Client Search</h3>
                                <Breadcrumb>
                                    <Breadcrumb.Item className="primarybreadcrumb">Client</Breadcrumb.Item>
                                    <Breadcrumb.Item className="secondarybreadcrumb" active>Client Search</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div>
                                <Button variant="success" className="driverBtn" onClick={() => {setActiveInput(''); setShowSearchModal(true); setInterimSearch(getInitialParams());}} ><span className="driverBtnIcon"><FontAwesomeIcon icon={faMagnifyingGlass} size="lg"/></span><span className="driverBtnText">Search</span></Button>
                            </div>
                        </div>
                        <div className="filteroptions">
                            <div className="filterparent">
                                <div className="filteritems">
                                    <div className="searchpane">
                                        <div>
                                            <div className="d-flex align-items-center">Search Parameters <ButtonOverride className="searchinfo" onClick={(e) => togglePopover(e,"Search Parameters","The tags below are the supplied search parameters.")}><FontAwesomeIcon icon={faQuestionCircle} /></ButtonOverride></div>
                                        </div>
                                        <div className="search-tags mt-1">
                                            {
                                                Object.entries(searchParams).map(([name, value]) => {
                                                    if(value){
                                                        const label = findLabelForParams(name,paramConfig);
                                                        return(
                                                            <ButtonOverride key={name} type="button" className="searchtag" onClick={() => {setInterimSearch(searchParams); setShowSearchModal(true); setActiveInput(name); }}>
                                                                <span className="d-flex align-items-center">
                                                                    <span className="slabel me-1">{label}</span> : <span className="svalue ms-1">{value}</span>
                                                                </span>
                                                            </ButtonOverride>
                                                        )
                                                    }
                                                    else{
                                                        return(
                                                            null
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="seperator mb-2"></div>
                {
                    processing
                    ?
                        <UserManSkeleton />
                    :
                        <Row className="mt-4">
                            <Col xs={12}>
                                {
                                    error
                                    ?
                                        <div className="d-flex justify-content-center mb-2">
                                            <InsetAlert error={error} dismissible={false} />
                                        </div>
                                    :
                                        results.map((_,index) => (
                                            <ClientSearchListItem key={index} clientInfo={{clientid: 10,fName: "John", lName: "Doe",dob: '12/1/1990'}} clickActionCallback={() => {setClientEditId(10); setClientEditMode(true)}}/>

                                        ))
                                }
                            </Col>
                        </Row>
                }
            </div>
        </LoggedInLayout>
        <Formik
            initialValues={interimSearch}
            enableReinitialize={true}
            onSubmit={handleSearchUpdate}
        >
            {
                formik => 
                    <Modal show={showSearchModal} onHide={() => {setShowSearchModal(false);}}>
                        <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                renderInputFields()
                            }
                            <ProgressBar style={{cursor: 'pointer'}} variant="primary" now={currentWeight} label="More details needed" visuallyHidden onClick={(e) => togglePopover(e,"Search Progress",buildWeightsPopover(),"top")} />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-cotent-end">
                                <Button variant="primary" type="submit" disabled={currentWeight < requiredWeight}>Search</Button>
                            </div>
                        </Modal.Footer>
                        </Form>
                    </Modal>
            }
        </Formik>
        </>
    )
}

export default ClientSearch;