import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import { useEffect } from 'react';


const ControlledFloatLabel = ({label,valueChangeCallback,...props}) => {
    const [field, meta] = useField(props);
    const safeId = label.replace(/[^\w]/gi, "").substring(0, 9);

    useEffect(() => {
        valueChangeCallback(field.value);
    },[field.value])

  return (
    <>
    <FloatingLabel
        controlId={`input-${safeId}`}
        label= {label}
        className='mb-3'
      >
        <Form.Control
                {...field}
                {...props}
                isInvalid={meta.touched && meta.error}
            />
            <Form.Control.Feedback type="invalid">
                {meta.error}
            </Form.Control.Feedback>
      </FloatingLabel>
    </>
      
    
  );
}

export default ControlledFloatLabel;