import { faMultiply, faSearch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonOverride from "./buttonoverride";

const NonExpandableSearchBox = ({searchTerm,setSearchTerm,onChange,style = null,...props}) => {
    const handleClear = () => { 
        setSearchTerm('');
    };

    const handleEscapeKey = (e) => {
        e.preventDefault();
        handleClear();
    };

    return(
        <div className={`NonExpSearchContainer ${props.disabled ? 'opacitydisabled' : ''}`}>
            <input type="text" {...props} autoComplete="off" className={`NonExpSearchInput ${props.disabled ? 'opacitydisabled' : ''}`} style={style ? style : {color: "#8f8f8f"}} onChange={(e) => onChange(e)} onKeyDown={(e) => {e.key === "Escape" && handleEscapeKey(e)}} />
            {
                searchTerm === ''
                ?
                    <FontAwesomeIcon className="NonExpSearchIcon" icon={faSearch} style={{color: '#8f8f8f'}} />
                :
                    <ButtonOverride type="button" title="Clear Search" aria-label="Clear Search" style={{padding: "0",width: "unset"}} onClick={() => setSearchTerm('')}>
                        <FontAwesomeIcon className="NonExpSearchIcon hasAction" icon={faMultiply} style={style ? style : {color: "#8f8f8f"}}  />
                    </ButtonOverride>
            }
        </div>
    )
}

export default NonExpandableSearchBox;