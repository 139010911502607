import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import useUserStore from '../stores/user-store';
import { useState,useEffect } from 'react';
import { Card,} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import useApi from '../hooks/use-api';
import FloatLabel from '../formik/floating-label';
import LegalWarning from '../components/legalwarning';
import InsetAlert from '../components/insetalert';
import DotLoader from '../components/dotloader';

function Login({showRecoverPassword}){

    useEffect(() => {
        document.title = "MFP - Login";
    },[]);


    const {processing, error, postRequest} = useApi();
    const navigate = useNavigate();
    const [showLoginFailure, setShowLoginFailure] = useState(false);

    const initialValues = {
        Username: "",
        Password: ""
    };

    const validationSchema = Yup.object({
        Username: Yup.string().required("Required"),
        Password: Yup.string().required("Required")
    });

    const LogInFunction = useUserStore((state) => state.login);
    const handleSubmit = async (values, { setSubmitting }) => {
        const [data, status] = await postRequest("login", values);
        if (status !== 200) {
            setShowLoginFailure(true);
        } else {
            LogInFunction(data.jwtToken);
            navigate("/");
        }
    };

    return(
        <>
            <Row>
                <Col xs={12}>
                    <LegalWarning />
                    <Card>
                        <Card.Img variant="top" src='../mfpbanner.png' alt="Money Follows The Person Banner"/>
                        <Card.Body>
                            <h3>MFP Login</h3>
                            
                                <>
                                {
                                showLoginFailure && error
                                ?
                                    <InsetAlert error={error} heading={"Login Failed"} style={{width: '100%'}}/>
                                :   
                                null}
                                </>
                            
                            

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {formik => 
                                    <>
                                        <Form>
                                            <FloatLabel 
                                                label="Username" 
                                                type="text"
                                                name="Username"
                                                placeholder="Username"
                                                autoComplete="Username"
                                                disabled = {formik.isSubmitting}
                                            />
                                            <FloatLabel 
                                                label="Password" 
                                                type="password"
                                                name="Password"
                                                placeholder="Password"
                                                disabled = {formik.isSubmitting}
                                            />
                                            <div className='d-flex justify-content-between align-items-center'>
                                                    <Button variant='link' className='ps-0' disabled={formik.isSubmitting} onClick={showRecoverPassword}>Forgot Password?</Button>
                                                    <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                                                        {formik.isSubmitting ? 
                                                        <>
                                                        <DotLoader ButtonStyle={true} />
                                                      </> : <>Login</>}
                                                    </Button>
                                            </div>
                                        </Form>
                                    </>
                            }
                        </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default Login;