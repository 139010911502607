// import styles from "../styles/userlist.module.css";
import styles from '../styles/Datalist.module.css';

const UserListItem = ({user,modalCallback}) => {

    let statusClass;

    switch(user.status){
        case "Invited":
            statusClass = "badge bg-warning d-inline";
            break;
        case "Confirmed":
            statusClass = "badge bg-success d-inline";
            break;
        case "Disabled":
            statusClass = "badge bg-danger d-inline";
            break;
        default:
            statusClass = "badge bg-warning d-inline";
    }

    return(
        // <div className={`${styles["user-card"]} mb-3`} onClick={() => modalCallback()} onKeyDown={(e) => {if(e.key === "Enter") modalCallback()}} tabIndex={0} >
        //     <div className={styles["datapoint"]}>
        //             <div className={styles["datalabel"]}>
        //                 Username
        //             </div>
        //             <div>
        //                 {user.username}
        //             </div>
        //     </div>
        //     <div className={styles["datapoint"]}>
        //             <div className={styles["datalabel"]}>
        //                 Name
        //             </div>
        //             <div>
        //                 {user.name}
        //             </div>
        //     </div>
        //     <div className={styles["datapoint"]}>
        //             <div className={styles["datalabel"]}>
        //                 Email
        //             </div>
        //             <div>
        //                 {user.email}
        //             </div>
        //     </div>
        //     <div className={styles["datapoint"]}>
        //             <div className={styles["datalabel"]}>
        //                 Last Active
        //             </div>
        //             <div>
        //                 {user.lastactive}
        //             </div>
        //     </div>
        //     <div className={styles["datapoint"]}>
        //             <div className={styles["datalabel"]}>
        //                 Status
        //             </div>
        //             <div className={styles["finalcol"]}>
        //                 <span className={statusClass}>{user.status}</span>
        //             </div>
        //     </div>
        // </div>
        <button className={`${styles["data-card"]} ${styles["fiveCol"]} ${styles["colMark"]} mb-3`} onClick={() => modalCallback()} type='button' aria-label={`Edit User : ${user.username}`}>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Username
                </span>
                <span className={styles["datacol"]}>
                    {user.username}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Name
                </span>
                <span className={styles["datacol"]}>
                    {user.name}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Email
                </span>
                <span className={styles["datacol"]}>
                    {user.email}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Last Active
                </span>
                <span className={styles["datacol"]}>
                    {user.lastactive}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Status
                </span>
                <span className={`${styles["finalcol"]} ${styles["datacol"]}`}>
                    <span className={statusClass}>{user.status}</span>
                </span>
            </span>
        </button>
    )
}

export default UserListItem;

{/* <div className={`${styles["user-card"]} mb-3`} onClick={() => handleClick()} tabIndex={0} onKeyDown={(e) => {if(e.key === "Enter") modalCallback()}} ref={ref}></div> */}