import { Accordion, Badge, Button, Card, Col, Row, Table,ListGroup } from "react-bootstrap";
import LoggedInLayout from "../../layouts/loggedin-layout";
import styles from '../../styles/offcanvasnav.module.css';
import ButtonOverride from "../../components/buttonoverride";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import FloatingActionButton from "../../components/floatingactionbutton";
import ApplicationListItem from "../../components/applicationlistitem";
import { useState } from "react";
import ClientFormsPage from "./ClientFormsPage";

const ClientLandingPage = ({clientID,returnCallback}) => {
    const [clientEditMode,setClientEditMode] = useState(null);

    const _homeCallback = () => {
        setClientEditMode(null);
    }

    return(
        clientEditMode
        ?
            <ClientFormsPage applicationID={clientEditMode} returnCallback={returnCallback} homeCallback={_homeCallback}/>
        :
        <LoggedInLayout>
            <div style={{padding: '25px'}}>
                <Row>
                    <Col xs={12} lg={3}>
                    <div className="clientInformation">
                            <h3 className="clientInformationTitle">
                                Client Information
                            </h3>
                            
                            <div className="infoItemBox">
                                <h5>Name</h5>
                                <p className="infoItemDataBody">John Doe</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>County of Residence</h5>
                                <p className="infoItemDataBody">Fayette</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Gender</h5>
                                <p className="infoItemDataBody">Male</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Medicaid #</h5>
                                <p className="infoItemDataBody">1348654431</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Date of Birth</h5>
                                <p className="infoItemDataBody">12/1/1967</p>
                            </div>
                            <div className="infoItemBox">
                                <h5>Login Name</h5>
                                <p className="infoItemDataBody">Bradley Tyler</p>
                            </div>
                            <Button type="button" variant="link" className="p-0" style={{textDecoration: 'none',fontSize: '0.9em'}} onClick={() => returnCallback()}>Return to Client Search</Button>
                        </div>
                    </Col>
                    <Col xs={12} lg={9}>
                        <div className="clientCard">
                            <div className="clientCardInner">
                                <div className="d-flex justify-content-between applicationHeader">
                                    <h3 className="clientInformationTitle">Applications</h3>
                                    <FloatingActionButton type="button" variant="success">
                                        <FloatingActionButton.Icon>
                                            <FontAwesomeIcon icon={faPlus} size="lg" />
                                        </FloatingActionButton.Icon>
                                        <FloatingActionButton.Text>
                                            Add Application
                                        </FloatingActionButton.Text>
                                    </FloatingActionButton>
                                </div>
                                <ApplicationListItem 
                                    appID={44513}
                                    status='inprogress'
                                    statusText="In Progress"
                                    creationDate='12/12/2012 11:33:21 PM'
                                    lastActiveDate='12/14/2012 12:34:11 AM'
                                    selectCallback={() => setClientEditMode(44513)}
                                />
                                <ApplicationListItem 
                                    appID={32345}
                                    status='denied'
                                    statusText="Individual's health needs exceed capacity of program."
                                    creationDate='12/12/2011 11:33:21 PM'
                                    lastActiveDate='12/14/2011 12:34:11 AM'
                                    selectCallback={() => setClientEditMode(32345)}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </LoggedInLayout>
    )
}

  

export default ClientLandingPage;