import styles from '../styles/Loader.module.css';

const DotLoader = ({ButtonStyle = false}) => {
    return(
        <div className={ButtonStyle ? styles.bouncingLoaderbtn : styles.bouncingLoader}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default DotLoader;