import { useEffect, useState, useRef } from "react";
import { Modal,Button } from "react-bootstrap";
import ModalSkeleton from "../components/modalskeleton";
import { Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import FloatingAlert from "../components/floatingalert";
import DotLoader from "../components/dotloader";
import { useModalStatusContext } from "../contexts/modalstatuscontext";
import ButtonOverride from "../components/buttonoverride";

const BaseModal = ({modalShown,closeModal,loadData,submitData,objectID,objectType,ModalTitle,setData,processing,error,initialValues,setSubmitHandler,formikProps,modalLocked,popoverHover,popoverLeave,setModalLocked,actionCompleteCallback,focusLock = false,children,...props}) => {
    const [actionState,setActionState] = useState(null);
    const [expandedErrorLog,setExpandedErrorLog] = useState(false);
    const sessionIDRef = useRef(null);
    const triggerFloatAlertBtnRef = useRef();
    const {HasError,isLoading,setLoading,handleError,resetContext} = useModalStatusContext();

    useEffect(() => {
        const newSessionID = Date.now();
        sessionIDRef.current = newSessionID;
        if(modalShown){
            if(objectID){
                setActionState('loadData');
                setLoading(true);
                const GetterFunction = async () => {
                    const currentSession = sessionIDRef.current;
                    const [data,status] = await loadData();
                    if(status === 200){
                        if(currentSession === sessionIDRef.current){
                            setLoading(false);
                            setData(data);
                            setActionState(null);
                        }
                    }
                    else{
                        if(currentSession === sessionIDRef.current){
                            setLoading(false);
                            setData(initialValues);
                            setModalLocked(true);
                            setActionState('loadFail');
                        }
                    }
                }
    
                GetterFunction();
            }
            else{
                setData(initialValues);
            }
        }
    },[modalShown,objectID]);

    useEffect(() => {
        if(error){
            handleError(error);
        }
    },[error]);

    const handleSubmitAction = async(values) => {
        setExpandedErrorLog(false);
        setActionState('sending');
        const SendRequest = async () => {
            const currentID = sessionIDRef.current;
            const [data,status] = await submitData(values);
            if(status === 200){
                if(currentID === sessionIDRef.current){
                    setActionState('sendSuccess');
                }
                actionCompleteCallback(
                    {
                        ts: new Date().getTime(),
                        sessionExpired: !(currentID === sessionIDRef.current),
                        method: objectID ? 'update' : 'add',
                        error: false
                    }
                )
            }
            else{
                if(currentID === sessionIDRef.current){
                    setActionState('sendFail');
                }
                actionCompleteCallback(
                    {
                        ts: new Date().getTime(),
                        sessionExpired: !(currentID === sessionIDRef.current),
                        method: objectID ? 'update' : 'add',
                        error: true
                    }
                )
            }
        }
        return SendRequest();
    };

    useEffect(() => {
        setSubmitHandler(handleSubmitAction);
    },[setSubmitHandler]);

    const ExitFunction = () => {
        setActionState(null);
        setData(initialValues);
        setModalLocked(false);
        setExpandedErrorLog(false);
        formikProps.resetForm();
        resetContext();
    }

    return(
        <Modal show={modalShown} onHide={() => closeModal()} onExited={() => ExitFunction()} {...props}> 
            <Form autoComplete="off">
            {
                    actionState === 'loadData'
                    ?
                        <ModalSkeleton />
                    :
                        <>
                            <Modal.Header>
                                <Modal.Title>{ModalTitle}</Modal.Title>
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => closeModal()} tabIndex={focusLock && -1}></button>
                            </Modal.Header>
                            <Modal.Body>
                                    {
                                        actionState === 'sending' || actionState === 'sendSuccess' || actionState === 'sendFail' || actionState === 'loadFail' || HasError
                                        ?
                                            <FloatingAlert triggerBtnRef={triggerFloatAlertBtnRef} processing={processing} expandedLog={expandedErrorLog} closeExpandedErrorLog={() => setExpandedErrorLog(false)} error={error}  objectType={objectType} action={objectID ? 'update' : 'add'}/>
                                        :
                                            null
                                    }
                                    {children}
                            </Modal.Body>
                            <Modal.Footer className="justify-content-between align-items-center">
                                <Button variant="danger" onClick={() => closeModal()} tabIndex={focusLock && -1}>
                                    Close
                                </Button>
                                <div className="d-flex align-items-center">
                                    {
                                        actionState === 'sendSuccess' || actionState === 'sendFail' || actionState === 'loadFail' || HasError
                                        ?
                                            <div ref={triggerFloatAlertBtnRef}>
                                                {
                                                  HasError
                                                  ?
                                                      <ButtonOverride className={"ErrorStatusButton me-1"} type="button" title="View status log." aria-label="View status log." aria-pressed={expandedErrorLog} onClick={() => setExpandedErrorLog(!expandedErrorLog)} >
                                                          <FontAwesomeIcon icon={faCircleXmark} size="xl" style={{color: "red"}}/>
                                                      </ButtonOverride>
                                                  :
                                                      <ButtonOverride className={"ErrorStatusButton me-1"} type="button" title="View status log." aria-label="View status log." aria-pressed={expandedErrorLog} onClick={() => setExpandedErrorLog(!expandedErrorLog)}>
                                                          <FontAwesomeIcon icon={faCheckCircle} size="xl" style={{color: "green"}}/>
                                                      </ButtonOverride>
                                                }
                                            </div>
                                        :
                                            null
                                    } 
                                    <Button variant="primary" type="submit" disabled={HasError || isLoading || formikProps.isSubmitting || (objectID ? !formikProps.dirty : false)} tabIndex={focusLock && -1}>
                                        {
                                            objectID 
                                            ?
                                                formikProps.isSubmitting
                                                ?
                                                    <DotLoader ButtonStyle={true} />
                                                :
                                                <>Save Changes</>
                                            :
                                                formikProps.isSubmitting
                                                ?
                                                    <DotLoader ButtonStyle={true} />
                                                :
                                                <>Submit</>
                                                
                                        }
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </>
                }
            </Form>
        </Modal>
    )

}

export default BaseModal;