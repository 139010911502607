import styles from '../styles/Datalist.module.css';

const ClientSearchListItem = ({clientInfo,clickActionCallback}) => {
    return(
        <button className={`${styles["data-card"]} ${styles["fiveCol"]} ${styles["colMark"]} mb-3`} onClick={() => clickActionCallback()} type='button' aria-label={`Open Form For : ${clientInfo.lName}, ${clientInfo.fName}`}>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Client ID
                </span>
                <span className={styles["datacol"]}>
                    {clientInfo.clientid}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    First Name
                </span>
                <span className={styles["datacol"]}>
                    {clientInfo.fName}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Last Name
                </span>
                <span className={styles["datacol"]}>
                    {clientInfo.lName}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Other Stuff...
                </span>
                <span className={styles["datacol"]}>
                    placeholder...
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Other Stuff...
                </span>
                <span className={`${styles["finalcol"]} ${styles["datacol"]}`}>
                    placeholder...
                </span>
            </span>
        </button>
    )
}

export default ClientSearchListItem;