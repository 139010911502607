import { Row,Col } from "react-bootstrap";
import LoggedInLayout from "../../layouts/loggedin-layout";
import { useEffect } from "react";

const UnderConstruction = () => {

    useEffect(() => {
        document.title = "MFP - Under Construction";
    },[]);


    return(
        <LoggedInLayout>
            <Row>
                <Col xs={12}>
                    <div className="text-center">
                        <h1 style={{color: '#4CAF50'}}>Under Construction</h1>
                        <p style={{color: '#333'}}>
                            This page is currently under construction. Please check back soon!
                        </p>
                    </div>
                </Col>
            </Row>
        </LoggedInLayout>
    )
}

export default UnderConstruction;