import { Form, Formik } from 'formik';
import React from 'react';
import { Col,FloatingLabel,Row } from 'react-bootstrap';
import FloatingLabelSelect from '../../../formik/floating-label-select';
import FloatLabel from '../../../formik/floating-label';
import InlineRadioGroup from '../../../formik/inlineradiogroup';
import FloatingActionButton from '../../../components/floatingactionbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

const Map351IForm = () => {

  const initialValues = {

  }

  const yesNo = [
    {
        title: 'Yes',
        value: 1
    },
    {
        title: 'No',
        value: 0
    }
]

  
  return (
    <div className='clientCard'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={() => alert("here")}
      >
        {
          formik => (
            <Form>
              <div className='clientCardInner'>
                <h4 className="text-center">SECTION I - MEMBER DEMOGRAPHICS</h4>
                <div className='mt-4 scrollSectionMain'>
                  <div className='scrollSectionHeader'>
                    <h4>Client Information</h4>
                  </div>
                  <div className='scrollSectionBody'>
                  <Row>
                    <Col xs={12} md={6}>
                      <strong>Date of Birth: </strong> 6/7/1972
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Gender: </strong>Female
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} lg={6} xl={3}>
                      <FloatingLabelSelect name="clientstate" label="State" data={[{id: -1,name : "Select a state"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                    </Col>
                    <Col xs={12} lg={6} xl={3}>
                      <FloatingLabelSelect name="clientcounty" label="County" data={[{id: -1,name : "Select a county"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                    </Col>
                    <Col xs={12} lg={6} xl={3}>
                      <FloatingLabelSelect name="clientzip" label="Zip" data={[{id: -1,name : "Select a zip"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} />
                    </Col>
                    <Col xs={12} lg={6} xl={3}>
                      <FloatLabel
                        type="text"
                        name="clientcity"
                        label="City"
                        placeholder="Email"
                        autoComplete="offz"
                      />
                    </Col>
                    <Col xs={12} lg={6} xl={3}>
                      <FloatLabel
                        type="tel"
                        name="tdphone"
                        label="Client Phone"
                        placeholder="fdsfsfd"
                        autoComplete="offz"
                      />
                    </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <InlineRadioGroup
                          name="readwrite"
                          primaryLabel="Is member able to read and write?"
                          data={yesNo}
                        />
                      </Col>
                    </Row>
                    <Row>
                    <Col xs={12} md={4}>
                        <FloatLabel
                          type="number"
                          name="clientheight"
                          label="Client Height (inches)"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <FloatLabel
                          type="number"
                          name="clientweight"
                          label="Client Weight (pounds)"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <strong>Emergency Contact Information</strong>
                      <Col xs={12} xl={4}>
                      <FloatLabel
                          type="text"
                          name="clientemergencyfirstname"
                          label="First Name"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      <Col xs={12} xl={4} >
                      <FloatLabel
                          type="number"
                          name="clientemergencylastname"
                          label="Last Name"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                      <Col xs={12} xl={4}>
                      <FloatLabel
                          type="tel"
                          name="clientemergencytelephone"
                          label="Phone"
                          placeholder="Email"
                          autoComplete="offz"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                <FloatingActionButton type="submit" variant="success">
                  <FloatingActionButton.Icon>
                    <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
                  </FloatingActionButton.Icon>
                  <FloatingActionButton.Text>
                    Save
                  </FloatingActionButton.Text>
                </FloatingActionButton>
              </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  )
}

export default Map351IForm;