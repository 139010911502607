import styles from '../styles/bubbleselection.module.css';
import emailStyles from '../styles/emaillistitem.module.css';
import ButtonOverride from './buttonoverride';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCheck, faMultiply,faSquareNfi,faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState,useRef, useEffect } from 'react';
import SelectComponent from './selectcomponent';
import useApi from '../hooks/use-api';
import { useModalStatusContext } from '../contexts/modalstatuscontext';
import GapButtonSkeleton from './gapbuttonskeleton';
import InsetAlert from './insetalert';
import { Form, useField } from 'formik';
import SelectionComponentTest from './selectnew/selectcomponenttest';

const EmailEventSelection = ({selectedEventIds,fieldName,focusLock = false,lockFocusFunction,setCurrentEvents}) => {
    const [field,meta,helpers] = useField(fieldName);
    const [editMode,setEditMode] = useState(false);
    const editWindowRef = useRef(null);
    const [events,setEvents] = useState([]);
    const [displayItems,setDisplayItems] = useState([]);
    const {processing, error, getRequest} = useApi();
    const [fieldExtracted,setFieldExtracted] = useState();
    const {setLoading,handleError,statusErrors} = useModalStatusContext();
    

    const [pendingSelection,setPendingSelection] = useState([]);

    useEffect(() => {
        // setFieldExtracted(_translateData(field.value));

        setPendingSelection(field.value);
        let displayItemList = [];

        field.value.map((eventID) => {
            if(events.length){
                const info = events.find(event => event.id === eventID);

                displayItemList.push({id: eventID, title: info.name});
            }
        });

        setDisplayItems(displayItemList);
    },[field.value,events])

    // useEffect(() => {
    //     console.log(fieldExtracted);
    // },[fieldExtracted])

    // const trapFocus = (event) => {
    //     if(!editMode) return;
    //     if(event.key !== 'Tab') return;

    //     const focusableElements = Array.from(editWindowRef.current.querySelectorAll('input, button, textarea'));

    //     const currentFocusIndex = focusableElements.findIndex(element => element === document.activeElement);
    //     let nextFocusIndex = 0;

    //     if(event.shiftKey){
    //         nextFocusIndex = currentFocusIndex <= 0 ? focusableElements.length - 1 : currentFocusIndex - 1;
    //     }
    //     else{
    //         nextFocusIndex = currentFocusIndex >= focusableElements.length - 1 ? 0 : currentFocusIndex + 1;
    //     }

    //     focusableElements[nextFocusIndex].focus();
    //     event.preventDefault();
    // };

    useEffect(() => {
        setLoading(true);
        const get = async () => {
            const [data, status] = await getRequest('events', {timestamp: Date.now()}); 
            if (status === 200) {
                setEvents(data);
                setLoading(false);
            }
            else{
                setEvents([]);
                setLoading(false);
            }
        };

        get();
    },[]);

    const _handleCloseClick = () => {
        setEditMode(false);
        setFieldExtracted(_translateData(field.value));
    }

    const _handleSaveClick = (items) => {
        const displayItems = items.map((eventID) => {
            const info = events.find(event => event.id === eventID);

            return {id: eventID, title: info.name};
        })

        setCurrentEvents(items);
        helpers.setValue(items);
        setDisplayItems(displayItems);
        setEditMode(false);
    }

    const _handleEditOpen = () => {
        if(!meta.touched){
            helpers.setTouched(true);
        }
        setEditMode(true);
    }

    useEffect(() => {
        // const currentFocusElement = editWindowRef.current;
        // currentFocusElement.addEventListener('keydown',trapFocus);

        // return () => {
        //     currentFocusElement.removeEventListener('keydown',trapFocus);
        // };
        if(editMode){
            lockFocusFunction(true);
        }
        else{
            lockFocusFunction(false);
        }
    },[editMode]);

    useEffect(() => {
        if(error){
            handleError(error);
        }
    },[error])

    const _dataExtractFunction = (data) => {
        const dataSet = [
            {
                id: 'events',
                items: data?.map((item) => ({
                    id: item.id,
                    name: item.name
                }))
            }
        ]

        return dataSet;
    }

    const _translateData = (fieldValues) => {
        const dataSet = new Set(fieldValues.map(id => `events:${id}`));
        
        let newSelections = {
            items: dataSet, 
            actions: new Map(),
        }

        return newSelections;
    }

    const _translateMapping = (values) => {
        let result = [];

        values.items.forEach(key => {
            const id = parseInt(key.split(':')[1]);

            result.push(id);
        })

        return result.sort((a,b) => a - b);
    }

    return(
        editMode
            ?
                <>
                    <div className={styles.overlay}></div>
                    <div className={styles.innerOverlay}></div>
                    <div className={`${styles.primaryContainer} ${styles.editmode}`} ref={editWindowRef}>
                        <div className={`${styles.header} ${editMode ? styles.editmode : styles.noneditmode}`}>
                            <strong>Edit Events</strong>
                        </div>
                        <SelectionComponentTest
                            searchboxid={"searchRecipients"}
                            data={() => _dataExtractFunction(events)}
                            objectName={"Recipients"}
                            disabled={false}
                            ignoreOutsideClickDataAttribute={"data-override-event"}
                            field={_translateData(pendingSelection)}
                            fieldSetter={(items) => setPendingSelection(_translateMapping(items))}
                        />
                        
                        <div className={styles.footer}>
                            <ButtonOverride className={styles.actionBtn} type="button" onClick={_handleCloseClick} data-override-event="true">
                                <FontAwesomeIcon icon={faMultiply} size='lg' style={{color: 'red'}} data-override-event="true"/>
                            </ButtonOverride>
                            <ButtonOverride className={styles.actionBtn} type="button" onClick={() => _handleSaveClick(pendingSelection)} data-override-event="true">
                                <FontAwesomeIcon icon={faCheck} size='lg' style={{color: 'green'}} data-override-event="true" />
                            </ButtonOverride>
                        </div>
                    </div>
                </>
            :
            <div className={`${styles.primaryContainer} ${styles.noneditmode}`} ref={editWindowRef}>
                <div className={`${styles.header} ${styles.noneditmode}`}>
                    <strong>Events</strong>
                </div>
                {
                            meta.error && !error && meta.touched
                            ?
                                <div className={styles.formError}>
                                    {meta.error}
                                </div>
                            :
                                null
                        }
                {
                    error
                    ?
                        <InsetAlert error={error} dismissible={false} style={{width: '100%'}}/>
                    :
                    <div className={styles.bubbleContainer}>
                    <div className={emailStyles.gapControl}>
                        {
                            processing
                            ?
                                <GapButtonSkeleton />
                            :
                                <>
                                    {
                                        displayItems.map(item => {
                                            return <button type='button' key={item.id} className={`${emailStyles.emailEvent} ${emailStyles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={_handleEditOpen} tabIndex={focusLock && -1}>{item.title}</button>
                                        })
                                    }
                                        <ButtonOverride className={`${styles.actionBtn} ${styles.addItemBtn} ${emailStyles.emailEventTag}`} type="button" onClick={_handleEditOpen} tabIndex={focusLock && -1}>
                                                <FontAwesomeIcon icon={faAdd} size='xl' style={{color: "#4a707a"}}/>
                                        </ButtonOverride>
                                </>
                        }
                    </div>
                </div>
                }
            </div>
    )
}


export default EmailEventSelection;