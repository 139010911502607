import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../styles/emaillistitem.module.css';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const EmailListItem = ({email,clickActionCallback}) => {
    const [showTooltip,setShowTooltip] = useState(false);
    const [target,setTarget] = useState(null);
    const [tooltipText,setTooltipText] = useState('');

    const _mouseEnter = (e,tooltipText) => {
        setTarget(e.target);
        setTooltipText(tooltipText);
        setShowTooltip(true);
    }

    const _mouseLeave = () => {
        setShowTooltip(false);
    }

    const _onClick = (focusElement = null) => {
        _mouseLeave();
        clickActionCallback(focusElement);
    }
    return(
        <>
        <Overlay placement='top' containerPadding={20} show={showTooltip} target={target}>
            <Tooltip>{tooltipText}</Tooltip>
        </Overlay>
        <div className={`${styles.emailCard} mb-3`}>
                <div className={styles.emailHeader}>
                    {/* <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Subject</Tooltip>}>
                        <h3 className={styles.emailSubject}>
                            <button type='button' className={`${styles.emailSubject} semanticBtnOverride`} style={{padding: '0'}} aria-label="Edit Subject" onClick={() => clickActionCallback()}>New Account Created</button>
                        </h3>
                    </OverlayTrigger> */}
                    <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Subject</Tooltip>}>
                        <h3 className={styles.emailSubject}>
                                <button type='button' className={`${styles.emailSubject} semanticBtnOverride`} style={{padding: '0'}} aria-label="Edit Subject" onClick={() => _onClick("subject")}>New Account Created</button>
                        </h3>
                    </OverlayTrigger>
                    {/* <h3 className={styles.emailSubject}>
                            <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Subject')} onMouseLeave={() => _mouseLeave()} className={`${styles.emailSubject} semanticBtnOverride`} style={{padding: '0'}} aria-label="Edit Subject" onClick={() => _onClick("subject")}>New Account Created</button>
                    </h3> */}
                    <div className={styles.gapControl}>
                        <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Event</Tooltip>}>
                            <button type='button' className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dfs_test</button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Event</Tooltip>}>
                            <button type='button' className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dfsfdsf_test</button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Event</Tooltip>}>
                            <button type='button' className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dfseeer_test</button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Event</Tooltip>}>
                            <button type='button' className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dffdsfds_test</button>
                        </OverlayTrigger>
                        {/* <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Event')} onMouseLeave={() => _mouseLeave()} className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dfs_test</button>
                        <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Event')} onMouseLeave={() => _mouseLeave()} className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dfsfdsf_test</button>
                        <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Event')} onMouseLeave={() => _mouseLeave()} className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dfseeer_test</button>
                        <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Event')} onMouseLeave={() => _mouseLeave()} className={`${styles.emailEvent} ${styles.emailEventTag} semanticBtnOverride`} aria-label='Edit Event' onClick={() => _onClick("event")}>dffdsfds_test</button> */}
                    </div>
                </div>
                <div className={styles.emailDetails}>
                    <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Body</Tooltip>}>
                        <button type='button' className={`${styles.emailBody} semanticBtnOverride`} aria-label='Edit Body' onClick={() => _onClick("body")}>
                            <span aria-hidden>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</span>
                        </button>
                    </OverlayTrigger>
                    {/* <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Event')} onMouseLeave={() => _mouseLeave()} className={`${styles.emailBody} semanticBtnOverride`} aria-label='Edit Body' onClick={() => _onClick("body")}>
                        <span aria-hidden>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</span>
                    </button> */}
                    <div className={`${styles.emailRecipients} mt-2`}>
                        <strong>Recipients</strong>
                        <div className={styles.recipientTags}>
                            <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Recipient</Tooltip>}>
                                <button type='button' className={`${styles.recipientTag} ${styles.sendTypeTo} semanticBtnOverride`} aria-label='Edit Recipient' onClick={() => _onClick("recipient")}>
                                    <span className={styles.sendTypeText}>TO</span>
                                    IBI - DBHDID
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement='top' delay={{show: 250, hide: 0}} overlay={<Tooltip>Edit Recipient</Tooltip>}>
                                <button type='button' className={`${styles.recipientTag} ${styles.sendTypeCc} semanticBtnOverride`} aria-label='Edit Recipient' onClick={() => _onClick("recipient")}>
                                    <span className={styles.sendTypeText}>CC</span>
                                    IBI - DBHDID_two
                                </button>
                            </OverlayTrigger>
                            {/* <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Event')} onMouseLeave={() => _mouseLeave()} className={`${styles.recipientTag} ${styles.sendTypeTo} semanticBtnOverride`} aria-label='Edit Recipient' onClick={() => _onClick("recipient")}>
                                <span className={styles.sendTypeText}>TO</span>
                                IBI - DBHDID
                            </button>
                            <button type='button' onMouseEnter={(e) => _mouseEnter(e,'Edit Event')} onMouseLeave={() => _mouseLeave()} className={`${styles.recipientTag} ${styles.sendTypeCc} semanticBtnOverride`} aria-label='Edit Recipient' onClick={() => _onClick("recipient")}>
                                <span className={styles.sendTypeText}>TO</span>
                                IBI - DBHDID_two
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default EmailListItem;