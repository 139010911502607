import { Form, Formik } from "formik";
import FloatingActionButton from "../../../components/floatingactionbutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

const BaseForm = ({initialValues,validationSchema = null,onSubmit,processing = false,children}) => {
    return(
        <div className="clientCard">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {
                    formik => (
                        <Form>
                            <div className="clientCardInner">
                                {children}
                                <div className="d-flex justify-content-end">
                                    <FloatingActionButton type="submit" variant="success" disabled={formik.isSubmitting || processing}>
                                        <FloatingActionButton.Icon>
                                            <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
                                        </FloatingActionButton.Icon>
                                        <FloatingActionButton.Text>
                                            Save
                                        </FloatingActionButton.Text>
                                    </FloatingActionButton>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )

}

export default BaseForm;