import { useState,useRef } from "react";
import BaseModal from "./basemodal";
import useApi from "../hooks/use-api";
import { Tabs,Tab,Col,Row,Table,Popover,Overlay} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { Formik} from 'formik';
import * as Yup from 'yup';
import InputField from "../formik/input-field";
import FloatingLabelSelect from "../formik/floating-label-select";
import Checkbox from "../formik/checkbox";
import FloatLabel from "../formik/floating-label";
import InsetAlert from "../components/insetalert";
import useMessageHub from "../hooks/use-messagehub";
import ActionResultText from "../utilities/ActionResultText";
import TreeComponent from "../components/treecomponent";
import { useModalStatusContext } from "../contexts/modalstatuscontext";

const UserModal = ({modalShown,hideModal,targetID,permissions,emailgroups,roles,userChangeCallback}) => {
    const {processing,error,getRequest,postRequest,putRequest} = useApi();
    const childSubmitHandlerRef = useRef(null);
    const [modalLocked,setModalLocked] = useState(false);
    const {registerActivity} = useMessageHub();
    const {isLoading,HasError,handleError} = useModalStatusContext();

    const initialValues = {
        firstName: '',
        lastName : '',
        email : '',
        supervisor : '-1',
        userType: '-1',
        hIPPADate : '',
        hIPPAUKDate : '',
        webAppTrainingDate: '',
        corporateCompDate: '',
        roles: [],
        permissions: [1],
        emailGroups: []

    }; 

    const validationSchema = Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().email("Email must be a valid Email Address").required("Required"),
        userType: Yup.string().required("Required").notOneOf(['-1'],"you must select a User Type"),
        supervisor: Yup.string().required("Required").notOneOf(['-1'],"You must select a Supervisor")
    });

    const alertText = {
        roles : {
            heading: "No Roles Exist!",
            body: "No Roles currently exist. You must add roles before assigning them to a User."
        },
        permissions : {
            heading: "No Permissions Exist!",
            body: "No Permissions currently exist. You must add Permissions before assigning them to a User."
        },
        emailgroups: {
            heading: "No Email Groups Exist!",
            body: "No Email Groups currently exist. You must add Email Groups before assigning them to a User."
        }
    };


    const[user,setUser] = useState(initialValues);

    const getUser = async () => {
        const [data,status] = await getRequest("getuser",{id: targetID});
        return [data,status];
    };

    const submitUserRequest = async (values) => {
        const [data, status] =  targetID ? await putRequest("edituser", {...values,id: targetID}) : await postRequest("adduser",{...values,id: 0});
        return [data,status];
    };

    const handleSubmitFunction = async (values) => {
        if(childSubmitHandlerRef.current){
            await childSubmitHandlerRef.current(values);
        }
    }

    const timeoutRef = useRef(null);
    const [showPopover, setShowPopover] = useState(false);
    const [popovertext,setPopoverText] = useState({heading: '',body : ''});
    const [target, setTarget] = useState(null);

    const popoverHoverEvent = (e,heading,body) =>{
        timeoutRef.current = setTimeout(() => {
            setPopoverText({heading: heading,body: body});
            setShowPopover(true);
            setTarget(e.target);
        }, 1000);
    }

    const popoverLeaveEvent = () => {
        clearTimeout(timeoutRef.current);
        setShowPopover(false);
    }

    const ActionCompleteCallback = (ActionResult) => {
        registerActivity(
            {
                body: ActionResultText({...ActionResult,objectTarget: 'User'}),
                errorFlag: ActionResult.error
            },
            ActionResult.sessionExpired,
            true
        )
        userChangeCallback();
    }

    return(
        <>
            <Overlay
                show={showPopover}
                target={target}
                placement="right"
                containerPadding={20}
                >
                <Popover id="popover-contained">
                    <Popover.Header as="h3">{popovertext.heading}</Popover.Header>
                    <Popover.Body>
                        {popovertext.body}
                    </Popover.Body>
                </Popover>
            </Overlay>
        <Formik
                initialValues={user}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmitFunction}
            >
                {
                    formik => 
                    <BaseModal
                    modalShown={modalShown}
                    closeModal={() => hideModal()}
                    ModalTitle={targetID ? <span>Edit User [{user.username}]</span> : <span>Add User</span>}
                    loadData={getUser}
                    submitData={submitUserRequest}
                    objectID={targetID}
                    objectType="User"
                    setData={setUser}
                    error={error}
                    processing={processing}
                    initialValues={initialValues}
                    size="lg"
                    setSubmitHandler={handler => childSubmitHandlerRef.current = handler}
                    formikProps={formik}
                    modalLocked={modalLocked}
                    popoverHover={popoverHoverEvent}
                    popoverLeave={popoverLeaveEvent}
                    setModalLocked={setModalLocked}
                    actionCompleteCallback={ActionCompleteCallback}
            >
             <div>
                                    <Tabs
                                    defaultActiveKey="userinfo"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    fill
                                    >
                                    <Tab eventKey="userinfo" title="User Information" disabled={formik.isSubmitting || processing || modalLocked}>
                                        <div className="box box--userinformation">
                                        <Row>
                                    <Col xs={12} md={6}>
                                    <FloatLabel
                                            type="text"
                                            name="firstName"
                                            label="First Name"
                                            placeholder="First Name"
                                            autoComplete="offz"
                                            disabled={formik.isSubmitting || processing || modalLocked}
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                    <FloatLabel
                                            type="text"
                                            name="lastName"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            autoComplete="offz"
                                            disabled={formik.isSubmitting || processing || modalLocked}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                    <FloatLabel
                                            type="email"
                                            name="email"
                                            label="Email"
                                            placeholder="Email"
                                            autoComplete="offz"
                                            disabled={formik.isSubmitting || processing || modalLocked}
                                        />
                                    </Col>
                                </Row>
                                <FloatingLabelSelect name="userType" label="User Type" data={[{id: -1,name : "Select a user type"},{id: -0,name : "tester"}]} disabled={formik.isSubmitting || processing || modalLocked}/>
                                <FloatingLabelSelect name="supervisor" label="Supervisor" data={[{id: -1,name : "Select a supervisor"},{id:0,name: "test"},{id:1,name: "tejskjfklj"}]} disabled={formik.isSubmitting || processing || modalLocked}/>
                                <InputField
                                            type="date"
                                            name="hIPPADate"
                                            label="HIPAA Confidentiality Agreement Date"
                                            max={new Date().toISOString().split('T')[0]}
                                            disabled={formik.isSubmitting || processing || modalLocked}
                                        />
                                        <InputField
                                            type="date"
                                            name="hIPPAUKDate"
                                            label="HIPAA UK Training Date"
                                            max={new Date().toISOString().split('T')[0]}
                                            disabled={formik.isSubmitting || processing || modalLocked}
                                        />
                                        <InputField
                                            type="date"
                                            name="webAppTrainingDate"
                                            label="Web App Training Date"
                                            max={new Date().toISOString().split('T')[0]}
                                            disabled={formik.isSubmitting || processing || modalLocked }
                                        />
                                        <InputField
                                            type="date"
                                            name="corporateCompDate"
                                            label="Corporate Compliance Date"
                                            max={new Date().toISOString().split('T')[0]}
                                            disabled={formik.isSubmitting || processing || modalLocked}
                                        />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="roles" title="Roles" disabled={formik.isSubmitting || processing || modalLocked}>
                                        <div className="box box--roles">
                                            <div className="internalbox">
                                                {
                                                    roles
                                                    ?
                                                        <Table bordered hover responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>Role Name</th>
                                                                    <th className="text-center"><FontAwesomeIcon icon={faInfoCircle} size="lg" style={{color : "#076bff",cursor: "pointer"}} onMouseEnter={(e) => {popoverHoverEvent(e,"Roles","Assign user roles by toggling the switch for the targeted roles. Hover over roles for more information.")}} onMouseLeave={popoverLeaveEvent}/></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    roles.map((row) => {
                                                                        return(
                                                                            <tr key={row.id}>
                                                                                <td className="textcell" onMouseEnter={(e) => {popoverHoverEvent(e,row.name,row.description)}} onMouseLeave={popoverLeaveEvent}>{row.name}</td>
                                                                                <td className="checkboxcell"><Checkbox type={"switch"} label={row.name} name={"roles"} value={row.id.toString()} disabled={formik.isSubmitting || processing || modalLocked}/> </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    :
                                                        <InsetAlert type="Warn" heading={alertText.roles.heading} body={alertText.roles.body} dismissible={false}/>
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="permissions" title="Permissions" disabled={formik.isSubmitting || processing || modalLocked}>
                                        <div className="box box--permissions">
                                        {/* <div className="internalbox">
                                                {
                                                    permissions
                                                    ?
                                                        <Table bordered hover responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>Permission Name</th>
                                                                    <th className="text-center"><FontAwesomeIcon icon={faInfoCircle} size="lg" style={{color : "#076bff",cursor: "pointer"}} onMouseEnter={(e) => {popoverHoverEvent(e,"Permissions","Assign user permissions by toggling the switch for the targeted permission. Hover over permissions for more information.")}} onMouseLeave={popoverLeaveEvent}/></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    permissions.map((row) => {
                                                                        return(
                                                                            <tr key={row.id}>
                                                                                <td className="textcell" onMouseEnter={(e) => {popoverHoverEvent(e,row.name,row.description)}} onMouseLeave={popoverLeaveEvent}>{row.name}</td>
                                                                                <td className="checkboxcell"><Checkbox type={"switch"} label={row.name} name={"permissions"} value={row.id.toString()} disabled={formik.isSubmitting || processing || modalLocked}/> </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    :
                                                        <InsetAlert type="Warn" heading={alertText.permissions.heading} body={alertText.permissions.body} dismissible={false} />
                                                }
                                            </div> */}
                                            <TreeComponent
                                            name="permissions" 
                                            objectName="Permission" 
                                            searchboxid="tester"  
                                            retrieveDataEndpoint="tree"
                                            extractDataFunction={(data) => data.map(permission => ({id: permission.id,name: permission.name, path: permission.path}))}
                                            disabled={HasError}
                                        />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="emailgroups" title="Email Groups" disabled={formik.isSubmitting || processing || modalLocked }>
                                        <div className="box box--emailgroups">
                                        <div className="internalbox">
                                                {
                                                    emailgroups
                                                    ?
                                                        <Table bordered hover responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>Email Group Name</th>
                                                                    <th className="text-center"><FontAwesomeIcon icon={faInfoCircle} size="lg" style={{color : "#076bff",cursor: "pointer"}} onMouseEnter={(e) => {popoverHoverEvent(e,"Email Groups","Assign user to Email Groups by toggling the switch for the targeted Email Group. Hover over the Email Groups for more information.")}} onMouseLeave={popoverLeaveEvent}/></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    emailgroups.map((row) => {
                                                                        return(
                                                                            <tr key={row.id}>
                                                                                <td className="textcell" onMouseEnter={(e) => {popoverHoverEvent(e,row.name,row.description)}} onMouseLeave={popoverLeaveEvent}>{row.name}</td>
                                                                                <td className="checkboxcell"><Checkbox type={"switch"} label={row.name} name={"emailGroups"} value={row.id.toString()} disabled={formik.isSubmitting || processing || modalLocked}/> </td>
                                                                            </tr>
                                                                        )
                                                                    }) 
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    :
                                                        <InsetAlert type="Warn" heading={alertText.emailgroups.heading} body={alertText.emailgroups.body} dismissible={false} />
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                    </Tabs>
                                    </div>
                        </BaseModal> 
                }
            
            </Formik>
        </>
    )
}


export default UserModal;