import { Container } from "react-bootstrap";
import MainNavbar from "../components/mainnavbar";
import Footer from "../components/footer";
import MessageHub from "../components/messagehub";
import ScrollToTopButton from "../components/scrolltotopbutton";
const LoggedInLayout = ({children}) => {
    return(
        <div className="mainwrapper">
            <MainNavbar />
            <Container fluid className="pt-4 pb-4 maincontainer">
                {children}
            </Container>
            <Footer />
            <MessageHub />
            <ScrollToTopButton />
        </div>
    )
}

export default LoggedInLayout;