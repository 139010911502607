import { useEffect, useState } from "react";
import Axios from 'axios';
import endpointMap from "../utilities/api-endpoints-map";
import useUserStore from "../stores/user-store";
import useApiUrlStore from "../stores/api-url-store";

const useApi = () => {

    const baseUrl = useApiUrlStore((state) => state.baseUrl);
    // const baseUrl = "https://localhost:7222/"
    const jwt = useUserStore((state) => state.jwt);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [status,setStatus] = useState(-1);
    const mapEndpoints = endpointMap;
    const methods = {
        get: "get",
        post: "post",
        put: "put",
        delete: "delete"
    };

    const procRequest = async (endpointKey, indata, params, method, headers, blob) => {
        setError(null);
        setProcessing(true);

        return Axios({
                method: method,
                url: baseUrl + mapEndpoints.get(endpointKey),
                data: indata,
                params: params,
                responseType: blob ? "blob" : "json",
                headers: headers
            }
        ).then((response) => {
            setProcessing(false);
            if (blob) {
                return response;
            } else {
                setStatus(response.status);
                return [ response.data, response.status ];
            }
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.data.message) {
                    setError({status: error.response.status,error: error.response.data.message});
                } else {
                    setError({status: error.response.status,error: error.message});
                }
                
                setProcessing(false);
                setStatus(error.response.status);
                return [ null, error.response.status ];
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
                setError({error: error.request});
            } else {
                // Something happened in setting up the request that triggered an Error
                //console.log('Error', error.message);
                setError({error: error.message});
            }
            setProcessing(false);  
            return [ null, 999 ];
        });

    }; 

    const procMultipleRequests = async(requests,method,headers,blob) => {
        setError(null);
        setProcessing(true);

        const lookupTable = new Map();

        requests.forEach(req => {
            let endpointKey = req.endpoint;

            if(endpointMap.has(endpointKey)){
                let value = baseUrl + endpointMap.get(endpointKey);
                lookupTable.set(value,endpointKey);
            }
            else{
                throw Error(`Endpoint Key ${endpointKey} was not found.`)
            }
        });

        let dataStructure = [];

        await Promise.all(requests.map(req =>
            Axios({
                method: method,
                url: baseUrl + mapEndpoints.get(req.endpoint),
                data: req.data,
                params: req.params,
                responseType: blob ? "blob" : "json",
                headers: headers,
            })
        )).then((responses) => {
            console.log(responses);
            responses.forEach(response => {
                let key = response.config.url;

                dataStructure[lookupTable.get(key)] = {
                    data: response.data,
                    status: response.status
                }
            })
            // dataStructure = responses.map(response => ({
            //     data: response.data,
            //     status: response.status
            // }));
        })
        .catch((error) => {
            setProcessing(false);
            console.log(error)
            // setStatus(error.response.status);
        })
        .finally(() => {
            setProcessing(false);
        })

        return dataStructure;


    }

    const procPostRequest = async (endpointKey, indata) => {
        return await procRequest(endpointKey, indata, null, methods.post, {"Authorization": "Bearer " + jwt}, false);
    };

    const procPostRequestFile = async (endpointKey, indata) => {
        return await procRequest(endpointKey, indata, null, methods.post, {"Authorization": "Bearer " + jwt, "Content-Type": "multipart/form-data"}, false);
    };

    const procGetRequest = async (endpointKey, params) => {
        return await procRequest(endpointKey, null, params, methods.get, {"Authorization": "Bearer " + jwt}, false);
    };

    const procGetRequestMulti = async(axiosConfigs) => {
        return await procMultipleRequests(axiosConfigs,methods.get,{"Authorization" : "Bearer " + jwt},false);
    }

    const procGetFileRequest = async (endpointKey, params) => {
        return await procRequest(endpointKey, null, params, methods.get, {"Authorization": "Bearer " + jwt}, true);
    };

    const procGetRequestNoAuth = async (endpointKey, params) => {
        return await procRequest(endpointKey, null, params, methods.get, null, false);
    };

    const procPutRequest = async (endpointKey, indata) => {
        return await procRequest(endpointKey, indata, null, methods.put, {"Authorization": "Bearer " + jwt}, false);
    };

    const procDeleteRequest = async (endpointKey, indata) => {
        return await procRequest(endpointKey, indata, null, methods.delete, {"Authorization": "Bearer " + jwt}, false);
    };

    return {
        error: error,
        processing: processing, 
        postRequest : procPostRequest,
        postFileRequest: procPostRequestFile,
        getRequest : procGetRequest,
        getRequestMulti: procGetRequestMulti,
        getFileRequest : procGetFileRequest,
        getRequestNoAuth: procGetRequestNoAuth,
        putRequest: procPutRequest,
        deleteRequest: procDeleteRequest
    };

};

export default useApi;

