import { Button } from 'react-bootstrap';
import styles from '../styles/scrollspy.module.css';
import ButtonOverride from './buttonoverride';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEllipsis, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';


const ScrollProgress = ({activeSection,sectionRefs,formik,validateSection }) => {
    const [expanded,setExpanded] = useState(false);
    const [validationResults,setValidationResults] = useState({});

    useEffect(() => {
        const validateAllSections = async () => {
            const results = {};

            for(const ref of sectionRefs.current){
                if(ref.current){
                    const sectionName = ref.current.getAttribute('data-title');

                    try{
                        let returnValue = await validateSection(sectionName);
                        results[sectionName] = returnValue;
                    }
                    catch(error){
                        results[sectionName] = false;
                    }

                }
            }

            setValidationResults(results);
        }

        validateAllSections();
    },[formik.values]);

    const _isComplete = (sectionName) => {
        const result = validationResults[sectionName];

        return result;
    }

    const _scrollToSection = (ref) => {
        if(ref.current){
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }
    }
  
    return (
        <div className={`${styles.stickyNavigation} ${expanded ? styles.expanded : ''}`}>
            <div className={styles.expandBtnContainer}>
                <Button className={styles.expandBtn} onClick={() => setExpanded(!expanded)} aria-label={`${expanded ? 'Collapse' : 'Expand'} navigation.`} title={`${expanded ? 'Collapse' : 'Expand'} navigation.`}>
                    <FontAwesomeIcon icon={faEllipsis} size="2xl" />
                </Button>
            </div>
            <div className={`${styles.navigationContent} ${expanded ? styles.expanded : ''}`}>
            <div className={`${styles.navBtnContainer} mb-1`}>
            {
                sectionRefs.current.map((ref,index) => {
                    const title = ref.current?.getAttribute('data-title');

                    return(
                        <ButtonOverride key={index} type="button" onClick={() => _scrollToSection(ref)} className={`${styles.navBtn} ${activeSection === index ? styles.active : ''}`} aria-label={`Navigate to section : [${title}]`} title={`Navigate to section : [${title}]`}>
                            <span className='d-flex align-items-center'>
                                {
                                    _isComplete(title)
                                    ?
                                        <FontAwesomeIcon icon={faCircleCheck} size='xl' className='me-1' style={{color: '#28a745'}}/>
                                    :
                                        <FontAwesomeIcon icon={faTriangleExclamation} size='xl' className='me-1' style={{color: '#ffc107'}} />
                                }
                                {title}
                                <div className={`${styles.scrollInd} ms-1`}></div>
                            </span>
                        </ButtonOverride>
                    )
                })
            }   
            </div>
            </div>
        </div>
    );
  };
  

export default ScrollProgress;