import styles from '../styles/Datalist.module.css';

const RoleListItem = ({role,clickActionCallback}) => {
    return(
        // <div className={`${styles["data-card"]} ${styles["fiveCol"]} ${styles["colMark"]} mb-3`} onClick={() => clickActionCallback()} onKeyDown={(e) => {if(e.key === "Enter") clickActionCallback()}} tabIndex={0}>
            // <div className={styles["datapoint"]}>
            //     <div className={styles["datalabel"]}>
            //         Role Name
            //     </div>
            //     <div className={styles["datacol"]}>
            //         {role.name}
            //     </div>
            // </div>
            // <div className={styles["datapoint"]}>
            //     <div className={styles["datalabel"]}>
            //         Description
            //     </div>
            //     <div className={styles["datacol"]}>
            //         {role.description}
            //     </div>
            // </div>
            // <div className={styles["datapoint"]}>
            //     <div className={styles["datalabel"]}>
            //         Created On
            //     </div>
            //     <div className={styles["datacol"]}>
            //         {role.createdon}
            //     </div>
            // </div>
            // <div className={styles["datapoint"]}>
            //     <div className={styles["datalabel"]}>
            //         User Count
            //     </div>
            //     <div className={styles["datacol"]}>
            //         {role.usercount}
            //     </div>
            // </div>
            // <div className={styles["datapoint"]}>
            //     <div className={styles["datalabel"]}>
            //         Permissions Count
            //     </div>
            //     <div className={`${styles["finalcol"]} ${styles["datacol"]}`}>
            //         {role.permcount}
            //     </div>
            // </div>
        // </div>        
        <button className={`${styles["data-card"]} ${styles["fiveCol"]} ${styles["colMark"]} mb-3`} onClick={() => clickActionCallback()} type='button' aria-label={`Edit Role : ${role.name}`}>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Role Name
                </span>
                <span className={styles["datacol"]}>
                    {role.name}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Description
                </span>
                <span className={styles["datacol"]}>
                    {role.description}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Created On
                </span>
                <span className={styles["datacol"]}>
                    {role.createdon}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    User Count
                </span>
                <span className={styles["datacol"]}>
                    {role.usercount}
                </span>
            </span>
            <span className={styles["datapoint"]}>
                <span className={styles["datalabel"]}>
                    Permissions Count
                </span>
                <span className={`${styles["finalcol"]} ${styles["datacol"]}`}>
                    {role.permcount}
                </span>
            </span>
        </button>
    )
}

export default RoleListItem;