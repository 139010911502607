import { Col, Row } from "react-bootstrap";
import BaseForm from "./baseform";
import InlineRadioGroup from "../../../formik/inlineradiogroup";
import InputField from "../../../formik/input-field";
import { Form, Formik } from "formik";
import FormikCheckbox from "../../../formik/formikcheckbox";
import FloatLabel from "../../../formik/floating-label";

const Map351VIIForm = () => {

    const initialValues = {
        behaviorproblems: null,
        orientedspacetime: null,
        majorcrisis: null,
        socialcommunity: null,
        cognition: null,
        confused: null,
        anxious: null,
        behaviorproblemfrequency: null,
        behaviorproblemscomments: '',
        orientedspacetimecomment: '',
        majorcrisiscomment: '',
        socialcommunitycomment: '',
        conditonscomment: '',
        cognitioncomment: '',
        confusedcomment: '',
        anxiouscomment: '',
        depressivecomment: '',
        membehaviorcomment: '',
        behonceweekcomment: '',
        behaviorproblemcomment: '',
        mentalstatuscomment: '',
        psychnursecomment: ''
    }

    const yesNo = [
        {
            title: 'Yes',
            value: 1
        },
        {
            title: 'No',
            value: 0
        }
    ]

    const ancillaryBehaviorProblems = [
        {
            title: 'Disruptive Behavior',
            name: 'disruptivebehavior'
        },
        {
            title: 'Agitated Behavior',
            name: 'agitatedbehavior'
        },
        {
            title: 'Assaultive Behavior',
            name: 'assaultivebehavior'
        },
        {
            title: 'Self-Injurous Behavior',
            name: 'selfinjurousbehavior'
        },
        {
            title: 'Self-Neglecting Behavior',
            name: 'selfneglectingbehavior'
        }
    ]

    const ancillaryOrientation = [
        {
            title: 'Forgetful',
            name: 'orientforgetful'
        },
        {
            title: 'Confused',
            name: 'orientconfused'
        },
        {
            title: 'Unresponsive',
            name: 'orientunresponsive'
        },
        {
            title: 'Impaired Judgement',
            name: 'orientimpairedjudgement'
        }
    ]

    const ancillaryConditions = [
        {
            title: 'Recognizing Others',
            name: 'diffrecognizingothers'
        },
        {
            title: 'Loneliness',
            name: 'loneliness'
        },
        {
            title: 'Sleeping Problems',
            name: 'sleepingproblems'
        },
        {
            title: 'Anxiousness',
            name: 'anxiousness'
        },
        {
            title: 'Irritability',
            name: 'irritability'
        },
        {
            title: 'Lack Of Interest',
            name: 'lackofinterest'
        },
        {
            title: 'Short-term Memory Loss',
            name: 'shorttermmem'
        },
        {
            title: 'Long-term Memory Loss',
            name: 'longtermmem'
        },
        {
            title: 'Hopelessness',
            name: 'hopelessness'
        },
        {
            title: 'Suicidal Behavior',
            name: 'suicidalbeh'
        },
        {
            title: 'Medication Abuse',
            name: 'medabuse'
        },
        {
            title: 'Substance Abuse',
            name: 'substanceabuse'
        },
        {
            title: 'Alcohol Abuse',
            name: 'alcoholabuse'
        }
    ]

    const ancillaryCognition = [
        {
            title: 'Alert/oriented, able to focus and shfit attention, comprehends and recalls taks directions independently',
            value: 0
        },
        {
            title: 'Requires prompting (cueing, repetition, reminders) onlue under stressful or unfamiliar conditions.',
            value: 1
        },
        {
            title: 'Requires assistance and some direction in specific situations (e.g., on all tasks involving shifting of attention), or consistently requires low stimulus environment due to distractability.',
            value: 2
        },
        {
            title: 'Required considerable assistance in routine situations. Is not alert and oriented or is unable to shift attention and recall directions more than half the time.',
            value: 3
        },
        {
            title: 'Totally dependent due to disturbances such as constant disorientation, coma, persistent vegetative state, or delirium',
            value: 4
        }
    ]

    const ancillaryConfused = [
        {
            title: 'Never',
            value: 0
        },
        {
            title: 'In new or complex situations only',
            value: 1
        },
        {
            title: 'On awakening or at night only',
            value: 2
        },
        {
            title: 'During the day and evening, but not constantly',
            value: 3
        },
        {
            title: 'Constantly',
            value: 4
        },
        {
            title: 'NA (non-responsive)',
            value: 5
        }
    ]

    const ancillaryAnxious = [
        {
            title: 'None of the time',
            value: 0
        },
        {
            title: 'Less often than daily',
            value: 1
        },
        {
            title: 'Daily, but not constantly',
            value: 2
        },
        {
            title: 'All of the time',
            value: 3
        },
        {
            title: 'NA (non-responsive)',
            value: 4
        }
    ]

    const ancillaryDepressive = [
        {
            title: 'Depressed mood (e.g., feeling sad, fearful)',
            name: 'depressivemood'
        },
        {
            title: 'Sense of failure or self-reproach',
            name: 'depressivefailure'
        },
        {
            title: 'Hopelessness',
            name: 'depressivehopeless'
        },
        {
            title: 'Recurrent thoughts of death',
            name: 'depressivedeath'
        },
        {
            title: 'Thoughts of suicide',
            name: 'depressivesuicide'
        },
        {
            title: 'None of the above feelings reported or observed',
            name: 'depressivenone'
        }
    ]

    const ancillaryMemberBehaviors = [
        {
            title: 'Indecisiveness, lack of concentration',
            name: 'memberbehindecisive'
        },
        {
            title: 'Diminished interest in most activities',
            name: 'memberbehinterest'
        },
        {
            title: 'Sleep disturbances',
            name: 'membehsleep'
        },
        {
            title: 'Recent changes in appetite or weight',
            name: 'membehappetite'
        },
        {
            title: 'Agitation',
            name: 'membehagitation'
        },
        {
            title: 'Suicide attempt',
            name: 'membehsuicide'
        },
        {
            title: 'None of the above behaviors observed or reported',
            name: 'membehnone'
        }
    ]

    const ancillaryBehaviorWeek = [
        {
            title: 'Memory deficit: failure to recognize familiar persons/places, inability to recall events of past 24-hours, significant memory loss so that supervision is required.',
            name: 'behweekmemorydeficit'
        },
        {
            title: "Impaired decision-making: failure to perform usual ADL's, inability to inappropriately stop activities, jeopardizes safety through actions.",
            name: 'behweekdecision'
        },
        {
            title: 'Verbal disruption: yelling, threatening, excessive profanity, sexual references, etc.',
            name: 'behweekverbal'
        },
        {
            title: 'Physical aggression: aggressive or combative to self and others (e.g., hits self, throws objects, punches, dangerous maneuvers with wheelchair or other objects).',
            name: 'behweekphysical'
        },
        {
            title: 'Disruptive, infantile, or socially inappropriate behavior (excludes verbal actions).',
            name: 'behweekdisruptive'
        },
        {
            title: 'Delusional, hallucinatory, or paranoid behaviour',
            name: 'behweekdelusion'
        },
        {
            title: 'None of the above behaviors observed or reported',
            name: 'behweeknone'
        }
    ]

    const ancillaryFrequency = [
        {
            title: 'Never',
            value: 0
        },
        {
            title: 'Less than once a month',
            value: 1
        },
        {
            title: 'Once a month',
            value: 2
        },
        {
            title: 'Several times each month',
            value: 3
        },
        {
            title: 'Several times a week',
            value: 4
        },
        {
            title: 'At least daily',
            value: 5
        }
    ]

    const ancillaryMentalStatus = [
        {
            title: 'Oriented',
            name: 'mentalstatusoriented'
        },
        {
            title: 'Forgetful',
            name: 'mentalstatusforgetful'
        },
        {
            title: 'Depressed',
            name: 'mentalstatusdepressed'
        },
        {
            title: 'Disoriented',
            name: 'mentalstatusdisoriented'
        },
        {
            title: 'Lethargic',
            name: 'mentalstatuslethargic'
        },
        {
            title: 'Agitated',
            name: 'mentalstatusagitated'
        },
        {
            title: 'Other',
            name: 'mentalstatusother'
        }
    ]

    return(
        <div className="clientCard">
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={() => alert("here")}
            >
                {
                    formik => (
                        <Form>
                            <div className="clientCardInner">
                                <h4 className="text-center">SECTION VII - NEURO/EMOTIONAL/BEHAVIORAL</h4>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <InlineRadioGroup  
                                                name="behaviorproblems"
                                                primaryLabel="1) Does member exhibit behavior problems?"
                                                data={yesNo}
                                            />
                                            <p className="mb-0">(If yes, check below all that apply and explain the frequency in comments)</p>
                                            {
                                                formik.values.behaviorproblems === 1 &&
                                                ancillaryBehaviorProblems.map((item,index) => (
                                                    <FormikCheckbox 
                                                        key={index}
                                                        label={item.title}
                                                        name={item.name}
                                                    />
                                                ))
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <InputField
                                                type="date"
                                                name="functionalanalysisdate"
                                                label="Date of functional analysis"
                                                max={new Date().toISOString().split('T')[0]}
                                            />
                                            <InputField
                                                type="date"
                                                name="behaviorsupportplandate"
                                                label="Date of behavior support plan"
                                                max={new Date().toISOString().split('T')[0]}
                                            />
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="behaviorproblemscomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <InlineRadioGroup  
                                                name="orientedspacetime"
                                                primaryLabel="2) Is member oriented to person, place, and time?"
                                                data={yesNo}
                                            />
                                            <p className="mb-0">(If no, check below all that apply and comment)</p>
                                            {
                                                formik.values.orientedspacetime === 0 &&
                                                ancillaryOrientation.map((item,index) => (
                                                    <FormikCheckbox 
                                                        key={index}
                                                        label={item.title}
                                                        name={item.name}
                                                    />
                                                ))
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="orientedspacetimecomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <InlineRadioGroup  
                                                name="orientedspacetime"
                                                primaryLabel="3) Has member experienced a major change or crisis in the past twelve months?"
                                                data={yesNo}
                                            />
                                            <p className="mb-0">(If yes, describe)</p>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="majorcrisiscomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <InlineRadioGroup  
                                                name="socialcommunity"
                                                primaryLabel="4) Is the member actively participating in social and/or community activities?"
                                                data={yesNo}
                                            />
                                            <p className="mb-0">(If yes, describe)</p>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="socialcommunitycomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h6 className="mb-0">5) Is member experiencing any of the following?</h6>
                                            <p>(For each checked, explain the frequency and details in the comment section)</p>
                                            {
                                                ancillaryConditions.map((item,index) => (
                                                    <FormikCheckbox
                                                        key={index}
                                                        label={item.title}
                                                        name={item.name}
                                                    />
                                                ))
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="conditionscomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '350px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                                <InlineRadioGroup
                                                    name='cognition'
                                                    primaryLabel="6) Cognitive functioning (Participant's current level of alertness, orientation, comprehension, concentration, and immediate memory for simple commands)"
                                                    data={ancillaryCognition}
                                                />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="cognitioncomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '350px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                                <InlineRadioGroup
                                                    name='confused'
                                                    primaryLabel="7) When Confused (Reported or Observed):"
                                                    data={ancillaryConfused}
                                                    inline={false}
                                                />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="confusedcomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                                <InlineRadioGroup
                                                    name='anxious'
                                                    primaryLabel="8) When Anxious (Reported or Observed):"
                                                    data={ancillaryAnxious}
                                                    inline={false}
                                                />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="anxiouscomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h6 className="mb-0">9) Depressive Feelings (Reported or Observed):</h6>
                                            {
                                                ancillaryDepressive.map((item,index) => (
                                                    <FormikCheckbox 
                                                        key={index}
                                                        label={item.title}
                                                        name={item.name}
                                                    />
                                                ))
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="depressivecomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h6 className="mb-0">10) Member Behaviors (Reported or Observed):</h6>
                                            {
                                                ancillaryMemberBehaviors.map((item,index) => (
                                                    <FormikCheckbox 
                                                        key={index}
                                                        label={item.title}
                                                        name={item.name}
                                                    />
                                                ))
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="membehaviorcomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h6 className="mb-0">11) Behaviors Demonstrated at Least Once a Week:</h6>
                                            {
                                                ancillaryBehaviorWeek.map((item,index) => (
                                                    <FormikCheckbox 
                                                        key={index}
                                                        label={item.title}
                                                        name={item.name}
                                                    />
                                                ))
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="behonceweekcomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '400px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                                <InlineRadioGroup
                                                    name='behaviorproblemfrequency'
                                                    primaryLabel="12) Frequency of Behavior Problems (Reported or Observed) such as wandering episodes, self-abuse, verbal disruption, physical aggressgion, etc:"
                                                    data={ancillaryFrequency}
                                                    inline={false}
                                                />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="behaviorproblemcomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h6 className="mb-0">13) Mental Status:</h6>
                                            {
                                                ancillaryMentalStatus.map((item,index) => (
                                                    <FormikCheckbox 
                                                        key={index}
                                                        label={item.title}
                                                        name={item.name}
                                                    />
                                                ))
                                            }
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="mentalstatuscomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4 questionItem">
                                    <Row>
                                        <Col xs={12} md={6}>
                                                <InlineRadioGroup
                                                    name='behaviorproblemfrequency'
                                                    primaryLabel="14) Is the member receiving Psychiatric Nursing Services at home provided by a qualified psychiatric nurse?"
                                                    data={yesNo}
                                                />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatLabel
                                                type="text"
                                                as="textarea"
                                                name="psychnursecomment"
                                                label="Comment"
                                                placeholder="Email"
                                                autoComplete="offz"
                                                style={{height: '215px'}} 
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

export default Map351VIIForm;