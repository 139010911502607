const ActionResultText = (actionResult) => {
    if(!actionResult){
        throw new Error("Null actionResult is not allowed in ActionResultText.jsx");
    }

    const actionWord = actionResult.method === "add" ? "Add" : "Updat";
    const actionSuffix = actionResult.error ? "ing" : "ed";
    const actionPhrase = actionWord + actionSuffix;
    const actionText = actionResult.error
     ? `A problem occured when ${actionPhrase} the ${actionResult.objectTarget}.`
     : `The ${actionResult.objectTarget} was succesfully ${actionPhrase}.`

    return actionText;
}

export default ActionResultText;